import * as React from 'react'

import styles from 'components/Quiz/Quiz.module.scss'

interface BodyProps {
  body?: Array<string>
}

const Body = ({body}: BodyProps) => {
  if (!body?.length) return null

  return (
    <>
      {body.map(bodyItem => {
        return (
          <p className={styles.bodyCopy} key={bodyItem}>
            {bodyItem}
          </p>
        )
      })}
    </>
  )
}

export default Body
