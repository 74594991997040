import * as React from 'react'

const RightBlob = (props: Record<string, unknown>) => (
  <svg fill="none" height="122" viewBox="0 0 119 122" width="119" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M89.7222 80.2698C53.2618 136.603 20.2855 98.9892 20.1494 76.4207C19.986 49.7838 29.3649 32.2544 52.4872 22.7245C75.8156 13.1092 127.407 22.0428 89.7222 80.2698V80.2698Z"
      fill="#112A44"
      fillRule="evenodd"
      opacity="0.243"
    />
  </svg>
)

export default RightBlob
