import * as React from 'react'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import {EmailOnFocusContext} from 'components/Quiz/contexts/EmailOnFocusContext'
import {Choice} from 'components/Quiz/Quiz.types'
import {errorMessage, isEmailInvalid} from 'components/Quiz/utils/utils'

import styles from './TextEntry.module.scss'

const TextEntry = ({setValue, question, index}: Choice) => {
  const {choices, answers, questionId, validation} = question || {}
  const id = `${questionId}_${index}`
  const DEFAULT_QUESTION_ID = 'QID1'
  const isInvalid =
    (isEmpty(answers[id]) && validation.isValid === false) ||
    (id !== 'QID1_1' && !question.validation.email && validation.isValid === false) ||
    (!isEmpty(answers[id]) && question.validation.email && isEmailInvalid(question))

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      event.preventDefault()

      const input = event.target as HTMLInputElement
      setValue(input.value, id)
    },
    [setValue, id],
  )

  const emailOnFocus = React.useContext(EmailOnFocusContext)

  const isDefaultQuestion = questionId === DEFAULT_QUESTION_ID
  // there is currently only one special type of text entry choice, a salesforce country of origin
  return (
    <div
      className={classnames({
        [styles.welcomeTextEntryWrap]: isDefaultQuestion,
        [styles.multipleChoiceTextEntryWrap]: !isDefaultQuestion,
      })}
    >
      <label
        className={classnames({
          [styles.welcomeTextEntryLabel]: isDefaultQuestion,
          [styles.textEntryLabel]: !isDefaultQuestion,
        })}
        htmlFor={id}
      >
        {choices?.[index || '1'].choiceText}
      </label>
      <div
        className={classnames({
          [styles.errorWrapper]: !isDefaultQuestion,
        })}
      >
        <input
          className={classnames({
            [styles.welcomeTextEntryInput]: isDefaultQuestion,
            [styles.multipleChoiceTextEntryInput]: !isDefaultQuestion,
            [styles.error]: isInvalid,
          })}
          data-cy={isDefaultQuestion ? 'quiz_firstStep-welcomeInput' : 'quiz_step-multipleChoiceInput'}
          id={id}
          name={id}
          onChange={handleChange}
          onFocus={choices[index || 1].description === 'email' && emailOnFocus ? emailOnFocus : undefined}
          type="text"
          value={answers?.[id]}
        />
        {isInvalid && (
          <div className={classnames(styles.errorText)} data-cy="quiz_step-errorText">
            {errorMessage(choices[index || 1].description!)}
          </div>
        )}
      </div>
    </div>
  )
}

export {TextEntry}
