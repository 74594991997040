import {QuizData} from 'components/Quiz/Quiz.types'

// we tag a question with data on how long a variable in a question can be in the qualtrics html
const lengthCap = (quiz: QuizData, variable: string, variableValue: string | number, isSmall: boolean | undefined) => {
  if (
    !quiz.activeQuestion ||
    !variableValue ||
    !quiz.activeQuestion.variableMaxLengths ||
    typeof variableValue !== 'string'
  ) {
    return
  }

  // the length is saved under the variable name which happens to be the question id
  const maxLengths = quiz.activeQuestion.variableMaxLengths[variable]
  if (!maxLengths) return variableValue

  // we have a different max length for small screens and medium and up screens
  if ((isSmall && variableValue.length > maxLengths.small) || variableValue.length > maxLengths.medium) {
    variableValue = `${variableValue.slice(0, isSmall ? maxLengths.small : maxLengths.medium)}...`
  }

  return variableValue
}

const getVariableValue = (quiz: QuizData, variable: {questionId: string; type: string; id: string}) => {
  let variableValue = ''
  if (variable.type === 'ChoiceTextEntryValue') {
    const val: string | number = quiz.answers[`${variable.questionId}_${variable.id}`]
    variableValue = typeof val === 'string' ? val : ''
  }

  if (
    quiz.answers &&
    quiz.activeQuestion &&
    // the question is multiple choice
    variable.type === 'ChoiceGroup' &&
    // qualtrics has sent a list of display string to use in place of the answer, which is just the choice index
    quiz.activeQuestion.variableChoiceGroupValues &&
    // the user has answered the question
    quiz.answers[variable.questionId]
  ) {
    const answer = quiz.answers[variable.questionId]
    variableValue = quiz.activeQuestion.variableChoiceGroupValues[typeof answer === 'number' ? answer - 1 : 1]
  }

  return variableValue
}

function extractVariables(text: string) {
  // variable data is 3 parts. The variable name, the answer type, and choice index
  // shape of var ex: ${<questionID>/<questiontype>/<choice-index>}
  // ${q://QID1/ChoiceTextEntryValue/1}

  const variables: Array<{questionId: string; type: string; id: string}> = []
  // following the linting rule here breaks the regex
  // eslint-disable-next-line no-useless-escape
  const variableRegex = /\$\{q:\/\/([^\/]*)\/([^\/]*)\/([^\/]*)}/
  const variableRegexGlobal = new RegExp(variableRegex, 'g')
  const matches = text.match(variableRegexGlobal)

  matches?.forEach(matchStr => {
    const captureGroups = matchStr.match(variableRegex)
    variables.push({
      id: captureGroups?.[3] ?? '',
      questionId: captureGroups?.[1] ?? '',
      type: captureGroups?.[2] ?? '',
    })
  })

  return variables
}

export const parseQuestionTextVariables = (quiz: QuizData, isSmall: boolean | undefined) => {
  if (!quiz.activeQuestion || !quiz.activeQuestion.tags) return

  for (const tag in quiz.activeQuestion.tags as any) {
    // go through all of the html tag data and pull the text info so we can check each one for the variable
    const texts = quiz.activeQuestion.tags[tag]

    texts.forEach((text, textI) => {
      let textResult = text

      if (textResult) {
        const variables = extractVariables(textResult)

        variables.forEach(variable => {
          const variableValue = getVariableValue(quiz, variable)
          const safeVariableValue = lengthCap(quiz, variable.questionId, variableValue, isSmall)

          textResult =
            textResult?.replace(
              `$\{q://${variable.questionId}/${variable.type}/${variable.id}}`,
              safeVariableValue || '',
            ) || ''

          if (quiz.activeQuestion && quiz.activeQuestion.tags) {
            quiz.activeQuestion.tags[tag][textI] = textResult
          }
        })
      }
    })
  }
}
