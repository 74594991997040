import React from 'react'
import {kebabCase} from 'lodash'

import {IPostersAndPresentationsFields} from '@invitae/contentful-types'
import {NvHeading, NvPagination, NvSpinner} from '@invitae/ids-react'
import {SearchBar, useTextCopy} from '@invitae/nucleobase'

import {useEducationCenter} from 'components/EducationCenter/EducationCenter.hooks'
import {TextCopyType} from 'constants/textCopies'
import {displayDateRange} from 'utils/dates'

import PosterOrPresentationItem from './PosterOrPresentationItem'

import styles from './PostersAndPresentationsCollection.module.scss'

export interface IPostersAndPresentationsProps {
  tabName: string
}

const PostersAndPresentations = ({tabName}: IPostersAndPresentationsProps) => {
  const {getText} = useTextCopy<TextCopyType<'papersAndPresentationsSection'>>()

  const scientificPublicationsTabTitle = getText('scientificPublicationsTabTitle')

  const {
    entries,
    error,
    isLoading,
    handleSearchFilter,
    totalResultsCount,
    specialtyAreaFilterConfig,
    yearFilterConfig,
    searchValue,
    tab,
  } = useEducationCenter<IPostersAndPresentationsFields>({
    defaultTabId: kebabCase(scientificPublicationsTabTitle),
    modelName: 'postersAndPresentations',
  })

  return (
    <>
      <SearchBar
        defaultValue={tabName === tab ? searchValue : ''}
        filters={[specialtyAreaFilterConfig, yearFilterConfig]}
        onSearch={handleSearchFilter}
        searchResultsCount={totalResultsCount}
      />
      <NvSpinner isLoading={isLoading} />
      {!isLoading && error && (
        <NvHeading className={styles.errorMessage} element="h3" level={3}>
          {error}
        </NvHeading>
      )}
      {!isLoading &&
        !error &&
        entries?.map((postersAndPresentationsItem, index) => {
          const {startDate, endDate, conference, location, posters, presentations} = postersAndPresentationsItem
          const displayableDate = displayDateRange(startDate, endDate)

          return (
            <div key={index}>
              <NvHeading className={styles.postersAndPresentationHeader} element="h2" level={2}>
                {conference}
              </NvHeading>
              <p>{`${displayableDate} • ${location}`}</p>

              {posters?.map((poster, index) => (
                <PosterOrPresentationItem
                  isFirstItemInList={index === 0}
                  item={poster}
                  key={poster.sys.id}
                  subheader={posters.length > 1 ? 'Posters' : 'Poster'}
                />
              ))}

              {presentations?.map((presentation, index) => (
                <PosterOrPresentationItem
                  isFirstItemInList={index === 0}
                  item={presentation}
                  key={presentation.sys.id}
                  subheader={presentations.length > 1 ? 'Presentations' : 'Presentation'}
                />
              ))}
            </div>
          )
        })}
      {!isLoading && <NvPagination />}
    </>
  )
}

export default PostersAndPresentations
