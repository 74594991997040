import * as React from 'react'
import classnames from 'classnames'
import {DateTime} from 'luxon'

import {IWebinarFields} from '@invitae/contentful-types'
import {NvHeading, NvLink, NvMarkdown} from '@invitae/ids-react'
import {BaseMarcomComponent} from '@invitae/nucleobase'

import {WebinarSectionItemsCollectionProps} from '../WebinarSectionItemsCollection'

import styles from './WebinarSectionItem.module.scss'

export interface IWebinarSectionItemProps
  extends Omit<
    WebinarSectionItemsCollectionProps,
    'webinarType' | 'queryObject' | 'setQueryObject' | 'tabId' | 'tabName'
  > {
  webinar: IWebinarFields
  isFirstItemInList: boolean
  isInStandalonePage?: boolean
}
const WebinarSectionItem = (props: IWebinarSectionItemProps) => {
  const {
    webinar: {title, presenters, abstract, date, time, link, slug},
    isFirstItemInList,
    shouldShowTime,
    buttonText,
    isInStandalonePage = false,
  } = props
  const displayableDate = DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED as any)

  return (
    <div className={classnames(styles.webinarItem, {[styles.borderTop]: !isFirstItemInList})}>
      <div className={styles.titleAndButtonTopRow} data-testid="heading-container">
        {isInStandalonePage && (
          <NvHeading
            className={styles.titleAndPresentersInStandalonePage}
            element={isInStandalonePage ? 'h1' : 'h3'}
            level={3}
          >
            {title}
          </NvHeading>
        )}
        {!isInStandalonePage && (!slug || slug.length === 0) && (
          <BaseMarcomComponent classes={{root: styles.titleAndPresenters}} source={link?.fields?.linkScheme}>
            <NvHeading element="h3" level={3}>
              {title}
            </NvHeading>
          </BaseMarcomComponent>
        )}
        {!isInStandalonePage && slug && slug?.length > 0 && (
          <NvLink className={styles.titleAndPresenters} href={`/us/webinars/${slug}`} variant="plain">
            <NvHeading element="h3" level={3}>
              {title}
            </NvHeading>
          </NvLink>
        )}
        <BaseMarcomComponent
          classes={{root: classnames('nv-button', styles.webinarButton, styles.sideButton)}}
          source={link?.fields?.linkScheme}
        >
          {buttonText}
        </BaseMarcomComponent>
      </div>
      <div className={styles.itemInfoText}>
        <span>{presenters}</span>
      </div>
      <div className={styles.itemInfoText}>
        <span>{displayableDate}</span>
        {shouldShowTime && (
          <span data-testid="webinar-time">
            {` | `}
            {time}
          </span>
        )}
      </div>
      <NvMarkdown>{abstract}</NvMarkdown>
      <BaseMarcomComponent
        classes={{root: classnames('nv-button', styles.webinarButton, styles.bottomButton)}}
        source={link?.fields?.linkScheme}
      >
        {buttonText}
      </BaseMarcomComponent>
    </div>
  )
}

export default WebinarSectionItem
