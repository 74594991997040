import * as React from 'react'
import {IconClock, IconFax, IconLocation, IconPhone} from 'assets/icons'

import {useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import styles from './ContactInfo.module.scss'

interface FAQLink {
  id: number
  displayText: string
  url: string
}

interface TestingInquiries {
  phone: string
  operatingHours: string
  fax?: string
}

interface LocalPhoneNumber {
  country: string
  phone: string
  id: number
}

export interface ContactInfoProps {
  FAQLinks: FAQLink[]
  localPhoneNumbers: LocalPhoneNumber[]
  USTestingInquiries: TestingInquiries
  intTestingInquiries: TestingInquiries
  headquartersAndLaboratoryPhone: string
}

const ContactInfo = ({
  FAQLinks,
  USTestingInquiries,
  intTestingInquiries,
  localPhoneNumbers,
  headquartersAndLaboratoryPhone,
}: ContactInfoProps) => {
  const {getText} = useTextCopy<TextCopyType<'contactForm'>>()
  // create a new array of localPhoneNumbers which will include all the localPhoneNumbers except the first one
  // to run a map on them. This is required to apply a different style only to the first element as it has an icon at the left
  // design: https://zpl.io/VD0vk33
  const localPhoneNumbersExceptFirst = localPhoneNumbers.length > 1 ? localPhoneNumbers.slice(1) : undefined

  return (
    <div className={styles.root}>
      <p className={styles.sectionHeader}>{getText('frequentlyAsked')}</p>
      <div className={styles.faqLinksWrapper}>
        {FAQLinks.map(FAQLink => (
          <p className={styles.link} key={FAQLink.id}>
            <a href={FAQLink.url}>{FAQLink.displayText}</a>
          </p>
        ))}
      </div>
      <div className={styles.divider} />
      <p className={styles.sectionHeader}>{getText('testingInquiries')}</p>
      <div className={styles.iconAndTextWrapper}>
        <IconPhone />
        <span className={styles.textWithIcon}>{USTestingInquiries.phone}</span>
      </div>
      <div className={styles.iconAndTextWrapper}>
        <IconClock />
        <span className={styles.textWithIcon}>{USTestingInquiries.operatingHours}</span>
      </div>
      {USTestingInquiries.fax && (
        <div className={styles.iconAndTextWrapper}>
          <IconFax />
          <span className={styles.textWithIcon}>{USTestingInquiries.fax}</span>
        </div>
      )}
      <div className={styles.divider} />
      <p className={styles.sectionHeader}>{getText('internationalInquiries')}</p>
      <div className={styles.iconAndTextWrapper}>
        <IconPhone />
        <span className={styles.textWithIcon}>{intTestingInquiries.phone}</span>
      </div>
      <div className={styles.iconAndTextWrapper}>
        <IconClock />
        <span className={styles.textWithIcon}>{intTestingInquiries.operatingHours}</span>
      </div>
      {intTestingInquiries.fax && (
        <div className={styles.iconAndTextWrapper}>
          <IconFax />
          <span className={styles.textWithIcon}>{intTestingInquiries.fax}</span>
        </div>
      )}
      <div className={styles.divider} />
      <p className={styles.sectionHeader}>{getText('localPhoneNumbersText')}</p>
      <div className={styles.iconAndTextWrapper}>
        <IconPhone />
        <span className={styles.textWithIcon}>
          {localPhoneNumbers[0].country}: {localPhoneNumbers[0].phone}
        </span>
      </div>
      <div className={styles.localPhoneNumbersWrapper}>
        {(localPhoneNumbersExceptFirst || []).map(localPhoneNumber => (
          <p key={localPhoneNumber.id}>
            {localPhoneNumber.country}: {localPhoneNumber.phone}
          </p>
        ))}
      </div>
      <p className={styles.sectionHeader}>{getText('headquartersAndLaboratory')}</p>
      <div className={styles.iconAndTextWrapper}>
        <IconLocation />
        <span className={styles.textWithIcon}>{headquartersAndLaboratoryPhone}</span>
      </div>
    </div>
  )
}

export default ContactInfo
