/* eslint-disable react/no-multi-comp */
import * as React from 'react'

import {useCountryLocationPrefix} from '@invitae/nucleobase'

import styles from './ResultsPage.module.scss'

const BaseCard: React.FC<{image: string; url: string}> = ({children, image, url}) => {
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  return (
    <a className={styles.card} href={addCountryCodePrefix(url)}>
      <div className={styles['image-container']}>
        <img alt={String(children)} src={image} />
      </div>
      {children}
    </a>
  )
}
export const Body: React.FC = ({children}) => <p className={styles.body}>{children}</p>
export const Callout: React.FC = ({children}) => <blockquote className={styles.callout}>{children}</blockquote>
export const LeadIn: React.FC = ({children}) => <p className={styles['lead-in']}>{children}</p>
export const Reference: React.FC = ({children}) => <footer className={styles.reference}>{children}</footer>
export const Footnotes: React.FC = ({children}) => <footer className={styles.footnotes}>{children}</footer>

export const Card = {
  BreastCancerTest: () => (
    <BaseCard image="http://invitae.imgix.net/icons/cancer-green.svg" url="cancer/about-our-tests">
      Breast cancer test
    </BaseCard>
  ),
  CancerScreen: () => (
    <BaseCard image="http://invitae.imgix.net/icons/cancer-green.svg" url="staying-healthy/about-our-tests">
      Cancer screen
    </BaseCard>
  ),
  CancerTest: () => (
    <BaseCard image="http://invitae.imgix.net/icons/cancer-green.svg" url="cancer/about-our-tests">
      Cancer test
    </BaseCard>
  ),
  CardiologyTest: () => (
    <BaseCard image="http://invitae.imgix.net/icons/dsm-heart-o-m-green.svg" url="genetic-illness/why-genes-matter">
      Cardiology test
    </BaseCard>
  ),
  CardioScreen: () => (
    <BaseCard image="http://invitae.imgix.net/icons/dsm-heart-o-m-green.svg" url="staying-healthy/about-our-tests">
      Cardio screen
    </BaseCard>
  ),
  CarrierScreen: () => (
    <BaseCard image="http://invitae.imgix.net/icons/dsm-family2-o-m-green.svg" url="pregnancy/carrier-screen">
      Carrier screen
    </BaseCard>
  ),
  DiagnosticTest: () => (
    <BaseCard
      image="http://invitae.imgix.net/icons/dsm-chromosome-o-m-green.svg"
      url="genetic-illness/why-genes-matter"
    >
      Diagnostic test
    </BaseCard>
  ),
  GeneticHealthScreen: () => (
    <BaseCard image="http://invitae.imgix.net/icons/dsm-apple-o-m-green.svg" url="staying-healthy/about-our-tests">
      Genetic health screen
    </BaseCard>
  ),
  HeartHealthScreen: () => (
    <BaseCard image="http://invitae.imgix.net/icons/dsm-heart-o-m-green.svg" url="pregnancy/heart-health-screen">
      Heart health screen
    </BaseCard>
  ),
  NeurologyTest: () => (
    <BaseCard image="http://invitae.imgix.net/icons/dsm-brain-o-m-green.svg" url="genetic-illness/why-genes-matter">
      Neurology test
    </BaseCard>
  ),
  NonInvasivePrenatalScreen: () => (
    <BaseCard
      image="http://invitae.imgix.net/icons/dsm-prenatal-o-m-green.svg"
      url="pregnancy/noninvasive-prenatal-screen"
    >
      Non-invasive prenatal screening
    </BaseCard>
  ),
  OvarianCancerTest: () => (
    <BaseCard image="http://invitae.imgix.net/icons/cancer-green.svg" url="cancer/about-our-tests">
      Ovarian and uterine cancer test
    </BaseCard>
  ),
  PediatricCancerScreen: () => (
    <BaseCard image="http://invitae.imgix.net/icons/dsm-pediatrics-o-m-green.svg" url="cancer/about-our-tests">
      Pediatric cancer test
    </BaseCard>
  ),
  PediatricDiagnosticTest: () => (
    <BaseCard
      image="http://invitae.imgix.net/icons/dsm-pediatrics-o-m-green.svg"
      url="genetic-illness/why-genes-matter"
    >
      Pediatric diagnostic test
    </BaseCard>
  ),
  PreimplantationTest: () => (
    <BaseCard
      image="http://invitae.imgix.net/icons/dsm-sample-collection-repro-o-m-green.svg"
      url="pregnancy/preimplantation-test-ivf"
    >
      Preimplantation test
    </BaseCard>
  ),
  ProstateCancerTest: () => (
    <BaseCard image="http://invitae.imgix.net/icons/cancer-green.svg" url="cancer/about-our-tests">
      Prostate cancer test
    </BaseCard>
  ),
}
/* eslint-enable react/no-multi-comp */
