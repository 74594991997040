import React, {ReactNode, useCallback, useEffect} from 'react'
import {NumberParam, StringParam, useQueryParams, withDefault} from 'use-query-params'

import {NvTabs} from '@invitae/ids-react'

interface EducationCenterTabsProps {
  children: ReactNode
  defaultTabId: string
}

const EducationCenterTabs = ({children, defaultTabId}: EducationCenterTabsProps) => {
  const [query, setQuery] = useQueryParams({
    currentPage: withDefault(NumberParam, 1),
    searchQuery: withDefault(StringParam, undefined),
    specialty: withDefault(StringParam, undefined),
    tab: withDefault(StringParam, defaultTabId),
    year: withDefault(NumberParam, undefined),
  })

  const tabHandler = useCallback(
    currentTab => {
      setQuery({
        currentPage: 1,
        searchQuery: undefined,
        specialty: undefined,
        tab: currentTab,
        year: undefined,
      })
    },
    [setQuery],
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [query])

  return (
    <NvTabs defaultTabId={query?.tab || defaultTabId} onTabChange={tabHandler}>
      {children}
    </NvTabs>
  )
}

export default EducationCenterTabs
