import range from 'lodash/range'
import {DateTime} from 'luxon'

// startDate and endDate are any ISO 8601 string
export const displayDateRange = (startDate: string, endDate: string): string => {
  const startDateTime = DateTime.fromISO(startDate)
  const endDateTime = DateTime.fromISO(endDate)

  if (!startDateTime.isValid || !endDateTime.isValid) {
    return ''
  }

  // if dates are the same, return only the start date, ex: "August 6, 2019"
  if (startDateTime.day === endDateTime.day) {
    return startDateTime.toLocaleString(DateTime.DATE_FULL as any)
  }
  // if dates are in the same month, only return the month once, ex: "August 6 - 7, 2019"
  else if (startDateTime.month === endDateTime.month) {
    return `${startDateTime.toFormat('MMMM d')} - ${endDateTime.toFormat('d, yyyy')}`
  }
  // if dates are in the same year, only return the year once, ex: "August 29 - November 7, 2019"
  else if (startDateTime.year === endDateTime.year) {
    return `${startDateTime.toFormat('MMMM d')} - ${endDateTime.toFormat('MMMM d, yyyy')}`
  }
  // otherwise, display both full dates, ex: "December 29, 2018 - January 7, 2019"
  return `${startDateTime.toLocaleString(DateTime.DATE_FULL as any)} - ${endDateTime.toLocaleString(
    DateTime.DATE_FULL as any,
  )}`
}

// returns an array of years (as numbers) from beginningYear to the current year
export const getYearsToPresent = (beginningYear: number): number[] => {
  return range(beginningYear, DateTime.local().year + 1).reverse()
}
