import * as React from 'react'

import {NvHeading} from '@invitae/ids-react'
import {useCountryLocationPrefix, useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import {useHomeAnnouncementQuizClick} from './HomeAnnouncementSection.hooks'

import styles from './HomeAnnouncementSection.module.scss'

const QUIZ_PATH = '/quiz'

const HomeAnnouncementSection = () => {
  const {trackQuizClick} = useHomeAnnouncementQuizClick()
  const {addCountryCodePrefix} = useCountryLocationPrefix()
  const {getText} = useTextCopy<TextCopyType<'homeAnnouncementSection'>>({
    quizButton: (
      <a
        className={styles.quizLink}
        data-cy="section_announcement-link"
        href={addCountryCodePrefix(QUIZ_PATH)}
        onClick={() => trackQuizClick(addCountryCodePrefix(QUIZ_PATH))}
      >
        Take a short quiz
      </a>
    ),
  } as any)

  return (
    <div className={styles.announcementContainer}>
      <NvHeading className="nv-container" element="h2" level={3}>
        {getText('description')}
      </NvHeading>
    </div>
  )
}

export default HomeAnnouncementSection
