import React, {useState} from 'react'
import classNames from 'classnames'

import {useAnalyticsQueue} from '@invitae/analytics-queue'
import {NvButton, NvHeading, NvInput, NvMarkdown, NvSelect} from '@invitae/ids-react'
import {useTextCopy} from '@invitae/nucleobase'

import {Option} from 'types/option'

import {calculateResultTextCopy} from './calculateResult'
import {HOUSEHOLD_DATA, INSURANCE_TYPES} from './data'
import {
  FinancialCalculatorErrorTextCopy,
  FinancialCalculatorMiscTextCopy,
  FinancialCalculatorResultTextCopy,
  InsuranceType,
} from './enums'

import styles from './FinancialCalculator.module.scss'

const FinancialCalculator = () => {
  const {logEvent} = useAnalyticsQueue()
  const {getText} = useTextCopy<
    FinancialCalculatorResultTextCopy | FinancialCalculatorMiscTextCopy | FinancialCalculatorErrorTextCopy
  >()
  const [householdMembers, setHouseholdMembers] = useState<{label: string | number; value: string}>()
  const [insuranceType, setInsuranceType] = useState<{label: InsuranceType; value: InsuranceType}>()
  const [income, setIncome] = useState('')
  const [result, setResult] = useState<FinancialCalculatorResultTextCopy[]>([])
  // placeholder until react-hooks-form can be integrated with our ids components
  const [isHouseholdMembersError, setIsHouseholdMembersError] = useState(false)
  const [isInsuranceTypeError, setIsInsuranceTypeError] = useState(false)
  const [isIncomeError, setIsIncomeError] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!householdMembers?.value) setIsHouseholdMembersError(true)
    if (!insuranceType?.value) setIsInsuranceTypeError(true)
    if (income?.length === 0) setIsIncomeError(true)

    if (!insuranceType?.value || !householdMembers?.value || income?.length === 0) return

    // placeholder validation passed
    if (isHouseholdMembersError) setIsHouseholdMembersError(false)
    if (isInsuranceTypeError) setIsIncomeError(false)
    if (isIncomeError) setIsIncomeError(false)

    const _householdMembers =
      typeof householdMembers.value !== 'string' ? Number(householdMembers.value) : householdMembers.value

    const result = calculateResultTextCopy({
      householdMembers: _householdMembers,
      income: Number(income),
      insuranceType: insuranceType.value,
    })

    setResult(result)

    logEvent({
      eventName: 'Estimate from financial calculator',
      eventProperties: {
        estimateKey: result?.[0],
      },
    })
  }

  const handleHouseholdSelect = (option: Option<string>) => {
    setHouseholdMembers(option)

    logEvent({
      eventName: 'Number of household members from financial calculator',
      eventProperties: {
        householdMembers: option.value,
      },
    })

    if (isHouseholdMembersError) setIsHouseholdMembersError(false)
  }

  const handleInsuranceTypeSelect = (option: Option<InsuranceType, InsuranceType>) => {
    setInsuranceType(option)

    logEvent({
      eventName: 'Insurance type from financial calculator',
      eventProperties: {
        insuranceType: option.value,
      },
    })

    if (isInsuranceTypeError) setIsInsuranceTypeError(false)
  }

  const handleIncomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIncome(e.currentTarget.value)
    if (isIncomeError) setIsIncomeError(false)
  }

  const handleIncomeOnBlur = () => {
    logEvent({
      eventName: 'Annual household income from financial calculator',
      eventProperties: {
        income,
      },
    })
  }

  return (
    <form className={classNames('nv-container', styles.grid)} method="POST" onSubmit={handleSubmit}>
      <div className={classNames('nv-container', styles.aboutContainer)}>
        <NvHeading className={styles.aboutHeading} level={3}>
          {getText(FinancialCalculatorMiscTextCopy.ABOUT_HEADING)}
        </NvHeading>
        <NvMarkdown>{getText(FinancialCalculatorMiscTextCopy.ABOUT_BODY)}</NvMarkdown>
      </div>
      <div className={styles.container}>
        <NvMarkdown className={styles.header}>{getText(FinancialCalculatorMiscTextCopy.HEADING)}</NvMarkdown>
        <div className={styles.fields}>
          <NvSelect
            hasError={isHouseholdMembersError}
            label={getText(FinancialCalculatorMiscTextCopy.HOUSEHOLD_MEMBERS_LABEL)}
            name="householdMembers"
            onChange={option => handleHouseholdSelect(option as Option<string>)}
            options={HOUSEHOLD_DATA}
            required
            value={householdMembers}
            {...(isHouseholdMembersError && {
              legendText: getText(FinancialCalculatorErrorTextCopy.HOUSEHOLD_MEMBERS_FIELD_ERROR),
            })}
          />
          <NvSelect
            hasError={isInsuranceTypeError}
            label={getText(FinancialCalculatorMiscTextCopy.INSURANCE_TYPE_LABEL)}
            name="insuranceType"
            onChange={option => handleInsuranceTypeSelect(option as Option<InsuranceType, InsuranceType>)}
            options={INSURANCE_TYPES(getText)}
            required
            value={insuranceType}
            {...(isInsuranceTypeError && {
              legendText: getText(FinancialCalculatorErrorTextCopy.INSURANCE_TYPE_FIELD_ERROR),
            })}
          />
          <NvInput
            label={getText(FinancialCalculatorMiscTextCopy.ANNUAL_PRETAX_HOUSEHOLD_INCOME_LABEL)}
            onBlur={handleIncomeOnBlur}
            onChange={handleIncomeChange}
            prefixElement="$"
            type="number"
            value={income}
          />
          {isIncomeError && (
            <p className={classNames('nv-form-legend nv-fine-print-1', styles.inputError)}>
              {getText(FinancialCalculatorErrorTextCopy.ANNUAL_PRETAX_HOUSEHOLD_INCOME_FIELD_ERROR)}
            </p>
          )}
          <NvButton type="submit">{getText(FinancialCalculatorMiscTextCopy.CALCULATE)}</NvButton>
        </div>
        {result?.length > 0 && (
          <div className={styles.result}>
            {result?.map(resultTextCopy => <NvMarkdown key={resultTextCopy}>{getText(resultTextCopy)}</NvMarkdown>)}
          </div>
        )}
        <NvMarkdown className={styles.disclaimer}>{getText(FinancialCalculatorMiscTextCopy.DISCLAIMER)}</NvMarkdown>
      </div>
    </form>
  )
}

export default FinancialCalculator
