import {ContentfulClientConfig, NucleobaseConfig} from '@invitae/nucleobase'

export const nucleobaseConfig: NucleobaseConfig = {
  authAPI: process.env.AUTH_API_HOST,
  homePageRedirectUrl: process.env.HOMEPAGE_REDIRECT_URL || '/us',
  qualtricsApiToken: process.env.QUALTRICS_API_TOKEN,
  qualtricsHostName: process.env.QUALTRICS_HOST_NAME,
  searchAPI: process.env.SEARCH_API_AUTOCOMPLETE,
}

export const contentfulClientCredentials: ContentfulClientConfig = {
  accessToken: process.env.ENABLE_CONTENTFUL_PREVIEW_MODE
    ? process.env.INVITAE_CONTENTFUL_PREVIEW_ACCESS_TOKEN
    : process.env.INVITAE_CONTENTFUL_CONTENT_DELIVERY_API_KEY,
  enablePreview: !!process.env.ENABLE_CONTENTFUL_PREVIEW_MODE,
  environment: process.env.INVITAE_CONTENTFUL_ENVIRONMENT,
  space: process.env.INVITAE_CONTENTFUL_SPACE,
}
