export default [
  {label: 'Allergy And Immunology', value: 'Allergy And Immunology'},
  {label: 'Anesthesiology', value: 'Anesthesiology'},
  {label: 'Behavioral Health', value: 'Behavioral Health'},
  {label: 'Biopharmaceutical', value: 'Biopharmaceutical'},
  {label: 'Cardiology', value: 'Cardiology'},
  {label: 'Chiropractic', value: 'Chiropractic'},
  {label: 'Dentistry', value: 'Dentistry'},
  {label: 'Dermatology', value: 'Dermatology'},
  {label: 'Emergency Medicine', value: 'Emergency Medicine'},
  {
    label: 'Endocrinology, Diabetes And Metabolism',
    value: 'Endocrinology, Diabetes And Metabolism',
  },
  {label: 'Family Medicine', value: 'Family Medicine'},
  {label: 'Gastroenterology', value: 'Gastroenterology'},
  {label: 'General Practice', value: 'General Practice'},
  {label: 'Gynecologic Oncology', value: 'Gynecologic Oncology'},
  {label: 'Hematology', value: 'Hematology'},
  {label: 'Hematology/Oncology', value: 'Hematology/Oncology'},
  {label: 'Hepatology', value: 'Hepatology'},
  {label: 'Infectious Disease', value: 'Infectious Disease'},
  {label: 'Internal Medicine', value: 'Internal Medicine'},
  {
    label: 'Maternal And Fetal Medicine',
    value: 'Maternal And Fetal Medicine',
  },
  {label: 'Medical Examiner', value: 'Medical Examiner'},
  {label: 'Medical Genetics', value: 'Medical Genetics'},
  {label: 'Medical Oncology', value: 'Medical Oncology'},
  {
    label: 'Neonatal-Perinatal Medicine',
    value: 'Neonatal-Perinatal Medicine',
  },
  {label: 'Nephrology', value: 'Nephrology'},
  {label: 'Neurology', value: 'Neurology'},
  {label: 'Nuclear Medicine', value: 'Nuclear Medicine'},
  {label: 'Nutrition', value: 'Nutrition'},
  {label: 'Obstetrics And Gynecology', value: 'Obstetrics And Gynecology'},
  {label: 'Ophthalmology', value: 'Ophthalmology'},
  {label: 'Orthopedics', value: 'Orthopedics'},
  {label: 'Other', value: 'Other'},
  {label: 'Otolaryngology', value: 'Otolaryngology'},
  {label: 'Pathology', value: 'Pathology'},
  {label: 'Pediatrics', value: 'Pediatrics'},
  {label: 'Pharmacy/Pharmacogenomics', value: 'Pharmacy/Pharmacogenomics'},
  {
    label: 'Physical Medicine And Rehabilitation',
    value: 'Physical Medicine And Rehabilitation',
  },
  {label: 'Podiatry', value: 'Podiatry'},
  {label: 'Preconception', value: 'Preconception'},
  {label: 'Preventive Medicine', value: 'Preventive Medicine'},
  {label: 'Psychiatry', value: 'Psychiatry'},
  {label: 'Psychology', value: 'Psychology'},
  {label: 'Pulmonary Disease', value: 'Pulmonary Disease'},
  {label: 'Radiology', value: 'Radiology'},
  {
    label: 'Reproductive Endocrinology/Infertility',
    value: 'Reproductive Endocrinology/Infertility',
  },
  {label: 'Rheumatology', value: 'Rheumatology'},
  {label: 'Surgery', value: 'Surgery'},
  {label: 'Urology', value: 'Urology'},
]
