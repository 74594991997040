import React from 'react'
import classnames from 'classnames'

import {NvPagination, NvPaginationContextProvider, NvSpinner} from '@invitae/ids-react'
import {SearchBar, useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import {SearchResultsItem} from './SearchResultsItem'
import {SearchResultsMessage} from './SearchResultsMessage'
import {useSearchResults} from './useSearchResults.hooks'

import styles from './SearchResults.module.scss'

const SearchResults = () => {
  const {paginationInit, queryObject, isLoading, handleSearch, pagination, collection, currentMessageType, fetchData} =
    useSearchResults()

  const {getText} = useTextCopy<TextCopyType<'searchResults'>>({
    searchValue: queryObject.q,
  })

  return (
    <NvPaginationContextProvider value={paginationInit}>
      <div className={classnames('nv-container', styles.wrapper)}>
        <SearchBar
          classNames={{
            form: styles.searchForm,
            searchComponent: styles.search,
            wrapper: styles.wrapperSearch,
          }}
          defaultValue={queryObject.q}
          filters={[]}
          isLoading={isLoading}
          onSearch={handleSearch}
          searchResultsCount={pagination.text}
        />

        <NvSpinner isLoading={isLoading} />

        {!isLoading && (
          <>
            {!collection.length && queryObject.q && (
              <SearchResultsMessage
                error={currentMessageType === 'error'}
                message={getText(currentMessageType)}
                retryFn={fetchData}
              />
            )}

            <div className={styles.result}>
              {collection.map((item: {title: string; q: string; url: string}) => (
                <SearchResultsItem key={item.title} term={queryObject.q} {...item} />
              ))}
            </div>

            <NvPagination hideNumPerPage />
          </>
        )}
      </div>
    </NvPaginationContextProvider>
  )
}

export default SearchResults
