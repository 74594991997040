import {getCode} from 'country-list'

import marcomFetch from 'utils/marcomFetch/marcomFetch'

export interface ContactUsFormValues {
  firstName: string
  lastName: string
  phone: string
  email: string
  country: string
  topic?: string
  organization: string
  role: string
  postalCode: string
  rq?: string
  medicalSpecialty: string
  comment: string
}

// Please contact @Jody Brooks if any amendments to the form are needed
export const submitContactUsForm = async (values: ContactUsFormValues) => {
  const ISOCountry = getCode(values.country)
  const payload: Record<string, string> = {
    // field representing RQ
    '00N2J000007TGfH': values.medicalSpecialty,
    // field maps to Primary Clinical Interest in SalesForce
    '00N2J000007TKgS': values.rq || '',
    // field maps to Lead Type in SalesForce
    '00N500000037zzW': values.role,
    // field maps to Source Description
    '00N500000039S6M': 'Website visit initiated contact',
    company: values.organization,

    country_code: ISOCountry || '',
    debug: process.env.INVITAE_SALESFORCE_CONTACT_US_FORM_DEBUG_MODE || '',

    debug_email: process.env.INVITAE_SALESFORCE_CONTACT_US_FORM_DEBUG_EMAIL || '',
    description: values.comment,
    email: values.email,

    first_name: values.firstName,

    last_name: values.lastName,

    lead_source: values.topic || '',
    oid: process.env.INVITAE_SALESFORCE_CONTACT_US_FORM_OID || '',
    phone: values.phone,
    'Source Description': 'Website visit initiated contact',
    zip: values.postalCode,
  }
  return marcomFetch(`${process.env.INVITAE_SALESFORCE_CONTACT_US_FORM_BASE_URL}/servlet/WebToLead?encoding=UTF-8`, {
    body: new URLSearchParams(payload),
    method: 'post',
  })
}
