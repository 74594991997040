import * as React from 'react'

import {NvHeading, NvLink} from '@invitae/ids-react'
import {useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'
import {formatComma, replaceHtml} from 'utils/misc'

import styles from './SearchResultsItem.module.scss'

export type GeneResult = {
  geneDescription?: string
  geneAlias?: string
  title: string
  url: string
}

export type TestResult = {
  testDescription?: string
  testCode?: string
  geneTested?: string
  title: string
  url: string
}

export type PageResult = {
  description?: string
  title: string
  url: string
}

export type SearchResultsItemProps = GeneResult &
  PageResult &
  TestResult & {
    term: string
  }

const SearchResultsItem = ({
  term,
  url,
  title,
  description,
  geneAlias,
  geneDescription,
  geneTested,
  testCode,
  testDescription,
}: SearchResultsItemProps) => {
  const headingRef = React.useRef<HTMLElement>(null)

  const {getText} = useTextCopy<TextCopyType<'searchResults'>>()

  const attributesAssoc = {
    [getText('descriptionLabel')]: description,
    [getText('testCodeLabel')]: testCode,
    [getText('geneTestedLabel')]: geneTested,
    [getText('testDescriptionLabel')]: testDescription,
    [getText('geneDescriptionLabel')]: geneDescription,
    [getText('geneAliasLabel')]: geneAlias,
  } as Record<string, string>

  React.useEffect(() => {
    replaceHtml({
      componentRef: headingRef as React.MutableRefObject<HTMLElement>,
      findTerm: term,
      replaceTo: (text: string) => `<span class="${styles.highlight}">${text}</span>`,
    })
  }, [headingRef, term])

  const showText = (text: string) => {
    return replaceHtml({
      findTerm: term,
      replaceTo: (text: string) => `<span class="${styles.highlight}">${text}</span>`,
      text: formatComma(text),
    })
  }

  return (
    <div className={styles.item}>
      <NvLink className={styles.heading} href={url} variant="plain">
        <NvHeading className={styles.itemHeader} level={3}>
          <span ref={headingRef}>{title}</span>
        </NvHeading>
      </NvLink>

      <div className={styles.description}>
        {Object.keys(attributesAssoc).map(attr => {
          return (
            attributesAssoc[attr] && (
              <p key={`${title}-${attr}`}>
                <b>{attr}:</b> <span dangerouslySetInnerHTML={{__html: showText(attributesAssoc[attr])}} />
              </p>
            )
          )
        })}
      </div>
    </div>
  )
}

export default SearchResultsItem
