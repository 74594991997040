/* eslint-disable spellcheck/spell-checker */
import * as React from 'react'

const IconPhone = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero" transform="translate(-525 -797)">
          <g transform="translate(525 244)">
            <g transform="translate(0 505)">
              <g transform="translate(0 46)">
                <g transform="translate(0 2)">
                  <g>
                    <path d="M19.261 13.722l-4.234-1.816a1.225 1.225 0 00-1.425.348l-1.723 2.105a13.672 13.672 0 01-6.234-6.234L7.75 6.402c.42-.345.56-.926.348-1.425L6.28.738A1.224 1.224 0 004.883.031L.945.941c-.554.126-.947.62-.945 1.188C0 12.023 8.02 20 17.87 20a1.22 1.22 0 001.193-.948l.905-3.934a1.22 1.22 0 00-.707-1.395zm-1.414 5.028c-9.16-.012-16.585-7.434-16.593-16.594l3.89-.898 1.797 4.195-2.843 2.324c1.89 4.031 4.082 6.23 8.129 8.13l2.324-2.845 4.195 1.797-.899 3.89z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconPhone
/* eslint-enable spellcheck/spell-checker */
