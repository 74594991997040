import {FormikValues, validateYupSchema, yupToFormErrors} from 'formik'
import * as yup from 'yup'

const fieldIsRequiredErrorMessage = 'This field is required'

const nonMedicalRoles = ['Patient', 'Patient Family/Friend/Advocate', 'Other']

export const getValidationSchemaForRole = (values: FormikValues) => {
  let validationSchema = yup.object().shape({
    comment: yup.string().required(fieldIsRequiredErrorMessage),
    country: yup.string().required(fieldIsRequiredErrorMessage),
    email: yup.string().email().required(fieldIsRequiredErrorMessage),
    firstName: yup.string().required(fieldIsRequiredErrorMessage),
    isCaptchaValid: yup.boolean().oneOf([true], 'CAPTCHA is required'),
    lastName: yup.string().required(fieldIsRequiredErrorMessage),
    phone: yup.string().required(fieldIsRequiredErrorMessage),
    role: yup.string().required(fieldIsRequiredErrorMessage),
    topic: yup.string().required(fieldIsRequiredErrorMessage),
  })

  const medicalRolesValidationSchema = yup.object({
    medicalSpecialty: yup.string().required(fieldIsRequiredErrorMessage),
    organization: yup.string().required(fieldIsRequiredErrorMessage),
  }) as any

  if (!nonMedicalRoles.includes(values.role)) {
    validationSchema = validationSchema.concat(medicalRolesValidationSchema)
  }

  try {
    validateYupSchema<FormikValues>(values, validationSchema, true)
  } catch (err) {
    return yupToFormErrors(err)
  }

  return {}
}
