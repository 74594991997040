import * as React from 'react'
import classnames from 'classnames'
import {getNames as getCountryNames} from 'country-list'
import {Formik, FormikState} from 'formik'

import {NvHeading, NvSpinner} from '@invitae/ids-react'
import {NvFormikAsyncSelect, NvFormikInput, NvFormikSelect} from '@invitae/ids-react-formik'
import {useAnalyticsQueue, useTextCopy} from '@invitae/nucleobase'

import GoogleCaptcha from 'components/GoogleCaptcha/GoogleCaptcha'
import clientRoles from 'constants/clientRoles'
import contactUsTopics from 'constants/contactUsTopics'
import medicalSpecialties from 'constants/medicalSpecialties'
import {TextCopyType} from 'constants/textCopies'
import useFormTimer from 'utils/useFormTimer'

import {submitContactUsForm} from './common'
import {useOrganizations} from './contactForm.hooks'
import ContactFormError from './ContactFormError'
import ContactFormSuccess from './ContactFormSuccess'
import {getValidationSchemaForRole} from './contactFormValidations'
import ContactInfo from './ContactInfo'
import {contactInfo} from './contactInfoData'

import styles from './ContactForm.module.scss'

const SUBMIT_CONTACT_FORM_EVENT_NAME = 'Complete Contact Us Form'

interface ContactFormValues {
  firstName: string
  lastName: string
  phone: string
  email: string
  country: string
  organization: string
  role: string
  postalCode: string
  rq?: string
  medicalSpecialty: string
  comment: string
  topic: string
  isCaptchaValid: boolean
}

const contactFormInitialValues: ContactFormValues = {
  comment: '',
  country: 'United States of America',
  email: '',
  firstName: '',
  isCaptchaValid: false,
  lastName: '',
  medicalSpecialty: '',
  organization: '',
  phone: '',
  postalCode: '',
  role: '',
  rq: '',
  topic: '',
}

const shouldShowOrganizationAndSpecialtyFields = (role: string) =>
  role !== 'Patient' && role !== 'Patient Family/Friend/Advocate'

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [requestErrored, setRequestErrored] = React.useState(false)
  const {getOrganizations} = useOrganizations()
  const {startFormTimer, getTotalTime} = useFormTimer()
  const {logEvent} = useAnalyticsQueue()
  const {getText} = useTextCopy<TextCopyType<'contactForm'>>()

  const contactInfoData = {
    FAQLinks: [
      {
        displayText: getText('patientSupportCenter'),
        id: 1,
        url: 'https://patient-support.invitae.com/hc/en-us/',
      },
      {
        displayText: getText('clinicalSupportCenter'),
        id: 2,
        url: 'https://support.invitae.com/hc/en-us',
      },
    ],
    ...contactInfo,
  }

  const submitAnalyticsEvent = React.useCallback(
    (values: ContactFormValues) => {
      const totalTime = getTotalTime()
      logEvent({
        eventName: SUBMIT_CONTACT_FORM_EVENT_NAME,
        eventProperties: {
          'Lead Type': values.role,
          'Medical Specialty': values.medicalSpecialty,
          Topic: values.topic,
          'Total Time To Complete': totalTime || '',
        },
      })
    },
    [logEvent, getTotalTime],
  )
  const handleSubmit = async (
    values: ContactFormValues,
    resetForm: (a: Partial<FormikState<ContactFormValues>>) => void,
  ) => {
    setIsLoading(true)
    try {
      await submitContactUsForm(values)
      submitAnalyticsEvent(values)
      setFormSubmitted(true)
      resetForm({})
    } catch (e) {
      setRequestErrored(true)
      setIsLoading(false)
    }
    setIsLoading(false)
  }
  const fieldsDisabled = isLoading || requestErrored || formSubmitted
  return (
    <div className={styles.root}>
      <div className={styles.background} />
      <NvHeading className={styles.heading} element="h2" level={2}>
        {getText('title')}
      </NvHeading>
      <div className={styles.formAndContactInfoWrapper}>
        <Formik
          initialValues={contactFormInitialValues}
          onSubmit={(values, {resetForm}) => handleSubmit(values as ContactFormValues, resetForm)}
          validate={getValidationSchemaForRole}
          validateOnBlur={false}
        >
          {({values, handleSubmit}) => (
            <form className={styles.formRoot} onChange={startFormTimer} onSubmit={handleSubmit}>
              <ContactFormError hasError={requestErrored} />
              <NvSpinner classes={{root: styles.LoadingDnaSpinnerComponent}} isLoading={isLoading} />
              <ContactFormSuccess isSuccess={formSubmitted} />
              <div
                className={
                  formSubmitted || isLoading || requestErrored
                    ? classnames(styles.form, styles.backdropFilter)
                    : styles.form
                }
              >
                <div className={styles.row}>
                  <NvFormikInput
                    classes={{root: styles.fieldWrapper}}
                    disabled={fieldsDisabled}
                    id="contactUsFormFirstName"
                    label={`${getText('firstName')}*`}
                    name="firstName"
                    type="text"
                  />
                  <NvFormikInput
                    classes={{root: styles.fieldWrapper}}
                    disabled={fieldsDisabled}
                    id="contactUsFormLastName"
                    label={`${getText('lastName')}*`}
                    name="lastName"
                    type="text"
                  />
                </div>
                <div className={styles.row}>
                  <NvFormikInput
                    classes={{root: styles.fullWidthInputField}}
                    disabled={fieldsDisabled}
                    id="contactUsFormEmail"
                    label={`${getText('email')}*`}
                    name="email"
                    type="email"
                  />
                </div>
                <div className={styles.row}>
                  <NvFormikSelect
                    classes={{
                      root: !shouldShowOrganizationAndSpecialtyFields(values.role)
                        ? styles.fullWidthInputField
                        : styles.fieldWrapper,
                    }}
                    isDisabled={fieldsDisabled}
                    label={`${getText('iAmA')}*`}
                    name="role"
                    options={clientRoles}
                  />
                  {shouldShowOrganizationAndSpecialtyFields(values.role) && (
                    <div className={styles.fieldWrapper}>
                      <NvFormikAsyncSelect
                        getOptions={getOrganizations}
                        isDisabled={fieldsDisabled}
                        label={`${getText('organization')}*`}
                        name="organization"
                        placeholder="Search"
                      />
                    </div>
                  )}
                </div>
                <div className={styles.row}>
                  <NvFormikSelect
                    classes={{root: styles.fieldWrapper}}
                    isDisabled={fieldsDisabled}
                    label={`${getText('country')}*`}
                    name="country"
                    options={getCountryNames().map(countryName => ({
                      label: countryName,
                      value: countryName,
                    }))}
                  />
                  <NvFormikInput
                    classes={{root: styles.fieldWrapper}}
                    disabled={fieldsDisabled}
                    id="contactUsFormZip"
                    label={`${getText('zipCode')}*`}
                    name="postalCode"
                    type="text"
                  />
                </div>
                <div className={styles.row}>
                  <NvFormikInput
                    classes={{
                      root: !shouldShowOrganizationAndSpecialtyFields(values.role)
                        ? styles.fullWidthInputField
                        : styles.fieldWrapper,
                    }}
                    disabled={fieldsDisabled}
                    id="contactUsFormPhone"
                    label={`${getText('phone')}*`}
                    name="phone"
                    type="text"
                  />
                  {shouldShowOrganizationAndSpecialtyFields(values.role) && (
                    <NvFormikSelect
                      classes={{root: styles.fieldWrapper}}
                      isDisabled={fieldsDisabled}
                      label={`${getText('medicalSpecialty')}*`}
                      name="medicalSpecialty"
                      options={medicalSpecialties}
                    />
                  )}
                </div>
                <div className={styles.row}>
                  <NvFormikSelect
                    classes={{root: styles.fullWidthInputField}}
                    isDisabled={fieldsDisabled}
                    label={`${getText('topic')}*`}
                    name="topic"
                    options={contactUsTopics}
                  />
                </div>
                {values.topic === 'Billing' && (
                  <div className={styles.row}>
                    <NvFormikInput
                      classes={{field: styles.commentField, root: styles.fullWidthInputField}}
                      disabled={fieldsDisabled}
                      id="rq"
                      label={getText('rq')}
                      name="rq"
                      type="text"
                    />
                  </div>
                )}
                <div className={styles.row}>
                  <NvFormikInput
                    as="textarea"
                    classes={{field: styles.commentField, root: styles.fullWidthInputField}}
                    disabled={fieldsDisabled}
                    id="contactUsFormComment"
                    label={`${getText('questionOrComment')}*`}
                    name="comment"
                  />
                </div>
                <div className={classnames(styles.row, styles.captchaAndSubmitbuttonWrapper)}>
                  <GoogleCaptcha name="isCaptchaValid" siteKey={process.env.GOOGLE_RECAPTCHA_PUBLIC_KEY || ''} />
                  <button className={classnames(styles.button, 'nv-button')} type="submit">
                    {getText('submitButton')}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <ContactInfo {...contactInfoData} />
      </div>
    </div>
  )
}

export default ContactForm
