import * as React from 'react'

import {Body, Callout, Card, Footnotes, LeadIn, Reference} from './ResultsComponents'

// This is a temporary file until we find a dynamic way to store these values. Everything from the manuscript should be
// here, word by word. https://drive.google.com/file/d/1Uce4_UQFzlyiZIg3ZTB4NGw5PWeDmuEP/view

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const embeddedData = {
  pregnancy: {
    firstTrimester: (
      <>
        <Body>
          When you’re pregnant, you want everything to go right. Understanding your genes can help you make more
          informed choices for a healthy pregnancy and happy baby.
        </Body>
        <Callout>
          The American College of Obstetricians and Gynecologists recommends that all pregnant women be offered genetic
          screening. <sup>1-3</sup>
        </Callout>
        <Body>That includes carrier screening and non-invasive prenatal screening.</Body>
        <Body>
          We know you’re scheduling a lot of OB appointments right now. Genetic testing can give you important
          information to help you make the most of that time.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.CarrierScreen />
        <Card.NonInvasivePrenatalScreen />
        <Reference>
          Prior, TW. 2008. Carrier screening for spinal muscular atrophy. Genetics in Medicine. 2008(10):p840–843.
        </Reference>
        <Reference>
          American College of Obstetricians and Gynecologists. Carrier screening in the age of genomic medicine. Obstet
          Gynecol. 2017(690);129:e35–40.
        </Reference>
        <Reference>
          ACOG guidelines recommend NIPT for all pregnancies regardless of risk. Accessed November 24, 2020.
          https://www.genomeweb.com/molecular-diagnostics/acog-guidelines-recommend-nipt-all-pregnancies-regardless-risk#.X71PaVNKibc
        </Reference>
      </>
    ),
    secondTrimester: (
      <>
        <Body>
          When you’re pregnant, you want everything to go right. Understanding your genes can help you make more
          informed choices for a healthy pregnancy and happy baby.
        </Body>
        <Callout>
          Non-invasive prenatal screening is a simple blood test that checks to see if your baby is at risk for
          inherited conditions—plus it can predict your baby&#39;s sex as early as 10 weeks.
        </Callout>
        <Body>We offer one of the most affordable options.</Body>
        <LeadIn>Get started with</LeadIn>
        <Card.NonInvasivePrenatalScreen />
      </>
    ),
    thirdTrimester: (
      <>
        <Body>
          When you’re pregnant, you want everything to go right. Understanding your genes can help you make more
          informed choices for a healthy pregnancy and happy baby.
        </Body>
        <Callout>
          Non-invasive prenatal screening is a simple blood test that checks to see if your baby is at risk for certain
          genetic disorders.
        </Callout>
        <Body>We offer one of the most affordable options.</Body>
        <LeadIn>Get started with</LeadIn>
        <Card.NonInvasivePrenatalScreen />
      </>
    ),
  },
  ttc: {
    justStarting: (
      <>
        <Body>
          Now’s a great time for genetic testing. It gives you more options for conception and can help you plan for a
          healthy pregnancy.
        </Body>
        <Callout>
          The American College of Obstetricians and Gynecologists recommends that all women who are considering
          pregnancy be offered genetic testing. <sup>1-3</sup>
        </Callout>
        <LeadIn>Get started with</LeadIn>
        <Card.CarrierScreen />
        <Reference>
          Prior, TW. 2008. Carrier screening for spinal muscular atrophy. Genetics in Medicine. 2008(10):p840–843.
        </Reference>
        <Reference>
          American College of Obstetricians and Gynecologists. Carrier screening in the age of genomic medicine. Obstet
          Gynecol. 2017(690);129:e35–40.
        </Reference>
        <Reference>
          ACOG guidelines recommend NIPT for all pregnancies regardless of risk. Accessed November 24, 2020.
          https://www.genomeweb.com/molecular-diagnostics/acog-guidelines-recommend-nipt-all-pregnancies-regardless-risk#.X71PaVNKibc
        </Reference>
      </>
    ),

    beenTrying: (
      <>
        <Body>
          Now’s a great time for genetic testing. It gives you more options for conception and can help you plan for a
          healthy pregnancy.
        </Body>
        <Callout>
          The American College of Obstetricians and Gynecologists recommends that all women who are considering
          pregnancy be offered genetic testing. <sup>1-3</sup>
        </Callout>
        <LeadIn>Get started with</LeadIn>
        <Card.CarrierScreen />
        <Reference>
          Prior, TW. 2008. Carrier screening for spinal muscular atrophy. Genetics in Medicine. 2008(10):p840–843.
        </Reference>
        <Reference>
          American College of Obstetricians and Gynecologists. Carrier screening in the age of genomic medicine. Obstet
          Gynecol. 2017(690);129:e35–40.
        </Reference>
        <Reference>
          ACOG guidelines recommend NIPT for all pregnancies regardless of risk. Accessed November 24, 2020.
          https://www.genomeweb.com/molecular-diagnostics/acog-guidelines-recommend-nipt-all-pregnancies-regardless-risk#.X71PaVNKibc
        </Reference>
      </>
    ),
    goingThrough: (
      <>
        <Body>
          Now’s a great time for genetic testing. It gives you more options for conception and can help you plan for a
          healthy pregnancy.
        </Body>
        <Body>
          Having a baby isn’t a one-size-fits-all experience. And when you’re going through IVF, you want to plan for
          success.
        </Body>
        <Callout>
          That’s why so many families make preimplantation genetic testing a standard part of their IVF treatment plan.
        </Callout>
        <Body>
          This test helps identify embryos that are most suitable for transfer* during IVF, giving you the best chance
          of implantation and a successful pregnancy.
        </Body>
        <LeadIn>Learn more about</LeadIn>
        <Card.PreimplantationTest />
        <Footnotes>
          *Most suitable implies the expected number of chromosomes indicated by Invitae preimplantation genetic
          testing.
        </Footnotes>
      </>
    ),
  },
  cancer: {
    breastCancer: (
      <>
        <Body>
          Understanding your genes can help guide you and your doctor to the most effective treatments, bringing you one
          step closer to beating cancer and then staying healthy long term. We offer a number of tests focused on
          specific types of cancer. Each comes with clear next steps, so you and your doctor can make decisions
          personalized to your genes.
        </Body>
        <Callout>
          The American Society of Breast Surgeons recommends that genetic testing be made available to all patients with
          breast cancer. <sup>1</sup>
        </Callout>
        <Body>A cancer diagnosis is stressful and overwhelming. We’re here to help.</Body>
        <LeadIn>Get started with</LeadIn>
        <Card.BreastCancerTest />
        <Reference>
          American Society of Breast Surgeons. Consensus guideline on genetic testing for hereditary breast cancer.
          Issued February 10, 2019.
        </Reference>
      </>
    ),
    prostate: (
      <>
        <Body>
          Understanding your genes can help guide you and your doctor to the most effective treatments, bringing you one
          step closer to beating cancer and then staying healthy long term. We offer a number of tests focused on
          specific types of cancer. Each comes with clear next steps, so you and your doctor can make decisions
          personalized to your genes.
        </Body>
        <Callout>
          Up to 1 in 6 men with prostate cancer has an inherited genetic mutation. <sup>1</sup>
        </Callout>
        <Body>A cancer diagnosis is stressful and overwhelming. We’re here to help.</Body>
        <LeadIn>Get started with</LeadIn>
        <Card.ProstateCancerTest />
        <Reference>
          Nicolosi P, Ledet E, Yang S, et al. Prevalence of germline variants in prostate cancer and implications for
          current genetic testing guidelines. JAMA Oncol. 2019;5(4):523–528.
        </Reference>
      </>
    ),
    uterine: (
      <>
        <Body>
          Understanding your genes can help guide you and your doctor to the most effective treatments, bringing you one
          step closer to beating cancer and then staying healthy long term. We offer a number of tests focused on
          specific types of cancer. Each comes with clear next steps, so you and your doctor can make decisions
          personalized to your genes.
        </Body>
        <Callout>
          Up to 1 in 4 patients with ovarian cancer has an inherited genetic mutation. <sup>1</sup>
        </Callout>
        <Body>A cancer diagnosis is stressful and overwhelming. We’re here to help.</Body>
        <LeadIn>Get started with</LeadIn>
        <Card.OvarianCancerTest />
        <Reference>
          Walsh T, Casadei S, Lee MK, et al. Mutations in 12 genes for inherited ovarian, fallopian tube, and peritoneal
          carcinoma identified by massively parallel sequencing. Proc Natl Acad Sci USA. 2011;108(44):18032–18037.
        </Reference>
      </>
    ),
    otherCancer: (
      <>
        <Body>
          Understanding your genes can help guide you and your doctor to the most effective treatments, bringing you one
          step closer to beating cancer and then staying healthy long term. We offer a number of tests focused on
          specific types of cancer. Each comes with clear next steps, so you and your doctor can make decisions
          personalized to your genes.
        </Body>
        <Callout>
          1 in 8 patients with cancer has a gene mutation passed down through their family. <sup>1</sup>
        </Callout>
        <Body>A cancer diagnosis is stressful and overwhelming. We’re here to help.</Body>
        <LeadIn>Get started with</LeadIn>
        <Card.CancerTest />
        <Reference>
          Samadder NJ, Riegert-Johnson D, Boardman L, et al. Comparison of universal genetic testing vs
          guideline-directed targeted testing for patients with hereditary cancer syndrome. JAMA Oncol. Published online
          October 30, 2020. doi:10.1001/jamaoncol.2020.6252
        </Reference>
      </>
    ),
  },
  sickJourney: {
    heartCondition: (
      <>
        <Body>
          Finding answers to your biggest health concerns can be a complicated web. Understanding your genes could help
          you and your doctor find a diagnosis and personalize your treatment.
        </Body>
        <Callout>
          More than a dozen major medical societies recommend genetic testing as a way of diagnosing a health condition
          or finding the right treatment. <sup>1</sup>
        </Callout>
        <Body>
          We know you may be taking a lot of tests right now. Our genetic tests can be started from the comfort of your
          home with a simple saliva sample.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.CardiologyTest />
        <Reference>
          Including the American Academy of Neurology, American Epilepsy Society, Child Neurology Society, American
          College of Obstetricians and Gynecologists, Society for Maternal-Fetal Medicine, American College of
          Cardiology, American Heart Association, Heart Failure Society of America, Heart Rhythm Society, National
          Comprehensive Cancer Network, American Society of Breast Surgeons, American College of Medical Genetics,
          National Society of Genetic Counselors, and American Society of Human Genetics.
        </Reference>
      </>
    ),
    neurological: (
      <>
        <Body>
          Finding answers to your biggest health concerns can be a complicated web. Understanding your genes could help
          you and your doctor find a diagnosis and personalize your treatment.
        </Body>
        <Callout>
          More than a dozen major medical societies recommend genetic testing as a way of diagnosing a health condition
          or finding the right treatment. <sup>1</sup>
        </Callout>
        <Body>
          We know you may be taking a lot of tests right now. Our genetic tests can be started from the comfort of your
          home with a simple saliva sample.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.NeurologyTest />
        <Reference>
          Including the American Academy of Neurology, American Epilepsy Society, Child Neurology Society, American
          College of Obstetricians and Gynecologists, Society for Maternal-Fetal Medicine, American College of
          Cardiology, American Heart Association, Heart Failure Society of America, Heart Rhythm Society, National
          Comprehensive Cancer Network, American Society of Breast Surgeons, American College of Medical Genetics,
          National Society of Genetic Counselors, and American Society of Human Genetics.
        </Reference>
      </>
    ),
    rareDisease: (
      <>
        <Body>
          Finding answers to your biggest health concerns can be a complicated web. Understanding your genes could help
          you and your doctor find a diagnosis and personalize your treatment.
        </Body>
        <Callout>
          More than a dozen major medical societies recommend genetic testing as a way of diagnosing a health condition
          or finding the right treatment. <sup>1</sup>
        </Callout>
        <Body>
          We know you may be taking a lot of tests right now. Our genetic tests can be started from the comfort of your
          home with a simple saliva sample.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.DiagnosticTest />
        <Reference>
          Including the American Academy of Neurology, American Epilepsy Society, Child Neurology Society, American
          College of Obstetricians and Gynecologists, Society for Maternal-Fetal Medicine, American College of
          Cardiology, American Heart Association, Heart Failure Society of America, Heart Rhythm Society, National
          Comprehensive Cancer Network, American Society of Breast Surgeons, American College of Medical Genetics,
          National Society of Genetic Counselors, and American Society of Human Genetics.
        </Reference>
      </>
    ),
    diagnosisResult: (
      <>
        <Body>
          Finding answers to your biggest health concerns can be a complicated web. Understanding your genes could help
          you and your doctor find a diagnosis and personalize your treatment.
        </Body>
        <Callout>
          More than a dozen major medical societies recommend genetic testing as a way of diagnosing a health condition
          or finding the right treatment. <sup>1</sup>
        </Callout>
        <Body>
          We know you may be taking a lot of tests right now. Our genetic tests can be started from the comfort of your
          home with a simple saliva sample.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.DiagnosticTest />
        <Reference>
          Including the American Academy of Neurology, American Epilepsy Society, Child Neurology Society, American
          College of Obstetricians and Gynecologists, Society for Maternal-Fetal Medicine, American College of
          Cardiology, American Heart Association, Heart Failure Society of America, Heart Rhythm Society, National
          Comprehensive Cancer Network, American Society of Breast Surgeons, American College of Medical Genetics,
          National Society of Genetic Counselors, and American Society of Human Genetics.
        </Reference>
      </>
    ),
    diagnosisForChild: (
      <>
        <Body>
          Finding answers to your biggest health concerns can be a complicated web. Understanding your child’s genes
          could help your child’s doctor find a diagnosis and personalize your child’s treatment.
        </Body>
        <Callout>
          More than a dozen major medical societies recommend genetic testing as a way of diagnosing a health condition
          or finding the right treatment. <sup>1</sup>
        </Callout>
        <Body>
          We know your child may be taking a lot of tests right now. Our genetic tests can be started from the comfort
          of your home with a simple saliva sample.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.PediatricDiagnosticTest />
        <Reference>
          Including the American Academy of Neurology, American Epilepsy Society, Child Neurology Society, American
          College of Obstetricians and Gynecologists, Society for Maternal-Fetal Medicine, American College of
          Cardiology, American Heart Association, Heart Failure Society of America, Heart Rhythm Society, National
          Comprehensive Cancer Network, American Society of Breast Surgeons, American College of Medical Genetics,
          National Society of Genetic Counselors, and American Society of Human Genetics.
        </Reference>
      </>
    ),
    childCancer: (
      <>
        <Body>
          Finding answers to your biggest health concerns can be a complicated web. Understanding your child’s genes
          could help your child’s doctor personalize your child’s treatment.
        </Body>
        <Callout>
          More than a dozen major medical societies recommend genetic testing as a way of diagnosing a health condition
          or finding the right treatment. <sup>1</sup>
        </Callout>
        <Body>
          We know your child may be taking a lot of tests right now. Our genetic tests can be started from the comfort
          of your home with a simple saliva sample.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.PediatricCancerScreen />
        <Reference>
          Including the American Academy of Neurology, American Epilepsy Society, Child Neurology Society, American
          College of Obstetricians and Gynecologists, Society for Maternal-Fetal Medicine, American College of
          Cardiology, American Heart Association, Heart Failure Society of America, Heart Rhythm Society, National
          Comprehensive Cancer Network, American Society of Breast Surgeons, American College of Medical Genetics,
          National Society of Genetic Counselors, and American Society of Human Genetics.
        </Reference>
      </>
    ),
  },
  healthyJourney: {
    heartResult: (
      <>
        <Body>
          Like wearing sunscreen or getting your blood pressure checked at the doctor, genetic testing is another
          important tool to help manage your health and lifestyle.
        </Body>
        <Callout>
          77% of people say they’d likely take a test if it would help them develop a personalized health plan.
          <sup>1</sup>
        </Callout>
        <Body>
          Your genes can give you clues into your risk of developing heart disease and—if it turns out that you have an
          increased risk—there are steps you can take to help you stay healthy.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.CardioScreen />
        <Reference>
          US public opinion about personalized medicine. Personalized Medicine Coalition. Accessed November 23, 2020.
          http://www.personalizedmedicinecoalition.org/Userfiles/PMC-Corporate/file/us_public_opinion_about_personalized_medicine.pdf
        </Reference>
      </>
    ),
    cancerResult: (
      <>
        <Body>
          Like wearing sunscreen or getting your blood pressure checked at the doctor, genetic testing is another
          important tool to help manage your health and lifestyle.
        </Body>
        <Callout>
          77% of people say they’d likely take a test if it would help them develop a personalized health plan.
          <sup>1</sup>
        </Callout>
        <Body>
          Your genes can give you clues into your risk of developing cancer and—if it turns out that you have an
          increased risk—there are steps you can take to help you stay healthy.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.CancerScreen />
        <Reference>
          US public opinion about personalized medicine. Personalized Medicine Coalition. Accessed November 23, 2020.
          http://www.personalizedmedicinecoalition.org/Userfiles/PMC-Corporate/file/us_public_opinion_about_personalized_medicine.pdf
        </Reference>
      </>
    ),
    personalized: (
      <>
        <Body>
          Like wearing sunscreen or getting your blood pressure checked at the doctor, genetic testing is another
          important tool to help manage your health and lifestyle.
        </Body>
        <Callout>
          77% of people say they’d likely take a test if it would help them develop a personalized health plan.
          <sup>1</sup>
        </Callout>
        <Body>
          Your genes can give you clues into your risk of developing cancer or heart disease, as well as a few more
          actionable inherited conditions. If it turns out you have an increased risk—there are steps you can take to
          help you stay healthy.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.GeneticHealthScreen />
        <Reference>
          US public opinion about personalized medicine. Personalized Medicine Coalition. Accessed November 23, 2020.
          http://www.personalizedmedicinecoalition.org/Userfiles/PMC-Corporate/file/us_public_opinion_about_personalized_medicine.pdf
        </Reference>
      </>
    ),
    otherResultHealthy: (
      <>
        <Body>
          Like wearing sunscreen or getting your blood pressure checked at the doctor, genetic testing is another
          important tool to help manage your health and lifestyle.
        </Body>
        <Callout>
          77% of people say they’d likely take a test if it would help them develop a personalized health plan.
          <sup>1</sup>
        </Callout>
        <Body>
          Your genes can give you clues into your risk of developing cancer or heart disease, as well as a few more
          actionable inherited conditions. If it turns out you have an increased risk—there are steps you can take to
          help you stay healthy.
        </Body>
        <LeadIn>Get started with</LeadIn>
        <Card.GeneticHealthScreen />
        <Reference>
          US public opinion about personalized medicine. Personalized Medicine Coalition. Accessed November 23, 2020.
          http://www.personalizedmedicinecoalition.org/Userfiles/PMC-Corporate/file/us_public_opinion_about_personalized_medicine.pdf
        </Reference>
      </>
    ),
  },
}

export const questionToResults: {[questionId: string]: {[answerId: string]: JSX.Element}} = {
  QID20: {
    '1': embeddedData.pregnancy.firstTrimester,
    '2': embeddedData.pregnancy.secondTrimester,
    '3': embeddedData.pregnancy.thirdTrimester,
  },
  QID21: {
    '1': embeddedData.ttc.justStarting,
    '2': embeddedData.ttc.beenTrying,
    '3': embeddedData.ttc.goingThrough,
  },
  QID22: {
    '1': embeddedData.cancer.breastCancer,
    '2': embeddedData.cancer.prostate,
    '3': embeddedData.cancer.uterine,
    '4': embeddedData.cancer.otherCancer,
  },
  QID23: {
    '1': embeddedData.sickJourney.heartCondition,
    '2': embeddedData.sickJourney.neurological,
    '3': embeddedData.sickJourney.rareDisease,
    '4': embeddedData.sickJourney.diagnosisResult,
    '5': embeddedData.sickJourney.diagnosisForChild,
    '6': embeddedData.sickJourney.childCancer,
  },
  QID26: {
    '1': embeddedData.healthyJourney.heartResult,
    '2': embeddedData.healthyJourney.cancerResult,
    '3': embeddedData.healthyJourney.personalized,
    '4': embeddedData.healthyJourney.otherResultHealthy,
  },
} as const

/* eslint-enable sort-keys-fix/sort-keys-fix */
