import * as React from 'react'

import {FlagValueDictionary} from '@invitae/nucleobase'
import {InternalServerErrorPage} from '@invitae/ssr-core'

type Custom500Props = {
  featureFlags?: FlagValueDictionary
}

const Custom500 = ({featureFlags}: Custom500Props) => <InternalServerErrorPage featureFlags={featureFlags} />

export default Custom500
