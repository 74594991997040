import {cloneDeep} from 'lodash'

import {Question, QuizData} from 'components/Quiz/Quiz.types'

// TODO: this will have to be redone if we want to do deeply nested branches IE branch withing a branch
// branch flows can be nested infinitely IE a branch within a branch.
//
// - case for then there are multiple sub flows and you increment subFlowI
// - traversing questions from within a sub flow
// - reduce complexity of traversing quiz and refactor: WT-19769

// base case you are on block type flow and are moving to the next question in this block
// a simple move down the questions array of a block

const isBaseCase = (quiz: QuizData) => {
  const {flow, position} = quiz
  const {flowI, questionI} = position
  const selectedBlockFlow = flow[flowI]
  if (selectedBlockFlow.type === 'Block') {
    return selectedBlockFlow.questions[questionI + 1]
  }
  return false
}
const handleBaseCase = (quiz: QuizData) => {
  const {flow, position} = quiz
  const {flowI} = position

  const selectedBlockFlow = flow[flowI]
  if (selectedBlockFlow.type !== 'Block') {
    throw Error('The flow should be a Block type')
  }
  return selectedBlockFlow.questions[++position.questionI] || quiz.activeQuestion
}

// you have reached the end of a block the next block is going to be a branch we select the branch to go down based on one of our past answers
const isEndOfBlock = (quiz: QuizData) => {
  const {flow, position} = quiz
  const {flowI, questionI} = position
  const selectedBlockFlow = flow[flowI]
  if (selectedBlockFlow.type === 'Block') {
    return !selectedBlockFlow.questions[questionI + 1] && flow[flowI + 1].type === 'Branch'
  }
  return false
}
const handleEndOfBlock = (quiz: QuizData) => {
  const {flow, position, activeQuestion} = quiz
  const {flowI} = position

  if (activeQuestion === undefined) {
    throw Error("Don't call handleEndOfBlock at the beginning")
  }
  // the next item in this flow will be however many choices you had for the question that triggered the branches
  const nextBlockI = flowI + Object.keys(activeQuestion.choices).length + 1

  // qualtrics branches get a branch added for each option in the last question that triggered the divergence
  position.flowI = flowI + parseInt(String(activeQuestion.answers[activeQuestion.questionId]))
  position.subFlowI = 0
  position.questionI = 0

  const selectedBranchFlow = flow[position.flowI]
  if (selectedBranchFlow.type !== 'Branch') {
    throw Error()
  }
  const selectedBlockSubFlow = selectedBranchFlow.flow[position.subFlowI]
  if (selectedBlockSubFlow.type !== 'Block') {
    throw Error('Next SubFlow should be a block')
  }

  quiz.nextBlockI = nextBlockI

  return selectedBlockSubFlow.questions?.[position.questionI] || quiz.activeQuestion
}

// you are in a sub flow and are moving along to the next question within the sub flow array
// this is the same function as the base case except it is moving along questions in a sub flow not in a block
const isInSubFlow = (quiz: QuizData) => {
  const {flow, position} = quiz

  const selectedBranchFlow = flow[position.flowI]
  if (selectedBranchFlow.type !== 'Branch') {
    throw Error()
  }
  const selectedBlockSubFlow = selectedBranchFlow.flow[position.subFlowI]
  if (selectedBlockSubFlow.type !== 'Block') {
    throw Error()
  }
  return selectedBlockSubFlow.questions[position.questionI + 1]
}
const handleInSubFlow = (quiz: QuizData) => {
  const {flow, position} = quiz

  const selectedBranchFlow = flow[position.flowI]
  if (selectedBranchFlow.type !== 'Branch') {
    throw Error()
  }
  const selectedBlockSubFlow = selectedBranchFlow.flow[position.subFlowI]
  if (selectedBlockSubFlow.type !== 'Block') {
    throw Error()
  }
  return selectedBlockSubFlow.questions?.[++position.questionI] || quiz.activeQuestion
}

// you have reached the end of a sub flow and are moving off the current branch along the main flow to the next block
const isEndOfSubFlow = (quiz: QuizData) => {
  const {flow, position} = quiz

  const selectedBranchFlow = flow[position.flowI]
  if (selectedBranchFlow.type !== 'Branch') {
    throw Error()
  }
  const selectedBlockSubFlow = selectedBranchFlow.flow[position.subFlowI]
  if (selectedBlockSubFlow.type !== 'Block') {
    throw Error()
  }
  if (quiz.nextBlockI === undefined) {
    throw Error('nextBlockI should have been set earlier')
  }
  return (
    !selectedBlockSubFlow.questions[position.questionI + 1] &&
    !selectedBranchFlow.flow[position.subFlowI + 1] &&
    flow?.[quiz.nextBlockI].type === 'Block'
  )
}
const handleEndOfSubFlow = (quiz: QuizData) => {
  const {flow, position} = quiz
  if (quiz.nextBlockI === undefined) {
    throw Error('nextBlockI should have been set earlier')
  }
  position.flowI = quiz.nextBlockI
  position.subFlowI = 0
  position.questionI = 0

  const selectedBlockFlow = flow[position.flowI]
  if (selectedBlockFlow.type !== 'Block') {
    throw Error('The flow should be a Block type')
  }

  return selectedBlockFlow.questions[position.questionI] || quiz.activeQuestion
}

// the next item in the main flow is the end of the survey
const isEndOfQuiz = (quiz: QuizData) => {
  const {flow, position} = quiz
  const {flowI} = position

  return flow[flowI + 1].type === 'EndSurvey'
}
const handleEndOfQuiz = (quiz: QuizData) => {
  const {flow, position} = quiz

  const selectedBlockFlow = flow[++position.flowI]
  if (selectedBlockFlow.type !== 'Block') {
    throw Error('The flow should be a Block type')
  }

  return selectedBlockFlow.questions[position.questionI] || quiz.activeQuestion
}

export const setNextQuestion = (quiz: QuizData) => {
  const {flow, position} = quiz

  let activeQuestion: Question | undefined
  if (isBaseCase(quiz)) {
    activeQuestion = handleBaseCase(quiz) as Question
  } else if (isEndOfBlock(quiz)) {
    activeQuestion = handleEndOfBlock(quiz) as Question
  } else if (isInSubFlow(quiz)) {
    activeQuestion = handleInSubFlow(quiz) as Question
  } else if (isEndOfSubFlow(quiz)) {
    activeQuestion = handleEndOfSubFlow(quiz) as Question
  } else if (isEndOfQuiz(quiz)) {
    activeQuestion = handleEndOfQuiz(quiz) as Question
  }

  if (!activeQuestion) {
    throw Error('No questions in the flow')
  }

  // after we have advanced the quiz check to see if we are one away from the end to update the "next" button to a "submit" one
  if (flow[position.flowI + 1].type === 'EndSurvey') {
    activeQuestion.submitOnComplete = true
  }

  quiz.navHistory.push({
    ...quiz.position,
  })

  quiz.activeQuestion = cloneDeep(activeQuestion)
}
