import {DateTime} from 'luxon'

/**
 * Return the last element of a given array, or `undefined`, if empty array is provided.
 */
export const last = <T>(array: T[]) => array[array.length - 1]

/**
 * Return a given string with a slash prepended if the string does not have one already.
 */
export const ensureLeadingSlash = (path: string) => (path.startsWith('/') ? path : `/${path}`)

export const ensureAbsolutePath = ensureLeadingSlash

export type Comparator<T> = (a: T, b: T) => -1 | 0 | 1

/**
 * Based on a given discriminator function, return a comparator function that can be
 * used directly in the builtin `Array.sort()`.
 *
 * A discriminator function must return a comparable value for any given input.
 *
 * How to use:
 * ```
 * const secondElement = <A, B>(tuple: [A, B]): B => tuple[1]
 *
 * const pairs: [string, number][] = [['Alice', 25], ['Bob', 22], ['Charlie', 24]]
 *
 * console.log(pairs.sort(compareWith(secondElement)))
 *
 * >>> [['Bob', 22], ['Charlie', 24], ['Alice', 25]]
 * ```
 *
 * @param discriminator Function that returns a comparable value when given a compared object.
 */
export const compareWith =
  <T, R>(discriminator: (a: T) => R): Comparator<T> =>
  (a: T, b: T) => {
    if (discriminator(a) === discriminator(b)) {
      return 0
    } else {
      return discriminator(a) < discriminator(b) ? -1 : 1
    }
  }

/**
 * Add a schema to a url with an ambiguous schema (`//`) (default is `https`).
 *
 * ```
 * ensureSchema('//cdn.invitae.com/i/123') === 'https://cdn.invitae.com/i/123'
 * ```
 */
export const ensureSchema = (url: string, schema = 'https') => {
  if (url) return url.startsWith('//') ? `${schema}:${url}` : url
  return ''
}

/**
 * Construct a URL query string from a given object.
 */
export const queryStringFrom = (params: Record<string, string>) =>
  Object.entries(params)
    .map(parts => parts.map(encodeURIComponent).join('='))
    .join('&')

/**
 * Add a query parameter to a given URL to make Contentful return the image as a JPG.
 */
export const ensureCorrectPreviewImageUrl = (url: string) => `${url}?${queryStringFrom({fm: 'jpg'})}`

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.valueOf())
}

export const compareDates = (a: string, b: string, method?: 'reverse') => {
  const aDate = new Date(a)
  const bDate = new Date(b)
  if (!isValidDate(aDate)) {
    console.error(`An error occurred while comparing dates. Invalid date provided: ${a}`)
    // return 0 and don't compare the dates
    return 0
  }
  if (!isValidDate(bDate)) {
    console.error(`An error occurred while comparing dates. Invalid date provided: ${b}`)
    // return 0 and don't compare the dates
    return 0
  }
  if (method === 'reverse') return Number(bDate) - Number(aDate)
  return Number(aDate) - Number(bDate)
}

export const convertUTCtoUSDateFormat = (iso: string) => DateTime.fromISO(iso).toFormat('MM/dd/yyyy')

export const removeEmpty = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v))
}

export const flattenObject = (obj: Record<string, any>) => {
  const flattened: Record<string, unknown> = {}

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}
// String.endsWith analog with IE support
export const endsWith = (str: string, suffix: string): boolean => {
  return str.indexOf(suffix, str.length - suffix.length) !== -1
}

export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

export interface ReplaceHtmlProps {
  findTerm: string
  replaceTo: (textFound: string) => string
  componentRef?: React.MutableRefObject<HTMLElement>
  text?: string
}

export const replaceHtml = ({findTerm, replaceTo, componentRef, text: originalText = ''}: ReplaceHtmlProps) => {
  const regex = new RegExp(findTerm.replace(' ', '|'), 'ig')
  const text = componentRef ? componentRef.current.innerHTML : originalText

  const newHTML = text.replace(regex, replaceTo)

  if (componentRef) {
    componentRef.current.innerHTML = newHTML
  }

  return newHTML
}

export const formatComma = (genes: string) => {
  return genes.replace(/(\s,\w|\w,\w|\s,\s)/g, patternFound => {
    return patternFound.trim().replaceAll(',', ', ')
  })
}

/**
 * This function will add/remove a classname based on a time interval
 * in other words, this function is a loop, which runs from time to time
 * `intervalTimeMilliseconds` is the time in milliseconds that represents the time interval to remove/add classname to the elements
 * `timeDelayToAddClassInMilliseconds` is the time that function will wait until it add classname to the next element
 */
export const addsClassToAnElementWithInterval = ({
  elements,
  classname,
  intervalTimeMilliseconds = 3000,
  timeDelayToAddClassInMilliseconds = 500,
}: {
  elements: HTMLElement[]
  classname: string
  intervalTimeMilliseconds?: number
  timeDelayToAddClassInMilliseconds?: number
}) => {
  // start element index with 0
  let currentElementIndex = 0

  const elementInterval = setInterval(() => {
    const currentElement = elements[currentElementIndex]

    // in the case we didn't have an element, the interval will be cancelled
    if (!currentElement) {
      clearInterval(elementInterval)
    }

    // remove class from current element
    currentElement.classList.remove(classname)
    // increase the index to add class on next element
    currentElementIndex++

    const nextElement = elements[currentElementIndex]

    // if nextElement was not found, reset element index to make a loop
    if (!nextElement) {
      currentElementIndex = 0
    }

    // add class to nextElement with 300ms delay, to give time to animation do his job
    setTimeout(() => {
      elements[currentElementIndex].classList.add(classname)
    }, timeDelayToAddClassInMilliseconds)
  }, intervalTimeMilliseconds)
}
