import React from 'react'
import {kebabCase} from 'lodash'

import {IPublicationFields} from '@invitae/contentful-types'
import {NvHeading, NvPagination, NvSpinner} from '@invitae/ids-react'
import {SearchBar, useTextCopy} from '@invitae/nucleobase'

import {useEducationCenter} from 'components/EducationCenter/EducationCenter.hooks'
import {TextCopyType} from 'constants/textCopies'

import ScientificPublicationsItem from './ScientificPublicationItem'

import styles from './ScientificPublicationsCollection.module.scss'

export interface IScientificPublicationsProps {
  tabName: string
}

const ScientificPublications = ({tabName}: IScientificPublicationsProps) => {
  const {getText} = useTextCopy<TextCopyType<'papersAndPresentationsSection'>>()

  const scientificPublicationsTabTitle = getText('scientificPublicationsTabTitle')

  const {
    entries,
    error,
    isLoading,
    handleSearchFilter,
    totalResultsCount,
    specialtyAreaFilterConfig,
    yearFilterConfig,
    searchValue,
    tab,
  } = useEducationCenter<IPublicationFields>({
    defaultTabId: kebabCase(scientificPublicationsTabTitle),
    modelName: 'publication',
  })

  return (
    <>
      <SearchBar
        defaultValue={tabName === tab ? searchValue : ''}
        filters={[specialtyAreaFilterConfig, yearFilterConfig]}
        onSearch={handleSearchFilter}
        searchResultsCount={totalResultsCount}
      />
      <NvSpinner isLoading={isLoading} />
      {!isLoading && error && (
        <NvHeading className={styles.errorMessage} element="h3" level={3}>
          {error}
        </NvHeading>
      )}
      {!isLoading &&
        !error &&
        entries?.map((scientificPublicationItem, index) => (
          <ScientificPublicationsItem
            isFirstItemInList={index === 0}
            key={index}
            publication={scientificPublicationItem}
          />
        ))}
      {!isLoading && <NvPagination />}
    </>
  )
}

export default ScientificPublications
