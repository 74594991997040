import 'intersection-observer' // polyfill for IE11

import * as React from 'react'

interface UseIsAnchoredProps {
  anchorsTo: 'top' | 'right' | 'bottom' | 'left'
}
export const useIsAnchored = <T extends HTMLElement>({anchorsTo}: UseIsAnchoredProps) => {
  const stickyElementRef = React.useRef<T>(null)
  const [isAnchored, setIsAnchored] = React.useState(false)

  React.useEffect(() => {
    if (!stickyElementRef.current) return

    const stickyElement = stickyElementRef.current

    const observer = new IntersectionObserver(
      ([observed]) => {
        if (window.getComputedStyle(stickyElement).position !== 'sticky') return

        if (observed.intersectionRatio < 1) setIsAnchored(true)
        else if (observed.intersectionRatio >= 1) setIsAnchored(false)
      },
      {
        threshold: [0, 1],
      },
    )

    observer.observe(stickyElement)
    return () => {
      observer.unobserve(stickyElement)
    }
  }, [])

  const style = {
    [anchorsTo]: '-1px',
  }

  return {
    isAnchored,
    stickyElementRef,
    style,
  }
}
