import {kebabCase} from 'lodash'

const specialtyAreas = [
  'Cardiology',
  'Digital Health',
  'Exome',
  'General genetics',
  'Genetic counseling',
  'Immunology',
  'Metabolics & newborn screening',
  'Neurology',
  'Oncology',
  'Pediatrics and rare disease',
  'Proactive',
  'Research',
  'Sponsored testing',
  'Technology',
  'Variant interpretation',
  'Women’s health',
  'Other',
]

export const specialties = specialtyAreas.map(specialty => ({label: specialty, value: kebabCase(specialty)}))

export default specialtyAreas
