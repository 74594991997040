import * as React from 'react'
import cx from 'classnames'

import {NvHeading, NvMarkdown, NvSubheading} from '@invitae/ids-react'
import {Grid, useCountryLocationPrefix, useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import LeftBlob from './blobs/LeftBlob'
import RightBlob from './blobs/RightBlob'
import RadialProgressBar from './RadialProgressBar/RadialProgressBar'

import styles from './SurvivalRateSection.module.scss'

export const CATEGORY_HREF = 'providers/test-catalog/hereditary-cancer?Category=Frequently+ordered+tests'

const SurvivalRateSection = () => {
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const {getText} = useTextCopy<TextCopyType<'survivalRateSection'>>()

  return (
    <Grid className={styles.survivalRateSection} withoutContainer>
      <div className={styles.headingsContainer}>
        <NvHeading data-testid="survival-rate-section-heading" element="h2" level={1}>
          <NvMarkdown>{getText('title')}</NvMarkdown>
        </NvHeading>
        <NvSubheading data-testid="survival-rate-section-subheading">
          <NvMarkdown>{getText('description')}</NvMarkdown>
        </NvSubheading>
      </div>
      <div className={styles.barsContainer}>
        <RadialProgressBar label={getText('atDiagnosis')} theme="spring" value={78} />
        <RadialProgressBar label={getText('beforeDiagnosis')} theme="teal" value={94} />
      </div>
      <a className={cx('nv-button', styles.button)} href={addCountryCodePrefix(CATEGORY_HREF)}>
        {getText('buttonText')}
      </a>
      <LeftBlob className={styles.leftBlob} />
      <RightBlob className={styles.rightBlob} />
    </Grid>
  )
}

export default SurvivalRateSection
