import * as React from 'react'

import {useAnalyticsQueue, usePageAnalyticsContext} from '@invitae/nucleobase'

enum CareerSearchEvents {
  GoToApplication = 'Go to job application on greenhouse',
}

export const useAnalytics = () => {
  const {previousPageUrl} = usePageAnalyticsContext<string>()
  const {logEvent} = useAnalyticsQueue()

  const trackViewApplicationButton = React.useCallback(
    (jobTitle, url) => {
      logEvent({
        eventName: CareerSearchEvents.GoToApplication,
        eventProperties: {
          jobTitle,
          previousPage: previousPageUrl,
          url,
        },
      })
    },
    [logEvent, previousPageUrl],
  )

  return {
    trackViewApplicationButton,
  }
}
