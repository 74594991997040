import * as React from 'react'
import classNames from 'classnames'

import {NvHeading, NvSubheading} from '@invitae/ids-react'
import {useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import styles from './GeneticCounselorSection.module.scss'

const GeneticCounselorSection = () => {
  const {getText} = useTextCopy<TextCopyType<'geneticCounselorSection'>>()

  return (
    <div className={styles.root}>
      <div className={classNames('nv-container', 'nv-grid', styles.termGrid)}>
        <h2 className={styles.term}>
          {(getText('title') as string)
            .split('\n')
            .filter(text => text.trim())
            .map(text => (
              <span key={text}>{text.trim()}</span>
            ))}
        </h2>

        <div className={styles.termDefinition}>
          <NvSubheading level="level1book">{getText('description')}</NvSubheading>
        </div>

        <NvHeading className={styles.termSubHeading} element="h3" level={2}>
          {getText('cardsTitle')}
        </NvHeading>

        <div className={styles.contactOption1}>
          <NvHeading element="h4" level={4}>
            {getText('cardTitle1')}
          </NvHeading>
          <p>{getText('cardDescription1')}</p>
        </div>

        <div className={styles.contactOption2}>
          <NvHeading element="h4" level={4}>
            {getText('cardTitle2')}
          </NvHeading>
          <p>{getText('cardDescription2')}</p>
        </div>
      </div>
    </div>
  )
}

export default GeneticCounselorSection
