import * as React from 'react'

import choiceTypes from 'components/Quiz/choiceTypes'
import {EmailOnFocusContext} from 'components/Quiz/contexts/EmailOnFocusContext'
import {Choice as IChoice, Question, QuestionTypes} from 'components/Quiz/Quiz.types'

import MultiOptionBlock from './MultiOptionBlock/MultiOptionBlock'

interface ChoicesBlockProps {
  setValue: (value: string | number, key: string) => void
  question?: Question
  emailOnFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
}

const ChoicesBlock = ({question, setValue, emailOnFocus}: ChoicesBlockProps) => {
  if (!question) return null

  const {questionType, questionId} = question

  // some types of questions from qualtrics are multi part and each "option" is really its own question
  // other types each question needs to take over the whole block
  const singleOptionBlock = questionType === QuestionTypes.MCDL

  if (!questionType || !choiceTypes[questionType]) {
    console.error('unknown question block')
  }

  const Choice: React.FC<IChoice> = choiceTypes[questionType || QuestionTypes.MCTE]

  return (
    <EmailOnFocusContext.Provider value={emailOnFocus ? emailOnFocus : null}>
      {singleOptionBlock ? (
        <Choice index="1" key={questionId} question={question} setValue={setValue} />
      ) : (
        <MultiOptionBlock question={question} setValue={setValue} />
      )}
    </EmailOnFocusContext.Provider>
  )
}

export default ChoicesBlock
