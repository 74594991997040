/* eslint-disable spellcheck/spell-checker */
import * as React from 'react'

const IconClock = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero" transform="translate(-525 -481)">
          <g transform="translate(525 244)">
            <g transform="translate(0 173)">
              <g transform="translate(0 64)">
                <g>
                  <path d="M10 0C4.476 0 0 4.476 0 10s4.476 10 10 10 10-4.476 10-10S15.524 0 10 0zm8.71 10c0 4.786-3.875 8.71-8.71 8.71-4.786 0-8.71-3.875-8.71-8.71 0-4.786 3.875-8.71 8.71-8.71 4.786 0 8.71 3.875 8.71 8.71zm-6.004 3.56L9.43 11.181a.487.487 0 01-.197-.39V4.354c0-.266.218-.484.484-.484h.564c.266 0 .484.218.484.484v5.899l2.843 2.069c.218.157.262.46.105.677l-.33.456a.487.487 0 01-.678.104z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconClock
/* eslint-enable spellcheck/spell-checker */
