import * as React from 'react'

import {NvHeading} from '@invitae/ids-react'

import {Department, Job} from 'types/greenhouse'

import {useAnalytics} from './useAnalytics.hooks'
import {shortenLocationName} from './useCareersUtils'

import styles from './JobsByDepartment.module.scss'

interface JobsByDepartmentProps {
  departments: Department[]
}

const groupJobCodesByTitle = (jobs: Job[]) =>
  jobs.reduce<Record<string, Job[]>>((acc, value) => {
    if (!acc[value.title]) {
      acc[value.title] = [] // Group initialization
    }

    // Grouping
    acc[value.title].push(value)

    return acc
  }, {})

const JobsByDepartment = ({departments}: JobsByDepartmentProps) => {
  const {trackViewApplicationButton} = useAnalytics()

  return (
    <>
      {departments.map(department => (
        <div data-testid="department-section" key={department.id}>
          <NvHeading className={styles.heading} element="h3" level={3}>
            {department.name}
          </NvHeading>

          {Object.keys(groupJobCodesByTitle(department.jobs)).map(key => (
            <div data-testid="job-code" key={key}>
              <span className={styles.jobTitle}>{key}</span> -{' '}
              {groupJobCodesByTitle(department.jobs)[key].map(location => (
                <a
                  className={styles.jobDetailLink}
                  href={location.absolute_url}
                  key={location.id}
                  onClick={() => {
                    trackViewApplicationButton(location.title, location.absolute_url)
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="View application on Greenhouse"
                >
                  {shortenLocationName(location.location.name)}
                </a>
              ))}
            </div>
          ))}

          <hr className={styles.lineSeparator} />
        </div>
      ))}
    </>
  )
}

export default JobsByDepartment
