import React from 'react'

import {IWebinarFields} from '@invitae/contentful-types'
import {NvHeading, NvPagination, NvSpinner} from '@invitae/ids-react'
import {SearchBar} from '@invitae/nucleobase'

import {useEducationCenter} from 'components/EducationCenter/EducationCenter.hooks'
import {WebinarTabIds} from 'types/TabTypes'

import WebinarSectionItem from './WebinarSectionItem/WebinarSectionItem'

import styles from './WebinarSectionItemsCollection.module.scss'

export interface WebinarSectionItemsCollectionProps {
  shouldShowTime: boolean
  buttonText: string
  tabName: string
}

const WebinarSectionItemsCollection = ({shouldShowTime, buttonText, tabName}: WebinarSectionItemsCollectionProps) => {
  const {
    error,
    isLoading,
    entries,
    totalResultsCount,
    handleSearchFilter,
    tab,
    searchValue,
    yearFilterConfig,
    specialtyAreaFilterConfig,
  } = useEducationCenter<IWebinarFields>({defaultTabId: WebinarTabIds.Upcoming, modelName: 'webinar'})

  return (
    <>
      <SearchBar
        defaultValue={tabName === tab ? searchValue : ''}
        filters={[specialtyAreaFilterConfig, ...(tab === WebinarTabIds.Recent ? [yearFilterConfig] : [])]}
        onSearch={handleSearchFilter}
        searchResultsCount={totalResultsCount}
      />
      <NvSpinner isLoading={isLoading} />
      {!isLoading && error && (
        <NvHeading className={styles.errorMessage} element="h3" level={3}>
          {error}
        </NvHeading>
      )}
      {!isLoading &&
        entries?.map((webinar, index) => (
          <WebinarSectionItem
            buttonText={buttonText}
            isFirstItemInList={index === 0}
            key={webinar.title + index}
            shouldShowTime={shouldShowTime}
            webinar={webinar}
          />
        ))}
      {!isLoading && <NvPagination />}
    </>
  )
}

export default WebinarSectionItemsCollection
