import * as React from 'react'
import classnames from 'classnames'

import {NvHeading} from '@invitae/ids-react'
import {useCountryLocationPrefix, useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import {useHomeJourneysFooterTracking} from './HomeJourneysFooterSection.hooks'

import styles from './HomeJourneysFooterSection.module.scss'

const HomeJourneysFooterSection = () => {
  const {trackGetTestClick, trackNavigateToProvider} = useHomeJourneysFooterTracking()
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const {getText} = useTextCopy<TextCopyType<'homeJourneysFooterSection'>>({
    providerSide: (
      <a
        data-cy="button_journeyCards-providerSite"
        href={addCountryCodePrefix('providers')}
        key="provider site"
        onClick={trackNavigateToProvider}
      >
        provider site
      </a>
    ),
  } as any)

  return (
    <div className={styles.homeJourneysFooterSection} data-cy="journeyCardsSection">
      <NvHeading className={styles.healthcareProviderText} element="h2" level={4}>
        {getText('description')}
      </NvHeading>
      <a
        className={classnames('nv-button', styles.footerButton)}
        data-cy="button_journeyCards-GetATest"
        href={addCountryCodePrefix('testing-options')}
        onClick={trackGetTestClick}
      >
        {getText('buttonText')}
      </a>
    </div>
  )
}

export default HomeJourneysFooterSection
