import * as React from 'react'

import {FlagValueDictionary} from '@invitae/nucleobase'
import {NotFoundPage} from '@invitae/ssr-core'

type Custom404Props = {
  featureFlags?: FlagValueDictionary
}

const Custom404 = ({featureFlags}: Custom404Props) => <NotFoundPage featureFlags={featureFlags} />

export default Custom404
