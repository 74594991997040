import * as React from 'react'
import classNames from 'classnames'

import {NavBar} from './NavBar'
import {Pagination} from './Pagination'

import styles from './QuizHeader.module.scss'

export interface HeaderProps {
  nav: {
    step?: number
    totalSteps?: number
    changeQuestion: (direction: boolean) => void
  }
  heading?: string | null
  subHeading?: Array<string | null>
  className?: string
}

const Header = ({heading, subHeading, nav, className}: HeaderProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      {nav && <NavBar changeQuestion={nav.changeQuestion} step={nav.step} />}
      <div className={styles.copyContainer}>
        {!!heading && <h1 className={styles.heading}>{heading}</h1>}
        {subHeading?.filter(Boolean)?.map(subEl => (
          <p className={styles.subHeading} key={subEl as string}>
            {subEl}
          </p>
        ))}
      </div>
      <div className={styles.blob} />
      {nav.step !== undefined && nav.totalSteps !== undefined && (
        <Pagination step={nav.step} totalSteps={nav.totalSteps} />
      )}
    </div>
  )
}

export default Header
