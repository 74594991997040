import * as React from 'react'

import {NucleobaseConfigContext, useWindowDimensions} from '@invitae/nucleobase'

import {QuizData} from 'components/Quiz/Quiz.types'
import {getQualtricsAPI, postQualtricsAPI} from 'components/Quiz/utils/api'
import {parseQuestionTextVariables} from 'components/Quiz/utils/parseQuestionTextVariables'
import {setNextQuestion} from 'components/Quiz/utils/setNextQuestion'
import {setPreviousQuestion} from 'components/Quiz/utils/setPreviousQuestion'
import {isQuestionValid, parseQualtricsQuiz} from 'components/Quiz/utils/utils'

export function useQuiz(qualtricsSurveyId: string) {
  const {qualtricsApiToken, qualtricsHostName} = React.useContext(NucleobaseConfigContext) || {}
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [quiz, setQuiz] = React.useState<QuizData>()
  const [questionId, setQuestionId] = React.useState<string | undefined>()
  const [submissionResult, setSubmissionResult] = React.useState<QuizData>()
  const {isSmall} = useWindowDimensions()
  const isNextButtonEnabled = quiz?.activeQuestion ? isQuestionValid(quiz?.activeQuestion).isValid : false

  // entry point to quiz fetches the quiz data from the api
  const getQuiz = React.useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await getQualtricsAPI({qualtricsApiToken, qualtricsHostName, qualtricsSurveyId})
      // reshape the API JSON to be better to work with
      const quiz = parseQualtricsQuiz(response)

      // look at quiz data and determine the current question and update state
      setNextQuestion(quiz)
      setQuestionId(quiz.activeQuestion?.questionId)
      setQuiz(quiz)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [qualtricsApiToken, qualtricsHostName, qualtricsSurveyId])

  const setAnswerValidity = (isValid: boolean) => {
    return setQuiz(prevQuiz => {
      const cloneQuiz = {...prevQuiz!}
      cloneQuiz.activeQuestion!.validation.isValid = isValid
      return cloneQuiz
    })
  }

  const submitQuiz = React.useCallback(async () => {
    if (!quiz) return

    try {
      if (quiz.activeQuestion === undefined) {
        throw Error('activeQuestion not yet set')
      }
      const {isValid} = isQuestionValid(quiz.activeQuestion)
      if (!isValid) {
        return setAnswerValidity(isValid)
      }

      setIsLoading(true)

      const response = await postQualtricsAPI({
        answers: quiz.answers,
        qualtricsApiToken,
        qualtricsHostName,
        qualtricsSurveyId,
      })

      setSubmissionResult(response.result)
      return response
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [quiz, qualtricsApiToken, qualtricsHostName, qualtricsSurveyId])

  // called when the user tries to advance to the next question
  const changeQuestion = React.useCallback<(forward?: boolean) => void>(
    (forward = true) => {
      if (!quiz) return
      if (quiz.activeQuestion === undefined) {
        throw Error('activeQuestion not yet set')
      }
      const {isValid} = isQuestionValid(quiz.activeQuestion)

      if (!forward) {
        // return to the previous question
        setPreviousQuestion(quiz)
      } else if (!isValid) {
        // if we want to go forward but a question has an invalid answer
        return setAnswerValidity(isValid)
      } else {
        // otherwise head to the next question
        setNextQuestion(quiz)
      }

      if (quiz.activeQuestion.hasVariable) {
        parseQuestionTextVariables(quiz, isSmall)
      }

      setQuiz(quiz)
      setQuestionId(quiz.activeQuestion.questionId)
      window.scrollTo(0, 0)
    },
    [quiz, isSmall],
  )

  // called when a user selects or enters an answer to a quiz
  const answerQuestion = React.useCallback(
    // the answer is the value the key is the key expected by the qualtrics api when we submit answers
    (answer: string | number, key: string) => {
      setQuiz(prevQuiz => {
        if (!prevQuiz || !prevQuiz.activeQuestion?.answers) return prevQuiz

        return {
          ...prevQuiz,
          activeQuestion: {
            ...prevQuiz?.activeQuestion,
            answers: {
              ...prevQuiz?.activeQuestion?.answers,
              [key]: answer,
            },
          },
          answers: {
            ...prevQuiz?.answers,
            [key]: answer,
          },
        }
      })
    },
    [],
  )

  // Call on initial render to request quiz
  React.useEffect(() => {
    const startQuiz = async () => {
      await getQuiz()
    }

    startQuiz()
  }, [qualtricsSurveyId, getQuiz])

  return {
    answerQuestion,
    changeQuestion,
    isLoading,
    isNextButtonEnabled,
    questionId,
    quiz,
    submissionResult,
    submitQuiz,
  }
}
