import * as React from 'react'
import {useRouter} from 'next/router'

import {useAnalyticsQueue, usePageHistory} from '@invitae/nucleobase'

const useHomeAnnouncementQuizClick = () => {
  const router = useRouter()
  const {lastPage} = usePageHistory()
  const {logEvent} = useAnalyticsQueue({debug: true})
  const currentPage: string = router.asPath

  const trackQuizClick = React.useCallback(
    (path: string) => {
      logEvent({
        eventName: 'Click to start quiz from homepage',
        eventProperties: {
          'Current page': currentPage,
          'Link destination': path,
          'Previous page': lastPage || '',
          Section: 'Home - consider genetic testing for the first time?',
        },
      })
      return true
    },
    [currentPage, logEvent, lastPage],
  )

  return {trackQuizClick}
}

export {useHomeAnnouncementQuizClick}
