import * as React from 'react'
import classnames from 'classnames'

import {NvHeading, NvMarkdown} from '@invitae/ids-react'
import {CaretDown, useCountryLocationPrefix, useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import {useHomeFAQAnalytics} from './HomeFAQSection.hooks'

import styles from './HomeFAQSection.module.scss'

const HomeFAQ = () => {
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null)
  const [nextSelectedIndex, setNextSelectedIndex] = React.useState<number | null>(null)
  const [isTabEvent, setIsTabEvent] = React.useState<boolean>(false)
  const {getText, isKeywordEmpty} = useTextCopy<TextCopyType<'homeFAQSection'>>()

  const CONTENT = React.useMemo(
    () =>
      new Array(10)
        .fill(null)
        .map((_, index) => {
          return !isKeywordEmpty(`questionTitle${index + 1}`)
            ? {
                id: index,
                text: getText(`questionText${index + 1}`),
                title: getText(`questionTitle${index + 1}`),
              }
            : null
        })
        .filter(question => !!question),
    [getText, isKeywordEmpty],
  )

  const selectedContent = selectedIndex !== null ? CONTENT[selectedIndex] : null

  const {trackExpandFAQ, trackGetTestClick, trackSeeAllFAQs} = useHomeFAQAnalytics()
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  React.useEffect(() => {
    if (nextSelectedIndex !== null) {
      setSelectedIndex(nextSelectedIndex)
      setNextSelectedIndex(null)
    }
  }, [nextSelectedIndex])

  const toggleSelection = (indexParam: number) => {
    const isSelected = selectedContent && indexParam === selectedContent.id
    const newIndex = isSelected ? null : indexParam
    const aDrawerIsAlreadyOpen = newIndex !== null
    if (aDrawerIsAlreadyOpen) {
      setSelectedIndex(null)
      setNextSelectedIndex(newIndex)
    } else {
      setSelectedIndex(newIndex)
    }
  }

  const switchSelectedIndex = (indexParam: number, e?: React.KeyboardEvent) => {
    trackExpandFAQ(CONTENT[indexParam]?.title, indexParam === selectedIndex)
    // Toogle for Enter key and for non-keyboard events
    if ((e && e.key === 'Enter') || !e) {
      toggleSelection(indexParam)
    }

    // Enable focus outline for keyboard events and hide for non-keyboard events
    setIsTabEvent(!!e)
  }

  const footerSection = !isKeywordEmpty('secondaryTitle') || !isKeywordEmpty('selectTestButton')

  return (
    <section className={styles.homeFaq} data-cy="faq">
      <div className={classnames('animation-fade-up', 'nv-container', styles.homeFaqWrapper)}>
        <NvHeading className={styles.sectionHeading} element="h2" level={2}>
          {getText('title')}
        </NvHeading>
        <hr />
        <ul className={styles.contentWrapper}>
          {CONTENT.map((content, contentIndex) => {
            const isSelected = selectedContent && content?.id === selectedContent.id
            const caretText = isSelected ? getText('hideText') : getText('showUpText')
            const caretTitle = caretText.replace('{{questionTitle}}', content?.title)

            return (
              <li key={content?.id}>
                <div
                  aria-label={content?.title}
                  className={classnames('nv-subheading-1', styles.faqRow, {[styles.noOutlineOnFocus]: !isTabEvent})}
                  onClick={() => switchSelectedIndex(content?.id as number)}
                  onKeyDown={e => switchSelectedIndex(content?.id as number, e)}
                  role="button"
                  tabIndex={0}
                >
                  <div className={classnames(styles.caret, {[styles.caretUp]: isSelected})}>
                    <CaretDown title={caretTitle} />
                  </div>
                  <div className={styles.titleAndText}>
                    <div className={styles.title}>{content?.title}</div>
                    <NvMarkdown
                      className={classnames(styles.text, {[styles.showText]: isSelected})}
                      data-testid={`question-content-${contentIndex}`}
                    >
                      {content?.text}
                    </NvMarkdown>
                  </div>
                </div>
                <hr />
              </li>
            )
          })}
        </ul>
        {!isKeywordEmpty('seeAllButtonPath') && (
          <a
            className={classnames('nv-subheading-1', styles.seeAllFaqs)}
            data-cy="button_faq-allFAQs"
            href={addCountryCodePrefix(getText('seeAllButtonPath'))}
            onClick={trackSeeAllFAQs}
          >
            {getText('seeAllButton')}
          </a>
        )}
      </div>
      {footerSection && (
        <div className={styles.sectionFooter}>
          {!isKeywordEmpty('secondaryTitle') && (
            <NvHeading className={styles.subheading} element="h2" level={2}>
              {getText('secondaryTitle')}
            </NvHeading>
          )}
          {!isKeywordEmpty('selectTestButton') && (
            <a
              className={classnames('nv-button', 'nv-button-white')}
              data-cy="button_faq-selectYourTest"
              href={addCountryCodePrefix('testing-options')}
              onClick={trackGetTestClick}
            >
              {getText('selectTestButton')}
            </a>
          )}
        </div>
      )}
    </section>
  )
}

export default HomeFAQ
