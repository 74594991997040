import {Department, Job} from 'types/greenhouse'

export const detectWorkDaySchema = (jobs: Job[]) =>
  jobs.some(job => getWorkLocationMetadata(job) && getJobFamilyMetadata(job))

export const getWorkLocationMetadata = (job: Job) => findMetadataValue(job, 'Work Location')

export const getJobTitle = (job: Job) => job.title

export const getJobFamilyMetadata = (job: Job) => findMetadataValue(job, 'Job Family Group') || ''

export const getLegacyLocation = (job: Job) => job.location.name

export const extractLocations = (jobs: Job[]): string[] => {
  const locations = new Set<string>()

  jobs.forEach(job => {
    const workLocation = getWorkLocationMetadata(job)

    if (workLocation) {
      locations.add(workLocation)
    }
  })

  return Array.from(locations)
}

export const groupByField = (jobs: Job[], field: (job: Job) => string) =>
  jobs.reduce<Record<string, Job[]>>((accumulator, job) => {
    const jobFamilyGroup = field(job)

    if (!accumulator[jobFamilyGroup]) {
      accumulator[jobFamilyGroup] = []
    }

    accumulator[jobFamilyGroup].push(job)

    return accumulator
  }, {})

export const findMetadataValue = (job: Job, metadataName: string) =>
  job.metadata?.find(metadata => metadata.name === metadataName)?.value

export const filterDepartmentsWithJobs = (departments: Department[]): Department[] =>
  departments.filter((department: Department) => department.jobs.length)

export const extractLocationsFromJobs = (departments: Department[]): string[] => {
  const locations = new Set<string>()
  departments.forEach(department => {
    department.jobs.forEach(job => {
      locations.add(job.location.name)
    })
  })
  return Array.from(locations)
}

export const filterJobsByLocation = (jobs: Job[], location: string): Job[] =>
  jobs.filter(job => getWorkLocationMetadata(job) === location)

export const filterJobsByLocationLegacy = (jobs: Job[], location: string): Job[] =>
  jobs.filter(job => job.location.name === location)

export const filterJobsBySearchTerm = (jobs: Job[], searchTerm: string): Job[] =>
  jobs.filter(job => job.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))

export const filterDepartmentsByLocationLegacy = (departments: Department[], location: string): Department[] => {
  const filteredDepartments: Department[] = []
  departments.forEach(department => {
    const filteredJobs = filterJobsByLocationLegacy(department.jobs, location)
    if (filteredJobs.length) {
      filteredDepartments.push({
        ...department,
        jobs: filteredJobs,
        name: department.name,
      })
    }
  })

  return filteredDepartments
}

export const filterDepartmentsBySearchTerm = (departments: Department[], searchTerm: string): Department[] => {
  const filteredDepartments: Department[] = []
  departments.forEach(department => {
    const filteredJobs = filterJobsBySearchTerm(department.jobs, searchTerm)
    if (filteredJobs.length) {
      // only return jobs if job title is matched
      filteredDepartments.push({
        ...department,
        jobs: filteredJobs,
      })
    } else if (department.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
      // otherwise returns departments when department name is matched
      filteredDepartments.push(department)
    }
  })
  return filteredDepartments
}

export const trimDepartmentName = (department: string) => department.replace(/[0-9]{4} - /, '') // removes prefixed job code

export const shortenLocationName = (location: string) =>
  location
    .replace(',', '')
    // Removes Country
    .replace(/, United States$/, '')

    // Shortens state name for US
    .replace(/California$/, 'CA')
    .replace(/North Carolina$/, 'NC')
    .replace(/New York$/, 'NY')
    .replace(/Texas$/, 'TX')
    .replace(/Colorado$/, 'CO')
    .replace(/New Jersey$/, 'NJ')
    .replace(/Washington$/, 'WA')
    .replace(/Massachusetts$/, 'MA')
    .replace(/Florida$/, 'FL')
    .replace(/New York$/, 'NY')

export const sortDepartmentsByName = (departments: Department[]) =>
  departments.sort((a, b) => a.name.localeCompare(b.name))
