export default [
  {label: 'Clinical Staff Member', value: 'Clinical Staff Member'},
  {label: 'Genetic Counselor', value: 'Genetic Counselor'},
  {label: 'Lab/Practice Administrator', value: 'Lab/Practice Administrator'},
  {label: 'Midwife', value: 'Midwife'},
  {label: 'Nurse Navigator', value: 'Nurse Navigator'},
  {label: 'Nurse Practitioner', value: 'Nurse Practitioner'},
  {label: 'Other', value: 'Other'},
  {label: 'Physician', value: 'Physician'},
  {label: 'Physician Assistant', value: 'Physician Assistant'},
  {label: 'Registered Nurse', value: 'Registered Nurse'},
  {label: 'Researcher/Professor', value: 'Researcher/Professor'},
  {label: 'Resident/Student', value: 'Resident/Student'},
  {label: 'Surgeon', value: 'Surgeon'},
]
