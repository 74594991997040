import React, {useEffect, useRef} from 'react'
import classNames from 'classnames'

import {NvButton, NvHeading, NvMarkdown} from '@invitae/ids-react'
import {useCountryLocationPrefix, useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'
import {addsClassToAnElementWithInterval} from 'utils/misc'

import styles from './HomeTextAndMedia.module.scss'

const HomeTextAndMedia = () => {
  const {getText} = useTextCopy<TextCopyType<'homeTextAndMedia'>>()
  const {addCountryCodePrefix} = useCountryLocationPrefix()
  const ref = useRef<HTMLDivElement>(null)
  const buttonUrl = addCountryCodePrefix(getText('buttonLink'))
  const blobImage = getText('blobImageUrl')

  const images = new Array(10)
    .fill(null)
    .map((_, index) => {
      const imageUrl = getText(`imageUrl${index + 1}`)

      return imageUrl !== `{{imageUrl${index + 1}}}`
        ? {
            alt: getText(`imageAlt${index + 1}`) || '',
            src: imageUrl,
          }
        : null
    })
    .filter(image => !!image)

  useEffect(() => {
    if (!ref?.current) return

    addsClassToAnElementWithInterval({
      classname: styles.show,
      elements: ref?.current?.children as any,
    })
  }, [])

  return (
    <div className={classNames('nv-grid nv-container', styles.root)}>
      <div className={classNames(styles.textContent, styles.text)}>
        <NvHeading className={styles.heading} element="h2" level={2}>
          {getText('heading')}
        </NvHeading>

        <NvMarkdown className={styles.body}>{getText('body')}</NvMarkdown>

        <div className={styles.actions}>
          <NvButton element="a" href={buttonUrl} variant="secondary">
            {getText('buttonLabel')}
          </NvButton>
        </div>
      </div>

      <div
        className={classNames(styles.mediaContent)}
        data-testid="media"
        ref={ref}
        style={{backgroundImage: `url(${blobImage})`}}
      >
        {images?.map((image, imageIndex) => (
          <img
            alt={image?.alt}
            className={imageIndex === 0 ? styles.show : ''}
            data-testid={`image-item-${imageIndex + 1}`}
            key={image?.alt}
            src={image?.src}
          />
        ))}
      </div>
    </div>
  )
}

export default HomeTextAndMedia
