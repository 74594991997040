import * as React from 'react'

import {Choice} from 'components/Quiz/Quiz.types'

import {DropDownList} from './DropDownList/DropDownList'
import {MultipleChoice} from './MultipleChoice/MultipleChoice'
import {MultipleChoiceTextEntry} from './MultipleChoiceTextEntry'
import {SalesforceCountryOfOrigin} from './SalesforceCountryOfOrigin/SalesforceCountryOfOrigin'
import {TextEntry} from './TextEntry/TextEntry'

interface ChoiceMap {
  [key: string]: React.FC<Choice>
}

const choiceMap: ChoiceMap = {
  MCDL: DropDownList,
  MCSAVR: MultipleChoice,
  MCTE: MultipleChoiceTextEntry,
  SFCO: SalesforceCountryOfOrigin,
  TEFORM: TextEntry,
}

export default choiceMap
