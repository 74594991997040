import React, {ReactNode} from 'react'
import classnames from 'classnames'

import {NvPaginationContextProvider, useNvPagination} from '@invitae/ids-react'

import styles from './EducationCenter.module.scss'

interface EducationCenterProps {
  children: ReactNode
}

const EducationCenter = ({children}: EducationCenterProps) => {
  const paginationConfig = useNvPagination()

  return (
    <NvPaginationContextProvider value={paginationConfig}>
      <div className={classnames('nv-container', styles.root)}>{children}</div>
    </NvPaginationContextProvider>
  )
}

export default EducationCenter
