import React from 'react'
import classNames from 'classnames'

import {NvButton, NvHeading, NvInput, NvMarkdown, NvSelect} from '@invitae/ids-react'
import {useTextCopy} from '@invitae/nucleobase'

import clientRoles from 'constants/clientRoles'
import countries from 'constants/countries'
import medicalSpecialties from 'constants/medicalSpecialties'
import {TextCopyType} from 'constants/textCopies'

import styles from './SalesforceWebToLead.module.scss'

const SalesforceWebToLead = () => {
  const {getText} = useTextCopy<TextCopyType<'salesforceWebToLead'>>()

  // utilizing a textCopy to give publishers a way to switch the background color between gray and white
  // not the best, but since SalesforceWebToLead isn’t a full CMS Block yet, it’s all we got to work with
  const theme = getText('theme') === 'gray' ? 'gray' : 'white'
  const className = classNames(styles.root, styles[theme])

  return (
    <div className={className}>
      <div className="nv-container">
        <NvHeading className={styles.heading} element="h4" level={1}>
          {getText('heading')}
        </NvHeading>

        <NvMarkdown className={classNames('nv-subheading-1-book', styles.body)}>{getText('body')}</NvMarkdown>

        <form action={process.env.SALESFORCE_WEB_TO_LEAD_URL} method="post">
          <input name="oid" type="hidden" value={process.env.SALESFORCE_WEB_TO_LEAD_OID} />
          <input name="retURL" type="hidden" value="https://www.invitae.com/thank-you" />

          {/* 
Debugging information. Uncomment these two lines to test in debug mode. You will receive an email.
<input name="debug" type="hidden" value="1" />
<input name="debugEmail" type="hidden" value="first.last@invitae.com" />
*/}

          <div className="nv-grid">
            <div className={styles.left}>
              <NvInput id="first_name" label="First name*" maxLength={40} name="first_name" required />
            </div>
            <div className={styles.right}>
              <NvInput id="last_name" label="Last name*" maxLength={40} name="last_name" required />
            </div>
          </div>

          <div className="nv-grid">
            <div className={styles.left}>
              <NvInput id="email" label="Email address*" maxLength={40} name="email" required type="email" />
            </div>
            <div className={styles.right}>
              <NvInput id="company" label="Organization name*" maxLength={40} name="company" required />
            </div>
          </div>

          <div className="nv-grid">
            <div className={styles.left}>
              <NvSelect label="Country*" name="country_code" options={countries} />
            </div>
            <div className={styles.right}>
              <NvInput id="zip" label="Zip/postal code*" maxLength={20} name="zip" required />
            </div>
          </div>

          <div className="nv-grid">
            <div className={styles.left}>
              <NvSelect label="Role*" name="00N50000003AjMC" options={clientRoles} />
            </div>

            <div className={styles.right}>
              <NvSelect label="Specialty*" name="00N2J000007TGfH" options={medicalSpecialties} />
            </div>
          </div>

          <div className={styles.actions}>
            <NvButton type="submit">Submit</NvButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SalesforceWebToLead
