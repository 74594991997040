import React from 'react'
import clsx from 'classnames'

import {useTextCopy} from '@invitae/cms-blocks'
import {IPageFields, IWebinarFields} from '@invitae/contentful-types'
import {NvLink} from '@invitae/ids-react'
import {useCountryLocationPrefix} from '@invitae/nucleobase'

import {TextCopyType} from '../../../constants/textCopies'
import WebinarSectionItem from '../WebinarSectionItemsCollection/WebinarSectionItem/WebinarSectionItem'
import WebinarPageNavigation from './WebinarPageNavigation/WebinarPageNavigation'

import styles from './WebinarPage.module.scss'

const WebinarPage = ({originalFields}: {originalFields: Pick<IPageFields, 'sections'>}) => {
  const [{fields}] = originalFields.sections
  const {getText} = useTextCopy<TextCopyType<'webinarSection'>>()
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  return (
    <div className={clsx('nv-container', styles.container)}>
      <NvLink
        className={styles.goBack}
        href={addCountryCodePrefix('/providers/educational-webinars?tab=recent&currentPage=1&numPerPage=10')}
        variant="plain"
      >
        {getText('goBack')}
      </NvLink>
      <WebinarSectionItem
        buttonText={getText('recentTabButton')}
        isFirstItemInList
        isInStandalonePage
        shouldShowTime
        webinar={fields as unknown as IWebinarFields}
      />
      <WebinarPageNavigation />
    </div>
  )
}

export default WebinarPage
