import {useCallback, useEffect, useRef, useState} from 'react'
import {useRouter} from 'next/router'

import {useAnalyticsQueue} from '@invitae/analytics-queue'
import {NvPaginationState, useNvPagination} from '@invitae/ids-react'
import {useEffectAsync, useQueryObject} from '@invitae/react-hooks'

import marcomFetch from 'utils/marcomFetch/marcomFetch'
import {scrollToTop} from 'utils/misc'

export const NUM_PER_PAGE = 20
const SECONDS_UNTIL_ABORT_REQUEST = 20

interface IQueryObject {
  q: string
  page: string
}

export const useSearchResults = () => {
  const [currentMessageType, setCurrentMessageType] = useState<'initial' | 'noResults' | 'error'>('initial')
  const router = useRouter()

  const onRoutePush = (path: string) => router.replace(path, undefined, {shallow: true})

  const {setQueryObject, queryObject} = useQueryObject<IQueryObject>(router.asPath, onRoutePush)

  const {logEvent} = useAnalyticsQueue()

  const paginationInit = useNvPagination()
  const {pagination, setPagination, resetPagination, getPaginationText} = paginationInit

  const firstRender = useRef(true)

  const handleSearch = useCallback(
    (value: string) => {
      if (firstRender.current) return

      if (!value) setCurrentMessageType('initial')

      resetPagination()
      setQueryObject(prev => ({...prev, q: value}))
    },
    [setQueryObject, resetPagination],
  )

  const getItems = useCallback(
    async ({page = 1, keyword}) => {
      if (!keyword) return []

      logEvent({
        eventName: 'Search page - on search event',
        eventProperties: {
          keyword,
          page,
        },
      })

      const searchParams = new URLSearchParams({
        area: 'all',
        page,
        query: keyword,
      })

      const requestURL = `${process.env.SEARCH_API}?${searchParams.toString()}`

      const abortSignal = AbortSignal as unknown as {
        timeout: (time: number) => AbortSignal
      }

      const request = await marcomFetch(requestURL, {
        method: 'GET',
        signal: abortSignal.timeout(SECONDS_UNTIL_ABORT_REQUEST * 1000),
      })

      const {page: currentPage, totalResults, results} = await request.json()

      setPagination(() => {
        const paginationValues: Omit<NvPaginationState, 'text'> = {
          currentPage,
          currentPageInput: `${page}`,
          numPerPage: NUM_PER_PAGE,
          numPerPageInput: `${NUM_PER_PAGE}`,
          totalEntries: totalResults,
        }

        return {
          ...paginationValues,
          text: getPaginationText(paginationValues),
        }
      })

      if (!results.length) {
        setCurrentMessageType('noResults')
      }

      return results
    },
    [setPagination, logEvent],
  )

  const fetchGetItems = useCallback(
    () =>
      getItems({
        keyword: queryObject.q,
        page: queryObject.page,
      }),
    [getItems, queryObject],
  )

  const {data: collection, isLoading, error: fetchError, fetchData} = useEffectAsync(fetchGetItems, [fetchGetItems], [])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false

      return
    }

    scrollToTop()

    // just show pagination on the URL if is greater than 1
    setQueryObject(prev => ({
      ...prev,
      page: pagination.currentPage > 1 ? `${pagination.currentPage}` : '',
    }))
  }, [pagination.currentPage, setQueryObject])

  useEffect(() => {
    if (fetchError) {
      setCurrentMessageType('error')
    }
  }, [fetchError])

  return {
    collection,
    currentMessageType,
    fetchData,
    handleSearch,
    isLoading,
    pagination,
    paginationInit,
    queryObject,
  }
}

export default useSearchResults
