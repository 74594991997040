import * as React from 'react'
import classnames from 'classnames'

import {useIsAnchored} from './Submit.hooks'

import styles from './Submit.module.scss'

interface SubmitProps {
  onClick: (forward?: boolean) => void
  text: string
  disabled: boolean
}

const Submit = ({onClick, text, disabled}: SubmitProps) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      onClick()
    },
    [onClick],
  )

  const {isAnchored, stickyElementRef, style} = useIsAnchored<HTMLDivElement>({anchorsTo: 'bottom'})

  return (
    <div
      className={classnames(styles.buttonWrapper, {
        [styles['buttonWrapper--anchored']]: isAnchored,
      })}
      ref={stickyElementRef}
      style={style}
    >
      <button
        className={classnames('nv-button', styles.button, {
          [styles.disabled]: disabled,
        })}
        data-cy={`quiz_${text}-button`}
        onClick={handleClick}
      >
        {text}
      </button>
    </div>
  )
}

export default Submit
