import * as React from 'react'
import classnames from 'classnames'

import choiceTypes from 'components/Quiz/choiceTypes'
import {Choice as IChoice, Question, QuestionTypes} from 'components/Quiz/Quiz.types'

import styles from './MultiOptionBlock.module.scss'

interface IMultiOptionsBlockProps {
  setValue: (value: string | number, key: string) => void
  question: Question
}

const MultiOptionsBlock: React.FC<IMultiOptionsBlockProps> = ({question, setValue}) => {
  const {choices, questionType, questionId, validation} = question
  const questionTypeStyle = {
    MCDL: undefined,
    MCSAVR: styles.multipleOptionsSingleAnswer,
    MCTE: undefined,
    SFCO: undefined,
    TEFORM: styles.textEntryForm,
  }[question.questionType]

  return (
    <>
      <div className={classnames(questionTypeStyle)} data-cy="quiz_choices">
        {Object.keys(choices || {}).map(c => {
          let Choice: React.FC<IChoice>

          if (choices?.[c].description === QuestionTypes.SFCO || choices?.[c].description === QuestionTypes.MCTE) {
            // some choiceTypes override the question type
            Choice = choiceTypes[choices?.[c].description || QuestionTypes.MCTE]
          } else {
            Choice = choiceTypes[questionType || QuestionTypes.MCTE]
          }

          return <Choice index={c} key={`${questionId}_${c}`} question={question} setValue={setValue} />
        })}
      </div>
      {validation.isValid === false && questionType === QuestionTypes.MCSAVR ? (
        <div className={styles.errorText} data-cy="quiz_choices-errorText">
          Please select an option
        </div>
      ) : null}
    </>
  )
}

export default MultiOptionsBlock
