import {PAP_MATRIX} from './data'
import {FinancialCalculatorResultTextCopy, InsuranceType} from './enums'

type CalculateResultTextCopyProps = {
  insuranceType: InsuranceType
  income: number
  householdMembers: number | string
}

export const calculateResultTextCopy = ({insuranceType, income, householdMembers}: CalculateResultTextCopyProps) => {
  let resultTextCopies: FinancialCalculatorResultTextCopy[] = []
  const MAX_SUPPORTED_HOUSEHOLD_MEMBERS = PAP_MATRIX.length - 1 // exclude last object ('> 8')
  const SUPPORTED_HOUSEHOLD_MEMBERS = Array.from({length: MAX_SUPPORTED_HOUSEHOLD_MEMBERS}, (_, i) => i + 1) // [1, ..., 8]

  SUPPORTED_HOUSEHOLD_MEMBERS.map((_householdMembers, i) => {
    if (_householdMembers === householdMembers) {
      switch (insuranceType) {
        case InsuranceType.INSURED: {
          // has insurance and income is lower than the threshold for $0 price
          if (income <= (PAP_MATRIX[i].threshold_0 as number)) {
            resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_1]
            return
          }
          // has insurance and income is larger than the threshold for $0 price and lower than $100 price
          if (income > (PAP_MATRIX[i].threshold_0 as number) && income <= (PAP_MATRIX[i].threshold_100 as number)) {
            resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_2]
            return
          }
          // has insurance and income is larger than the threshold for $100 price and lower than $250 price
          if (income > (PAP_MATRIX[i].threshold_100 as number) && income <= (PAP_MATRIX[i].threshold_250 as number)) {
            resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_3]
            return
          }
          // has insurance but income is too high
          if (income > (PAP_MATRIX[i].threshold_250 as number)) {
            resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_5]
            return
          }
          break
        }

        case InsuranceType.NON_INSURED: {
          // does not have insurance and income is lower than the threshold for $0 price
          if (income <= (PAP_MATRIX[i].threshold_0 as number)) {
            resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_6]
            return
          }
          // does not have insurance and income is larger than the threshold for $0 price and lower than $100 price
          if (income > (PAP_MATRIX[i].threshold_0 as number) && income <= (PAP_MATRIX[i].threshold_100 as number)) {
            resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_7]
            return
          }
          // does not have insurance and income is larger than the threshold for $100 price and lower than $250 price
          if (income > (PAP_MATRIX[i].threshold_100 as number) && income <= (PAP_MATRIX[i].threshold_250 as number)) {
            resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_8]
            return
          }
          // does not have insurance and income is too high
          if (income > (PAP_MATRIX[i].threshold_250 as number)) {
            resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_9]
            return
          }
          break
        }
      }
    }
  })

  if (insuranceType === InsuranceType.GOVERNMENT_PAYOR) {
    resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_4]
    return resultTextCopies
  }

  if (householdMembers === '> 8') {
    resultTextCopies = [FinancialCalculatorResultTextCopy.ESTIMATE_10]
  }

  return resultTextCopies
}
