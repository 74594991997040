export const contactInfo = {
  headquartersAndLaboratoryPhone: '+1 (415) 930-4018',
  intTestingInquiries: {
    operatingHours: 'M-F 4:00 am to 6:00 pm Pacific',
    phone: '+1 (415) 930-4018',
  },
  localPhoneNumbers: [
    {
      country: 'Argentina',
      id: 1,
      phone: '11-5032-0180',
    },
    {
      country: 'Australia',
      id: 2,
      phone: '1800-961-370',
    },
    {
      country: 'Brazil',
      id: 3,
      phone: '11-3181-4924',
    },
    {
      country: 'Canada',
      id: 4,
      phone: '1-888-332-6039',
    },
    {
      country: 'Chile',
      id: 5,
      phone: '2-2599-4386',
    },
    {
      country: 'Colombia',
      id: 6,
      phone: '1-486-4863',
    },
    {
      country: 'Israel',
      id: 7,
      phone: '180-947-0122',
    },
    {
      country: 'Mexico',
      id: 8,
      phone: '554-777-2190',
    },
    {
      country: 'Peru',
      id: 9,
      phone: '1-705-9827',
    },
    {
      country: 'South Africa',
      id: 10,
      phone: '080-021-2370',
    },
    {
      country: 'Uruguay',
      id: 11,
      phone: '000-4019-0910',
    },
  ],
  USTestingInquiries: {
    fax: '(415) 276-4164',
    operatingHours: 'M-F 5:00 am to 5:00 pm Pacific',
    phone: '(800) 436-3037',
  },
}
