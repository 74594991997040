import {useEffect, useState} from 'react'
import {flagNames} from 'flagNames'

import {FlagValueDictionary, getFeatureFlags} from '@invitae/nucleobase'

export const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState<FlagValueDictionary | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchFlags = async () => {
      const featureFlagsData = await getFeatureFlags({
        appName: 'marcom',
        flagNames,
        projectName: 'customer-experience',
      })
      if (featureFlagsData) {
        setFeatureFlags(featureFlagsData?.data || null)
      }
      setIsLoading(false)
    }

    fetchFlags()
  }, [])

  return {featureFlags, isLoading}
}
