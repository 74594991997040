enum InsuranceType {
  INSURED = 'insured',
  NON_INSURED = 'nonInsured',
  GOVERNMENT_PAYOR = 'governmentPayor',
}

enum FinancialCalculatorResultTextCopy {
  ESTIMATE_1 = 'estimate1',
  ESTIMATE_2 = 'estimate2',
  ESTIMATE_3 = 'estimate3',
  ESTIMATE_4 = 'estimate4',
  ESTIMATE_5 = 'estimate5',
  ESTIMATE_6 = 'estimate6',
  ESTIMATE_7 = 'estimate7',
  ESTIMATE_8 = 'estimate8',
  ESTIMATE_9 = 'estimate9',
  ESTIMATE_10 = 'estimate10',
}

enum FinancialCalculatorMiscTextCopy {
  ABOUT_HEADING = 'aboutHeading',
  ABOUT_BODY = 'aboutBody',
  HOUSEHOLD_MEMBERS_LABEL = 'householdMembersLabel',
  INSURANCE_TYPE_LABEL = 'insuranceTypeLabel',
  ANNUAL_PRETAX_HOUSEHOLD_INCOME_LABEL = 'annualPretaxHouseholdIncomeLabel',
  HEADING = 'heading',
  DISCLAIMER = 'disclaimer',
  CALCULATE = 'calculate',
}

enum FinancialCalculatorErrorTextCopy {
  HOUSEHOLD_MEMBERS_FIELD_ERROR = 'householdMembersFieldError',
  INSURANCE_TYPE_FIELD_ERROR = 'insuranceTypeFieldError',
  ANNUAL_PRETAX_HOUSEHOLD_INCOME_FIELD_ERROR = 'annualPretaxHouseholdIncomeFieldError',
}

export {
  InsuranceType,
  FinancialCalculatorMiscTextCopy,
  FinancialCalculatorResultTextCopy,
  FinancialCalculatorErrorTextCopy,
}
