import * as React from 'react'
import classnames from 'classnames'

import {IPublicationFields} from '@invitae/contentful-types'
import {NvHeading, NvMarkdown} from '@invitae/ids-react'
import {BaseMarcomComponent} from '@invitae/nucleobase'

import styles from './ScientificPublicationsCollection.module.scss'

export interface IPublicationItemProps {
  publication: IPublicationFields
  isFirstItemInList: boolean
}

const ScientificPublicationsItem = ({
  publication: {title, citation, link},
  isFirstItemInList,
}: IPublicationItemProps) => (
  <div className={classnames(styles.publicationItem, {[styles.borderTop]: !isFirstItemInList})}>
    <BaseMarcomComponent classes={{root: classnames(styles.itemLink)}} source={link?.fields?.linkScheme}>
      <NvHeading className={styles.title} element="h4" level={4}>
        {title}
      </NvHeading>
      <NvMarkdown className={styles.citation}>{citation}</NvMarkdown>
    </BaseMarcomComponent>
  </div>
)

export default ScientificPublicationsItem
