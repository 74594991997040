import * as React from 'react'
import classnames from 'classnames'

import {NvSelect} from '@invitae/ids-react'

import {Choice} from 'components/Quiz/Quiz.types'

import styles from './DropDownList.module.scss'

interface DropDownListProps extends Choice {
  className?: string
  isInvalid?: boolean
  label?: string
}

type Option = {
  label: string
  value: string
}

export const DropDownList = ({
  className,
  setValue,
  question,
  isInvalid,
  label = 'Select option',
}: DropDownListProps) => {
  const {choices, questionId} = question || {}

  const handleClick = React.useCallback(
    selectedOption => {
      setValue(selectedOption.value, questionId || 'qID')
    },
    [setValue, questionId],
  )

  const options: Option[] = React.useMemo(() => {
    const options = Object.keys(choices).map(choiceKey => {
      const choice = choices[choiceKey]
      return {label: choice.choiceText, value: choice.variableName ?? choice.recode}
    })

    if (!question.validation.doesForceResponse) {
      options.unshift({label: 'Select...', value: ''})
    }

    return options as Option[]
  }, [choices, question.validation.doesForceResponse])

  return (
    <div className={classnames(className, styles.dropdownContainer)}>
      <div
        className={classnames(styles.dropdown, {[styles.error]: isInvalid})}
        data-cy="quiz_lastStep-countryWrapperSelect"
      >
        <NvSelect<Option> hideLabel label={label} name={questionId} onChange={handleClick} options={options} />
      </div>
    </div>
  )
}
