import * as React from 'react'
import {FormEventHandler} from 'react'

import {ISectionQuizFields} from '@invitae/contentful-types'
import {NvSpinner} from '@invitae/ids-react'
import {NucleobaseComponent, useAnalyticsQueue, useWindowDimensions} from '@invitae/nucleobase'

import {INITIAL_RENDER_HEADER_TEXT} from './constants/config'
import Container from './Container/Container'
import {useQuiz} from './hooks/useQuiz.hooks'
import {useQuizAnalytics} from './hooks/useQuizAnalytics'
import Body from './Question/Body'
import ChoiceBlock from './Question/ChoiceBlock'
import {questionToResults} from './Question/Results/ResultsEmbeddedData'
import ResultsPage from './Question/Results/ResultsPage'
import Submit from './Question/Submit/Submit'

const Quiz: NucleobaseComponent<ISectionQuizFields> = ({id, fields: {surveyId}}) => {
  const {
    isLoading,
    quiz,
    questionId,
    changeQuestion,
    answerQuestion,
    submitQuiz,
    submissionResult,
    isNextButtonEnabled,
  } = useQuiz(surveyId)

  React.useEffect(() => {
    if (submissionResult) window.scrollTo(0, 0)
  }, [submissionResult])

  const {logEvent} = useAnalyticsQueue()

  const {trackedAnswerQuestion, trackedChangeQuestion, trackedOnExit, trackedSubmit, trackEmailFieldOnFocus} =
    useQuizAnalytics({
      answerQuestion,
      changeQuestion,
      logEvent,
      quiz,
      submitQuiz,
      surveyId,
    })

  const {activeQuestion} = quiz || {}
  const {tags, step, totalSteps, submitOnComplete} = activeQuestion || {}
  const {isSmall, isExtraSmall} = useWindowDimensions()

  function findSurveyResult() {
    if (!quiz) throw new Error('Quiz should been initialized')
    const answers = quiz.answers
    if (!answers) throw new Error('Quiz should been initialized')
    const answerKey = Object.keys(answers).find(answer => answer in questionToResults)
    if (answerKey === undefined) {
      throw new Error(`${Object.keys(answers)} don't contain any known answer ${Object.keys(questionToResults)}`)
    }
    const answerValue = String(answers[answerKey])
    const question = questionToResults[answerKey]
    return question[answerValue] as JSX.Element
  }

  const heading: string | undefined | null = isLoading && !activeQuestion ? INITIAL_RENDER_HEADER_TEXT : tags?.h1?.[0]
  const subHeading: Array<string | null> = tags?.h2 || []
  const nav = {changeQuestion: trackedChangeQuestion, onExit: trackedOnExit, step, totalSteps: totalSteps}

  if (submissionResult) {
    const result = findSurveyResult()
    return <ResultsPage nav={nav}>{result}</ResultsPage>
  } else {
    const isCopyOnBody = isSmall || isExtraSmall
    // optionally prepends body paragraphs with subheading paragraphs, depending on viewport width
    const bodyParagraphs = [...(isCopyOnBody ? subHeading : []), ...(tags ? tags.p : [])].filter(
      e => !!e,
    ) as Array<string>

    const handleSubmitClick = submitOnComplete ? trackedSubmit : trackedChangeQuestion

    const formHandleSubmit: FormEventHandler<HTMLFormElement> = ev => {
      ev.preventDefault()
      handleSubmitClick(true)
    }
    return (
      <>
        <Container heading={heading} nav={nav} subHeading={isCopyOnBody ? undefined : subHeading}>
          <form data-active-question={questionId} id={id} onSubmit={formHandleSubmit}>
            <Body body={bodyParagraphs} />

            <ChoiceBlock
              emailOnFocus={trackEmailFieldOnFocus}
              question={activeQuestion}
              setValue={trackedAnswerQuestion}
            />

            <NvSpinner isLoading={isLoading} />
          </form>
        </Container>

        {!isLoading && (
          <Submit
            disabled={!isNextButtonEnabled}
            onClick={value => handleSubmitClick(!!value)}
            text={submitOnComplete ? 'Submit' : 'Next'}
          />
        )}
      </>
    )
  }
}

export default Quiz
