import {mapValues} from 'lodash'

import {FLOW_TYPES, TAGS} from 'components/Quiz/constants/qualtrics'
import {emailRegex, htmlRegex} from 'components/Quiz/constants/regex'
import {
  EnhancedElement,
  EnhancedFlowData,
  FlowData,
  IBlock,
  IGetSurvey,
  IQuestion,
  Question,
  QuestionTypes,
  QuizData,
} from 'components/Quiz/Quiz.types'

const parseQuestion = (question: IQuestion) => {
  // the HTML sent from qualtrics contains information the quiz
  const questionTextEl = document.createElement('html')
  questionTextEl.innerHTML = question.questionText

  // we can style the front end based on what html tag the copy is contained in
  TAGS.forEach((tag: string) => {
    const elements = questionTextEl.getElementsByTagName(tag)
    if (!question.tags) question.tags = {}
    question.tags[tag] = [...elements].map(element => element?.textContent).filter(Boolean)
  })

  // the div containing the qualtrics question is tagged with information for the header pagination
  const mainDiv = questionTextEl.getElementsByTagName('div')[0]
  if (mainDiv) {
    const dataset = questionTextEl.getElementsByTagName('div')[0].dataset
    const {totalSteps, step, variableNames, variableChoiceGroupValues} = dataset

    if (totalSteps && step) {
      question.totalSteps = parseInt(totalSteps)
      question.step = parseInt(step)
    }

    if (variableNames) {
      const variableNameList: string[] = []
      question.hasVariable = true
      question.variableMaxLengths = {}

      variableNames.split(',').forEach(variable => {
        variableNameList.push(variable)

        const smallCap: string | undefined = dataset[`maxLengthMedium|${variable.toLowerCase()}`]
        const mediumCap: string | undefined = dataset[`maxLengthSmall|${variable.toLowerCase()}`]

        if (question.variableMaxLengths && typeof smallCap === 'string' && typeof mediumCap === 'string') {
          question.variableMaxLengths[variable] = {
            medium: parseInt(mediumCap),
            small: parseInt(smallCap),
          }
        }
      })

      question.variableNames = variableNameList
    }

    if (variableChoiceGroupValues) {
      question.variableChoiceGroupValues = variableChoiceGroupValues.split('|')
    }
  }

  question.choices = mapValues(question.choices, choice => {
    question.validation.isValid = true
    if (choice.description.includes('country-of-origin')) {
      // country of origin is a special type of text ]entry
      // to save work on the salesforce team they requested we make country a text entry not a drop down
      return {...choice, choiceText: choice.choiceText.replace(htmlRegex, ''), description: QuestionTypes.SFCO}
    }
    if (choice.description.includes('email-validation')) {
      // NOTE: qualtrics is not sending validation this is going to be hacked into the description
      question.validation.email = true
      return {...choice, choiceText: choice.choiceText.replace(htmlRegex, ''), description: 'email'}
    }
    if (choice.description.includes(QuestionTypes.MCTE)) {
      // NOTE: qualtrics does not say that a multiple choice option might actually be a text entry form and not a button...
      // we add it to the HTML sent on the qualtrics side so we know here that this despite being marked as MC the choice is actually a text form
      return {...choice, choiceText: choice.choiceText.replace(htmlRegex, ''), description: QuestionTypes.MCTE}
    }
    return {...choice}
  })

  return question
}

// Formats qualtrics quiz shape by pulling questions out of blocks and formatting them for our components
function parseBlock(block: IBlock, questions: IGetSurvey['result']['questions']): Array<EnhancedElement> {
  const formattedQuestions = block.elements
    .filter(element => element.type === 'Question') // we only care about question data sent by the API so remove everything that is not needed for the front end
    .map(element => {
      // the api sends alot of data in strange places including some in data elements in the question HTML we refactor it here to be more useable by the front end
      const {questionId} = element

      const {
        validation,
        questionText,
        choices,
        questionName,
        questionType,
        tags,
        totalSteps,
        step,
        hasVariable,
        variableNames,
        variableChoiceGroupValues,
        variableMaxLengths,
      } = parseQuestion(questions[questionId])

      return {
        answers: {},
        choices,
        hasVariable,
        headerText: questionText,
        questionId,
        questionName,
        questionText,
        questionType: `${questionType.type}${questionType.selector}` as EnhancedElement['questionType'],
        step,
        tags,
        totalSteps,
        validation,
        variableChoiceGroupValues,
        variableMaxLengths,
        variableNames,
      }
    })

  return formattedQuestions
}

// flattens qualtrics logic into an easier to traverse shape by pulling questions out of blocks and adding them to the flow
// adds an empty object that is later used to track the currently selected question
// adds position object that is used to track where the user is in the quiz
export const parseQualtricsQuiz = (apiResponse: IGetSurvey) => {
  if (!apiResponse || !apiResponse.result) {
    throw Error('No API Response')
  }

  const {flow, blocks, questions} = apiResponse.result

  function processFlow(flow: FlowData[]): EnhancedFlowData[] {
    const enhancedFlow = flow
      .filter(flowDatum => [FLOW_TYPES.BLOCK, FLOW_TYPES.BRANCH, FLOW_TYPES.END_SURVEY].includes(flowDatum.type))
      .map(flowDatum => {
        // three main type of flows

        // a simple list of questions one after the next
        if (flowDatum.type === 'Block') {
          return {...flowDatum, questions: parseBlock(blocks[flowDatum.id], questions)}
        }

        // a branch in the logic represented by a nested flow
        // every logic branch becomes its own flow
        if (flowDatum.type === 'Branch') {
          return {...flowDatum, flow: processFlow(flowDatum.flow)}
        }

        if (flowDatum.type === 'EndSurvey') {
          return {...flowDatum}
        }

        throw Error(`Unexpected Type ${flowDatum.type}`)
      })
    return enhancedFlow
  }
  const quizFlow = processFlow(flow)

  const quiz: QuizData = {
    activeQuestion: undefined, // to track the current question
    answers: {}, // to store formatted answers ready to submit to the API
    flow: quizFlow,
    // position in the quiz
    navHistory: [],
    // the quiz is initialized  before the first question and then advanced to question one ie -1 -> 0
    position: {flowI: 0, questionI: -1, subFlowI: 0},
    questions,
  }

  return quiz
}

interface ValidityData {
  isValid: boolean
  errors: {
    doesForceResponse?: boolean
    email?: boolean
  }
}

// NOTE: move validations to its own file if it grows further
const haveAllQuestionsBeenAnswered = (question: Question) => {
  const {questionType, choices, answers, questionId} = question
  let haveAllQuestionsBeenAnswered = true
  // checks if a multiple choice question has an answer
  // NOTE: some multiple choice questions are actually text entry
  if (
    questionType === QuestionTypes.MCSAVR &&
    !answers?.[questionId!] &&
    questionType === QuestionTypes.MCSAVR &&
    !answers?.[`${questionId}_TEXT`]
  ) {
    haveAllQuestionsBeenAnswered = false
  } else if (questionType === QuestionTypes.TEFORM) {
    // Text entry form can actually be many different text entry inputs
    // this checks all of the choices (each one can be its own input) to see if they have had an answer saved
    Object.keys(choices!).forEach(c => {
      if (!answers?.[`${questionId}_${c}`]) {
        haveAllQuestionsBeenAnswered = false
      }
    })
  }

  return haveAllQuestionsBeenAnswered
}

export const isEmailInvalid = (question: Question) => {
  const {choices, answers, questionId} = question

  for (const c in choices) {
    if (choices[c].description === 'email') {
      return !emailRegex.test(String(answers?.[`${questionId}_${c}`]))
    }
  }

  return false
}

// Checks for qualtrics validity rules
export const isQuestionValid = (question: Question) => {
  const {validation} = question

  const validityData: ValidityData = {errors: {}, isValid: true}

  if (validation?.doesForceResponse && !haveAllQuestionsBeenAnswered(question)) {
    validityData.isValid = false
    validityData.errors.doesForceResponse = true
  }

  if (validation?.email && isEmailInvalid(question)) {
    validityData.isValid = false
    validityData.errors.email = true
  }

  return validityData
}

// Select error message based on description
export const errorMessage = (questionDescription: string) => {
  if (questionDescription === "What's your name?") {
    return 'Please enter your name'
  } else if (questionDescription === 'email') {
    return 'Please enter a valid email address'
  } else if (questionDescription === 'SFCO') {
    return 'This field is required'
  } else {
    return 'Please make a selection'
  }
}
