import * as React from 'react'

import styles from './ContactForm.module.scss'

interface ContactFormSuccessProps {
  isSuccess: boolean
}

const ContactFormSuccess = ({isSuccess}: ContactFormSuccessProps) => {
  if (!isSuccess) return null
  return (
    <div className={styles.successWrapper}>
      <h2 className={styles.successHeader}>We’ve got mail!</h2>
      <p className={styles.successBody}>Thank you for contacting&nbsp;us. Our team will be in touch&nbsp;soon.</p>
    </div>
  )
}

export default ContactFormSuccess
