import * as React from 'react'
import classnames from 'classnames'

import {Choice} from 'components/Quiz/Quiz.types'

import styles from './MultipleChoice.module.scss'

export const MultipleChoice = ({setValue, question, index}: Choice) => {
  const {choices, answers, questionId, validation} = question || {}

  const handleClick = React.useCallback(() => {
    // qualtrics requires answer as an int
    if (String(answers?.[questionId || 'QD1']) === String(index)) {
      setValue('', questionId || 'QD1')
      return
    }
    setValue(parseInt(index || '1'), questionId || 'QD1')
  }, [answers, questionId, index, setValue])

  return (
    <button
      className={classnames(styles.choice, {
        [styles.active]: String(answers?.[questionId || 'QD1']) === String(index),
        [styles.error]: validation.isValid === false,
      })}
      onClick={handleClick}
      type="button"
      value={index}
    >
      {choices?.[index || '1'].choiceText}
    </button>
  )
}
