export enum WebinarTabIds {
  Recent = 'recent',
  Upcoming = 'upcoming',
}

export enum ConferenceTabIds {
  Publication = 'publication',
  PostersAndPresentations = 'postersAndPresentations',
}

export type TabIds = WebinarTabIds | ConferenceTabIds
