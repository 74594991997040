import {ComponentBlockSectionInfo} from '@invitae/nucleobase'

import CareersSearch from 'components/CareersSearch/CareersSearch'
import ContactForm from 'components/ContactForm/ContactForm'
import EmbryoSection from 'components/EmbryoSection/EmbryoSection'
import GeneticCounselorSection from 'components/GeneticCounselorSection/GeneticCounselorSection'
import HomeChosenByMillions from 'components/home/HomeChosenByMillions/HomeChosenByMillions'
import HomeMobileResults from 'components/home/HomeMobileResults/HomeMobileResults'
import HomeAnnouncementSection from 'components/HomeAnnouncementSection/HomeAnnouncementSection'
import HomeFAQSection from 'components/HomeFAQSection/HomeFAQSection'
import HomeJourneysFooterSection from 'components/HomeJourneysFooterSection/HomeJourneysFooterSection'
import SalesforceWebToLead from 'components/SalesforceWebToLead/SalesforceWebToLead'
import WebinarSection from 'components/WebinarSection/WebinarSection'

import FinancialCalculator from './FinancialCalculator/FinancialCalculator'
import {HomeTextAndMedia} from './home/HomeTextAndMedia'
import PapersAndPresentationsSection from './PapersAndPresentationsSection/PapersAndPresentationsSection'
import {SearchResults} from './SearchResults'
import SurvivalRateSection from './SurvivalRateSection/SurvivalRateSection'
import WebinarPage from './WebinarSection/WebinarPage/WebinarPage'

const componentBlockMap: Record<string, ComponentBlockSectionInfo> = {
  careersSearch: {Component: CareersSearch, isSection: true},
  contactForm: {Component: ContactForm, isSection: true},
  embryoSection: {Component: EmbryoSection, isSection: true},
  financialCalculator: {Component: FinancialCalculator, isSection: true},
  geneticCounselorSection: {Component: GeneticCounselorSection, isSection: true},
  homeAnnouncementSection: {Component: HomeAnnouncementSection, isSection: true},
  homeChosenByMillions: {Component: HomeChosenByMillions, isSection: true},
  homeFAQSection: {Component: HomeFAQSection, isSection: true},
  homeJourneysFooterSection: {Component: HomeJourneysFooterSection, isSection: true},
  homeMobileResults: {Component: HomeMobileResults, isSection: true},
  homeTextAndMedia: {Component: HomeTextAndMedia, isSection: true},
  papersAndPresentationsSection: {Component: PapersAndPresentationsSection, isSection: true},
  salesforceWebToLead: {Component: SalesforceWebToLead, isSection: true},
  searchResults: {Component: SearchResults, isSection: true},
  survivalRateSection: {Component: SurvivalRateSection, isSection: true},
  webinarPage: {Component: WebinarPage as any, isSection: true},
  webinarSection: {Component: WebinarSection, isSection: true},
}

export {componentBlockMap}
