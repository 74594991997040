import * as React from 'react'
import classnames from 'classnames'

import {ILink} from '@invitae/contentful-types'
import {NvHeading, NvMarkdown} from '@invitae/ids-react'
import {BaseMarcomComponent} from '@invitae/nucleobase'

import styles from './PostersAndPresentationsCollection.module.scss'

export interface PosterOrPresentationItemProps {
  item: ILink
  subheader: 'Poster' | 'Posters' | 'Presentation' | 'Presentations'
  isFirstItemInList: boolean
}

const PosterOrPresentationItem = (props: PosterOrPresentationItemProps) => {
  const {item, subheader, isFirstItemInList} = props
  return (
    <div className={classnames(styles.postersAndPresentationItem, {[styles.borderTop]: !isFirstItemInList})}>
      {isFirstItemInList && (
        <NvHeading className={styles.posterAndPresentationSubheader} element="h4" level={4}>
          {subheader}
        </NvHeading>
      )}
      <div className={styles.titleAndButtonTopRow}>
        <NvMarkdown>{item.fields?.text}</NvMarkdown>
        <BaseMarcomComponent
          classes={{root: classnames('nv-button', styles.posterAndPresentationButton, styles.sideButton)}}
          source={item?.fields?.linkScheme}
        >
          Download pdf
        </BaseMarcomComponent>
      </div>
    </div>
  )
}

export default PosterOrPresentationItem
