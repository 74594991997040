import * as React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {useField} from 'formik'

import styles from './GoogleCaptcha.module.scss'

interface GoogleCaptchaProps {
  siteKey: string
  name: string
}

const GoogleCaptcha = ({siteKey, name}: GoogleCaptchaProps) => {
  const [field, meta, helpers] = useField(name)
  if (!siteKey) return null
  return (
    <div>
      {/* wrapper around the captcha to add a red outline when validation is triggered*/}
      <div className={meta.touched && meta.error ? styles.captchaWrapperError : ''}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ReCAPTCHA {...field} onChange={() => helpers.setValue(true)} sitekey={siteKey} />
      </div>
      {meta.touched && meta.error && <div className={styles.errorMessage}>{meta.error}</div>}
    </div>
  )
}

export default GoogleCaptcha
