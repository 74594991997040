import * as React from 'react'
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar'

import {NvHeading} from '@invitae/ids-react'

import 'react-circular-progressbar/dist/styles.css'
import styles from './RadialProgressBar.module.scss'

const THEMES = {
  gray4: 'rgb(201, 203, 213)',
  spring: 'rgb(155, 202, 110)',
  teal: 'rgb(25, 172, 159)',
}

type RadialProgressBarProps = {
  theme?: keyof typeof THEMES
  value: number
  label: string
}

const RadialProgressBar = ({label, theme = 'gray4', value}: RadialProgressBarProps) => {
  const circularStyles = buildStyles({
    pathColor: theme ? THEMES[theme] : undefined,
    strokeLinecap: 'butt',
    trailColor: THEMES.gray4,
  })

  return (
    <CircularProgressbarWithChildren styles={circularStyles} value={value}>
      <div className={styles.value} data-testid="radial-progress-bar-value">
        <div className={styles.number}>{value}</div>
        <NvHeading className={styles.percentage} element="h2" level={1}>
          %
        </NvHeading>
      </div>
      <NvHeading className={styles.label} element="h3" level={3}>
        {label}
      </NvHeading>
    </CircularProgressbarWithChildren>
  )
}

export default RadialProgressBar
