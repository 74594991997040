import * as React from 'react'

import styles from './ContactForm.module.scss'

interface ContactFormErrorProps {
  hasError: boolean
}

const ContactFormError = ({hasError}: ContactFormErrorProps) => {
  if (!hasError) return null
  return (
    <div className={styles.errorWrapper}>
      <h2 className={styles.requestErroredHeader}>OOPS...</h2>
      <p className={styles.errorBody}>Something went wrong. Please try&nbsp;again.</p>
    </div>
  )
}

export default ContactFormError
