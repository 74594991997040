import * as React from 'react'

import {useAnalyticsQueue, useCountryLocationPrefix, usePageHistory} from '@invitae/nucleobase'

function useHomeFAQAnalytics() {
  const {lastPage} = usePageHistory()
  const {logEvent} = useAnalyticsQueue()
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const trackExpandFAQ = React.useCallback(
    (title: string, opened: boolean) => {
      logEvent({
        eventName: 'Toggle patient FAQs accordion',
        eventProperties: {
          Action: opened ? 'Close' : 'Open',
          'Previous page': lastPage || '',
          'Question text': title,
          'Site location': `invitae.com${addCountryCodePrefix('/')}`,
        },
      })
    },
    [lastPage, addCountryCodePrefix, logEvent],
  )

  const trackGetTestClick = React.useCallback(() => {
    logEvent({
      eventName: 'Click to view PIT Select a Test page',
      eventProperties: {
        'Page location': 'Bottom of page ”Select your test”',
        'Previous page': lastPage || '',
      },
    })
    return true
  }, [lastPage, logEvent])

  const trackSeeAllFAQs = React.useCallback(() => {
    logEvent({
      eventName: 'View patient FAQs',
      eventProperties: {
        'Previous page': lastPage || '',
        'Site location': `invitae.com${addCountryCodePrefix('/')}`,
      },
    })
    return true
  }, [lastPage, addCountryCodePrefix, logEvent])

  return {
    trackExpandFAQ,
    trackGetTestClick,
    trackSeeAllFAQs,
  }
}

export {useHomeFAQAnalytics}
