import * as React from 'react'

import {useAnalyticsQueue, usePageHistory} from '@invitae/nucleobase'

function useHomeJourneysFooterTracking() {
  const {lastPage} = usePageHistory()
  const {logEvent} = useAnalyticsQueue()

  const trackGetTestClick = React.useCallback(() => {
    logEvent({
      eventName: 'Click to view PIT Select a Test page',
      eventProperties: {
        'Page location': 'Category panel ”Get a test”',
        'Previous page': lastPage || '',
      },
    })
    return true
  }, [logEvent, lastPage])

  const trackNavigateToProvider = React.useCallback(() => {
    logEvent({
      eventName: 'Click providers landing page link',
      eventProperties: {
        'Page location': 'In-page redirect',
        'Previous page': lastPage || '',
        'Site location': window.location.href,
      },
    })
    return true
  }, [logEvent, lastPage])

  return {trackGetTestClick, trackNavigateToProvider}
}

export {useHomeJourneysFooterTracking}
