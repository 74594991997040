import * as React from 'react'

export interface OrganizationsOption {
  label: string
}
interface Organization {
  name: string
}

export function useOrganizations() {
  const [organizations, setOrganizations] = React.useState<OrganizationsOption[]>([])

  const clearOrganizations = () => {
    setOrganizations([])
  }

  const getOrganizations = React.useCallback(async value => {
    const data = await fetch(`${process.env.INVITAE_PRACTICES_AUTOCOMPLETE_API}/?q=${value}`, {
      method: 'GET',
    })
    const options = await data.json()
    const organizations = options.map((org: Organization) => {
      return {
        label: org.name,
        value: org.name,
      }
    })
    setOrganizations(organizations)
    return organizations
  }, [])
  return {
    clearOrganizations,
    getOrganizations,
    organizations,
  }
}
