import * as React from 'react'
import classnames from 'classnames'

import {DropDownList} from 'components/Quiz/choiceTypes/DropDownList/DropDownList'
// We could potentially get the list from https://github.com/fannarsh/country-list as we do for ContactForm,
// but there are slight differences (with diacritics, for instance).
import countries from 'components/Quiz/constants/countries.json'
import {Choice, Question} from 'components/Quiz/Quiz.types'

import styles from './SalesforceCountryOfOrigin.module.scss'

const convertArrayToChoiceObject = (array: Array<string>) =>
  array.reduce((choices, country, i) => ({...choices, [String(i)]: {choiceText: country, variableName: country}}), {})

const SalesforceCountryOfOrigin = ({setValue, question, index}: Choice) => {
  const questionOverride: Question = {...question}

  questionOverride.questionId = `${question?.questionId}_${index}`
  questionOverride.choices = convertArrayToChoiceObject(countries)
  questionOverride.validation.doesForceResponse = true
  const countryIsInvalid =
    !questionOverride.answers[questionOverride.questionId] && !questionOverride.validation.isValid

  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor={questionOverride.questionId}>
        Country of residence
      </label>
      <div className={styles.errorWrapper}>
        <DropDownList
          className={styles.dropDown}
          isInvalid={countryIsInvalid}
          question={questionOverride}
          setValue={setValue}
        />
        {countryIsInvalid && (
          <div className={classnames(styles.errorText)} data-cy="quiz_lastStep-countryErrorTextSelect">
            Please select an option
          </div>
        )}
      </div>
    </div>
  )
}

export {SalesforceCountryOfOrigin}
