import {RequestEntity} from 'types/greenhouse'
import marcomFetch from 'utils/marcomFetch/marcomFetch'

const greenhouseAPI = async (requestEntity: RequestEntity) => {
  const endPoint = `${process.env.INVITAE_GREENHOUSE_BASE_URL}/boards/${process.env.INVITAE_GREENHOUSE_BOARD}/${requestEntity}`

  const response = await marcomFetch(endPoint, {
    method: 'get',
  })

  const data = await response.json()

  return data[requestEntity]
}

export {greenhouseAPI}
