import * as React from 'react'

import Container, {ContainerProps} from '../../Container/Container'

import styles from './ResultsPage.module.scss'

export type ResultsPageProps = Omit<ContainerProps, 'extraClassNames' | 'heading' | 'subHeading' | 'showBottomBlob'> & {
  children: JSX.Element
}

const ResultsPage: React.FC<ResultsPageProps> = ({children, nav}) => {
  return (
    <Container
      extraClassNames={{copy: styles['container-body'], header: styles['container-header']}}
      heading="Our Recommendation"
      nav={{changeQuestion: nav.changeQuestion, step: 1, totalSteps: undefined}}
      showBottomBlob
      subHeading={[]}
    >
      <section className={styles.root}>{children}</section>
    </Container>
  )
}

export default ResultsPage
