import React from 'react'

import {NvButton, NvHeading} from '@invitae/ids-react'

import styles from './SearchResultsMessage.module.scss'

export interface SearchResultsMessageProps {
  retryFn?: () => void
  error?: boolean
  message: string
}

const SearchResultsMessage = ({retryFn, error, message}: SearchResultsMessageProps) => {
  return (
    <div className={styles.wrapper}>
      <NvHeading className={styles.title} element="h4" level={4}>
        {message}
      </NvHeading>

      {error && <NvButton onClick={retryFn}>Retry</NvButton>}
    </div>
  )
}

export default SearchResultsMessage
