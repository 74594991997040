export default [
  {label: 'New Client', value: 'New Client'},
  {label: 'General Inquiry', value: 'General Inquiry'}, // TODO: check if not present in SF
  {label: 'Existing order', value: 'Existing order'},
  {label: 'Billing', value: 'Billing'},
  {
    label: 'Partnerships & Collaborations',
    value: 'Partnerships & Collaborations',
  },
  {label: 'Media inquiries', value: 'Media inquiries'},
  {label: 'Investor Contacts', value: 'Investor Contacts'},
  {label: 'Other', value: 'Other'},
  {label: 'Test registration', value: 'Test registration'},
  {label: 'Get an Invitae test', value: 'Get an Invitae test'},
]
