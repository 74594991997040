import React from 'react'
import {kebabCase} from 'lodash'

import {NvTab} from '@invitae/ids-react'
import {useTextCopy} from '@invitae/nucleobase'

import EducationCenter from 'components/EducationCenter/EducationCenter'
import EducationCenterTabs from 'components/EducationCenter/EducationCenterTabs'
import {TextCopyType} from 'constants/textCopies'

import PostersAndPresentationsCollection from './PostersAndPresentations/PostersAndPresentationsCollection'
import ScientificPublicationsCollection from './ScientificPublications/ScientificPublicationsCollection'

const PapersAndPresentationsSection = () => {
  const {getText} = useTextCopy<TextCopyType<'papersAndPresentationsSection'>>()

  const postersAndPresentationsTabTitle = getText('postersAndPresentationsTabTitle')
  const scientificPublicationsTabTitle = getText('scientificPublicationsTabTitle')

  return (
    <EducationCenter>
      <EducationCenterTabs defaultTabId={kebabCase(scientificPublicationsTabTitle)}>
        <NvTab tabId={kebabCase(scientificPublicationsTabTitle)} titleText={scientificPublicationsTabTitle}>
          <ScientificPublicationsCollection tabName={kebabCase(scientificPublicationsTabTitle)} />
        </NvTab>
        <NvTab tabId={kebabCase(postersAndPresentationsTabTitle)} titleText={postersAndPresentationsTabTitle}>
          <PostersAndPresentationsCollection tabName={kebabCase(postersAndPresentationsTabTitle)} />
        </NvTab>
      </EducationCenterTabs>
    </EducationCenter>
  )
}

export default PapersAndPresentationsSection
