import * as React from 'react'
import classNames from 'classnames'

import Header, {HeaderProps} from '../Question/Header/Header'

import styles from './Container.module.scss'

export interface ContainerProps extends Omit<HeaderProps, 'className'> {
  showBottomBlob?: boolean
  extraClassNames?: Partial<{
    header: HeaderProps['className']
    copy: string
    blob: string
  }>
}

const Container: React.FC<ContainerProps> = ({
  heading,
  subHeading,
  showBottomBlob,
  extraClassNames = {},
  nav,
  children,
}) => {
  return (
    <>
      <Header className={extraClassNames?.header} heading={heading} nav={nav} subHeading={subHeading} />
      <div className={classNames(styles.root, extraClassNames.copy)}>
        {children}
        {showBottomBlob && (
          <img
            alt=""
            className={classNames(styles.blob, extraClassNames.blob)}
            role="presentation"
            src="http://invitae.imgix.net/graphics/blob-eden_2.svg"
          />
        )}
      </div>
    </>
  )
}
export default Container
