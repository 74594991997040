import * as React from 'react'
import debounce from 'lodash/debounce'
import {v4 as uuidv4} from 'uuid'

import {usePageAnalyticsContext} from '@invitae/nucleobase'

import {errorMessage} from 'components/Quiz/utils/utils'
import useFormTimer from 'utils/useFormTimer'

import {QuestionTypes, QuizData} from '../Quiz.types'

type JSONObject = Record<string, any>

export type LogEvent = ({eventName, eventProperties}: {eventName: string; eventProperties: JSONObject}) => void

enum QuizAnalyticsEvents {
  View = 'View quiz page',
  ChangeAnswer = 'Answer quiz question',
  OnEmailFocus = 'Focus quiz email field',
  OnError = 'Error on quiz answer',
  Back = 'Go to previous quiz question',
  Quit = 'Quit quiz',
  Submit = 'Submit quiz',
}

interface UseQuizAnalyticsProps {
  logEvent: LogEvent
  surveyId: string
  quiz?: QuizData
  answerQuestion: (answer: React.ReactText, questionId: string) => void
  changeQuestion: (forward?: boolean | undefined) => void
  submitQuiz: () => Promise<any>
}
export const useQuizAnalytics = ({
  logEvent: originalLogEvent,
  quiz,
  answerQuestion,
  changeQuestion,
  submitQuiz,
  surveyId,
}: UseQuizAnalyticsProps) => {
  const [quizSessionId] = React.useState(() => uuidv4())
  const [quizStarted, setQuizStarted] = React.useState(false)
  const {startFormTimer, getTotalTime} = useFormTimer()
  const {previousPageUrl} = usePageAnalyticsContext<string>()

  // logEvent function with default fields
  const logEvent = React.useCallback<LogEvent>(
    ({eventName, eventProperties}) => {
      originalLogEvent({
        eventName: eventName,
        eventProperties: {
          quizSessionId,
          surveyId,
          ...eventProperties,
        },
      })
    },
    [originalLogEvent, quizSessionId, surveyId],
  )

  // used for tracking text field changes
  const debouncedLogEvent = React.useMemo(() => {
    return debounce(logEvent, 500)
  }, [logEvent])

  // Fires Quiz started event on the beginning of the quiz (on quiz mounted)
  React.useEffect(() => {
    if (quizStarted) return

    startFormTimer()
    logEvent({
      eventName: QuizAnalyticsEvents.View,
      eventProperties: {previousPage: previousPageUrl, startTime: new Date().toUTCString()},
    })
    setQuizStarted(true)
  }, [logEvent, previousPageUrl, quizStarted, startFormTimer])

  // Tracks error messages
  React.useEffect(() => {
    if (typeof quiz?.activeQuestion?.validation.isValid === 'undefined' || quiz?.activeQuestion?.validation.isValid)
      return

    const errorMessages = Object.keys(quiz?.activeQuestion?.choices ?? {})
      .map(key => quiz?.activeQuestion?.choices[key])
      .filter(
        (choice): choice is NonNullable<typeof choice> & {description: string} => !!choice && !!choice.description,
      )
      .map(choice => errorMessage(choice.description))

    logEvent({
      eventName: QuizAnalyticsEvents.OnError,
      eventProperties: {
        errorMessages,
        questionId: quiz?.activeQuestion?.questionId ?? '',
        questionName: quiz?.activeQuestion?.questionName ?? '',
        questionText: quiz?.activeQuestion?.questionText ?? '',
      },
    })
  }, [
    logEvent,
    quiz?.activeQuestion?.choices,
    quiz?.activeQuestion?.questionId,
    quiz?.activeQuestion?.questionName,
    quiz?.activeQuestion?.questionText,
    quiz?.activeQuestion?.validation.isValid,
  ])

  // Tracks on every answer or answer change
  const trackedAnswerQuestion = React.useCallback(
    (answer: React.ReactText, questionId: string) => {
      if (quiz?.activeQuestion?.questionType === QuestionTypes.TEFORM) {
        debouncedLogEvent({
          eventName: QuizAnalyticsEvents.ChangeAnswer,
          eventProperties: {
            answer,
            questionId: quiz?.activeQuestion.questionId,
            questionName: quiz?.activeQuestion?.questionName,
            questionText: quiz?.activeQuestion?.questionText,
          },
        })
      } else {
        logEvent({
          eventName: QuizAnalyticsEvents.ChangeAnswer,
          eventProperties: {
            answer,
            questionId: quiz?.activeQuestion?.questionId ?? '',
            questionName: quiz?.activeQuestion?.questionName ?? '',
            questionText: quiz?.activeQuestion?.questionText ?? '',
          },
        })
      }
      return answerQuestion(answer, questionId)
    },
    [
      answerQuestion,
      debouncedLogEvent,
      logEvent,
      quiz?.activeQuestion?.questionId,
      quiz?.activeQuestion?.questionName,
      quiz?.activeQuestion?.questionText,
      quiz?.activeQuestion?.questionType,
    ],
  )

  // Tracks focus on email fields
  const trackEmailFieldOnFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      logEvent({
        eventName: QuizAnalyticsEvents.OnEmailFocus,
        eventProperties: {
          currentValue: e.currentTarget.value ?? '',
          fieldLabel: e.currentTarget.labels?.[0]?.innerHTML ?? '',
          questionId: quiz?.activeQuestion?.questionId ?? '',
          questionName: quiz?.activeQuestion?.questionName ?? '',
          questionText: quiz?.activeQuestion?.questionText ?? '',
        },
      })
    },
    [
      logEvent,
      quiz?.activeQuestion?.questionId,
      quiz?.activeQuestion?.questionName,
      quiz?.activeQuestion?.questionText,
    ],
  )

  // Tracks quiz submission
  const trackedSubmit = React.useCallback(() => {
    // fire submit event only if the submission is successful
    submitQuiz().then(res => {
      if (res) {
        logEvent({
          eventName: QuizAnalyticsEvents.Submit,
          eventProperties: {
            answers: quiz?.answers ?? {},
            previousPage: previousPageUrl,
            totalTime: getTotalTime() ?? '',
          },
        })
      }
    })
  }, [getTotalTime, logEvent, previousPageUrl, quiz?.answers, submitQuiz])

  // Tracks on back event
  const trackedChangeQuestion = React.useCallback<(forward: boolean) => void>(
    (forward = true) => {
      if (!forward) {
        logEvent({
          eventName: QuizAnalyticsEvents.Back,
          eventProperties: {
            currentQuestionName: quiz?.activeQuestion?.questionName ?? '',
            currentQuestionText: quiz?.activeQuestion?.questionText ?? '',
            questionId: quiz?.activeQuestion?.questionId ?? '',
          },
        })
      }

      return changeQuestion(forward)
    },
    [
      changeQuestion,
      logEvent,
      quiz?.activeQuestion?.questionId,
      quiz?.activeQuestion?.questionName,
      quiz?.activeQuestion?.questionText,
    ],
  )

  // Tracks on exit
  const trackedOnExit = React.useCallback(() => {
    logEvent({
      eventName: QuizAnalyticsEvents.Quit,
      eventProperties: {
        answersSoFar: quiz?.answers ?? {},
        currentQuestionName: quiz?.activeQuestion?.questionName ?? '',
        currentQuestionText: quiz?.activeQuestion?.questionText ?? '',
        questionId: quiz?.activeQuestion?.questionId ?? '',
        totalTime: getTotalTime() ?? '',
      },
    })
  }, [
    getTotalTime,
    logEvent,
    quiz?.activeQuestion?.questionId,
    quiz?.activeQuestion?.questionName,
    quiz?.activeQuestion?.questionText,
    quiz?.answers,
  ])

  // Track on window close or typed new address on address bar
  React.useEffect(() => {
    window.addEventListener('beforeunload', trackedOnExit)

    return () => window.removeEventListener('beforeunload', trackedOnExit)
  }, [trackedOnExit])

  return {
    trackedAnswerQuestion,
    trackedChangeQuestion,
    trackedOnExit,
    trackedSubmit,
    trackEmailFieldOnFocus,
  }
}
