import * as React from 'react'

import styles from './QuizHeader.module.scss'

interface PaginationProps {
  totalSteps?: number
  step?: number
}

const Pagination = ({totalSteps = 0, step = 0}: PaginationProps) => (
  <div className={styles.pagination} data-cy="quiz_pagination">
    {new Array(totalSteps).fill(0).map((_, i) => {
      const isActive = i < step
      return (
        <div
          className={isActive ? styles.active : ''}
          data-cy={`quiz_paginationItem-${isActive ? 'active' : 'inactive'}`}
          key={`step_${i}`}
        />
      )
    })}
  </div>
)

export {Pagination}
