import * as React from 'react'

/**
 * Translates seconds into human readable format of seconds, minutes, hours, days, and years
 */
export const durationForHumans = (seconds: number) => {
  const levels: [string, string][] = [
    [Math.floor(seconds / 31536000).toString(), 'years'],
    [Math.floor((seconds % 31536000) / 86400).toString(), 'days'],
    [Math.floor(((seconds % 31536000) % 86400) / 3600).toString(), 'hours'],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60).toString(), 'minutes'],
    [((((seconds % 31536000) % 86400) % 3600) % 60).toString(), 'seconds'],
  ]
  let returnText = ''

  for (let i = 0, max = levels.length; i < max; i++) {
    if (parseInt(levels[i][0]) === 0) continue
    returnText += ` ${levels[i][0]} ${
      parseInt(levels[i][0]) === 1 ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1]
    }`
  }
  return returnText.trim()
}

const useFormTimer = () => {
  const [formStartTime, setFormStartTime] = React.useState<Date | undefined>()
  const startFormTimer = () => {
    if (!formStartTime) setFormStartTime(new Date())
  }

  const clearFormStartTime = () => {
    setFormStartTime(undefined)
  }

  const getTotalTime = () => {
    // Unary + will coerce to number to silence the type error.
    if (!formStartTime) return
    return durationForHumans(Math.round((+new Date() - +formStartTime) / 1000))
  }
  return {clearFormStartTime, formStartTime, getTotalTime, startFormTimer}
}

export default useFormTimer
