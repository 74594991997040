import * as React from 'react'
import isEmpty from 'lodash/isEmpty'

import {Department, FilterValues, Job, Office} from 'types/greenhouse'

import {greenhouseAPI} from '../../services/greenhouse/greenhouseService'
import {
  detectWorkDaySchema,
  extractLocations,
  extractLocationsFromJobs,
  filterDepartmentsByLocationLegacy,
  filterDepartmentsBySearchTerm,
  filterDepartmentsWithJobs,
  filterJobsByLocation,
  filterJobsBySearchTerm,
  sortDepartmentsByName,
  trimDepartmentName,
} from './useCareersUtils'

export let ALL_LOCATIONS = 'All locations'

export const DEFAULT_DEPARTMENTS: Department[] = []
export const DEFAULT_JOBS: Job[] = []
export const DEFAULT_FILTERED_JOBS: Job[] = []
export const DEFAULT_IS_LOADING = false
export const DEFAULT_OFFICES: Office[] = []

export let DEFAULT_FILTER_VALUES: FilterValues = {location: ALL_LOCATIONS, searchTerm: ''}
export let DEFAULT_LOCATIONS: string[] = [ALL_LOCATIONS]

export interface ICareersSearchState {
  departments: Department[]
  jobs: Job[]
  setIsLoading: (value: boolean) => void
  filterValues: FilterValues
  isLoading: boolean
  locations: string[]
  setFilterValues: React.Dispatch<React.SetStateAction<FilterValues>>
}

const USE_CAREERS_DEFAULT_VALUES = {
  allLocationsText: ALL_LOCATIONS,
}

interface UseCareersProps {
  allLocationsText: string
}

const useCareers = ({allLocationsText}: UseCareersProps = USE_CAREERS_DEFAULT_VALUES): ICareersSearchState => {
  ALL_LOCATIONS = allLocationsText
  DEFAULT_LOCATIONS = ALL_LOCATIONS ? [ALL_LOCATIONS] : []
  DEFAULT_FILTER_VALUES = {location: ALL_LOCATIONS, searchTerm: ''}

  const [originalDepartments, setOriginalDepartments] = React.useState<Department[]>(DEFAULT_DEPARTMENTS)

  const [originalJobs, setOriginalJobs] = React.useState<Job[]>(DEFAULT_JOBS)
  const [departments, setDepartments] = React.useState<Department[]>(DEFAULT_DEPARTMENTS)
  const [jobs, setJobs] = React.useState<Job[]>(DEFAULT_JOBS)
  const [filterValues, setFilterValues] = React.useState<FilterValues>(DEFAULT_FILTER_VALUES)
  const [locations, setLocations] = React.useState<string[]>(DEFAULT_LOCATIONS)
  const [isLoading, setIsLoading] = React.useState<boolean>(DEFAULT_IS_LOADING)

  const populateDepartments = React.useCallback((apiResponseBodyDepartments: Department[]): void => {
    const filteredDepartments = filterDepartmentsWithJobs(apiResponseBodyDepartments).map(department => ({
      ...department,
      name: trimDepartmentName(department.name), // #TODO: remove when department codes are removed from Greenhouse
    }))

    setLocations([ALL_LOCATIONS, ...extractLocationsFromJobs(filteredDepartments)])
    setDepartments(filteredDepartments)
    setOriginalDepartments(filteredDepartments)
  }, [])

  const populateJobs = React.useCallback((apiResponseBodyJobs: Job[]): void => {
    setLocations([ALL_LOCATIONS, ...extractLocations(apiResponseBodyJobs)])
    setJobs(apiResponseBodyJobs)
    setOriginalJobs(apiResponseBodyJobs)
  }, [])

  const filterJobs = React.useCallback(() => {
    let filteredJobs: Job[] = []

    if (!isEmpty(filterValues.location) && filterValues.location === ALL_LOCATIONS) {
      filteredJobs = originalJobs
    } else if (!isEmpty(filterValues.location)) {
      filteredJobs = filterJobsByLocation(originalJobs, filterValues.location)
    }

    if (!isEmpty(filterValues.searchTerm)) {
      filteredJobs = filterJobsBySearchTerm(jobs, filterValues.searchTerm)
    }

    setJobs(filteredJobs)
  }, [filterValues.location, filterValues.searchTerm, originalJobs])

  const filterJobsLegacy = React.useCallback(() => {
    let filteredDepartments: Department[] = []

    if (!isEmpty(filterValues.location) && filterValues.location === ALL_LOCATIONS) {
      filteredDepartments = originalDepartments
    } else if (!isEmpty(filterValues.location)) {
      filteredDepartments = filterDepartmentsByLocationLegacy(originalDepartments, filterValues.location)
    }

    if (!isEmpty(filterValues.searchTerm)) {
      filteredDepartments = filterDepartmentsBySearchTerm(filteredDepartments, filterValues.searchTerm)
    }

    filteredDepartments = sortDepartmentsByName(filteredDepartments)

    setDepartments(filteredDepartments)
  }, [filterValues.location, filterValues.searchTerm, originalDepartments])

  React.useEffect(() => {
    if (!isEmpty(jobs)) {
      filterJobs()
    }
    if (!isEmpty(departments)) {
      filterJobsLegacy()
    }
  }, [filterValues.location, filterValues.searchTerm])

  React.useEffect(() => {
    const populate = async () => {
      setIsLoading(true)

      const [jobsResponse, departmentsResponse] = await Promise.all([
        greenhouseAPI('jobs'),
        greenhouseAPI('departments'),
      ])

      if (!detectWorkDaySchema(jobsResponse)) {
        console.warn('Could not detect new workday schema falling back to departments schema')
        populateDepartments(departmentsResponse)
      } else {
        populateJobs(jobsResponse)
      }
      setIsLoading(false)
    }

    populate()
  }, [populateDepartments, populateJobs])

  return {
    // Public getters
    departments,
    isLoading,
    jobs,
    locations,
    // Public setters
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filterValues,
    setFilterValues,
    setIsLoading,
  }
}

export {useCareers}
