import React from 'react'
import {kebabCase} from 'lodash'
import {DateTime} from 'luxon'
import {NumberParam, QueryParamConfig, StringParam, useQueryParam, useQueryParams, withDefault} from 'use-query-params'

import {NvPaginationContext, useTabs} from '@invitae/ids-react'
import {NvPaginationProps, NvPaginationState} from '@invitae/ids-react/dist/NvPagination/useNvPagination.hooks'
import {
  countryCodeToLocale,
  getContentfulEntries,
  useContentfulClient,
  useCountryLocationPrefix,
  useTextCopy,
} from '@invitae/nucleobase'
import {useEffectAsync} from '@invitae/react-hooks'

import {specialties} from 'constants/specialtyAreas'
import {WebinarTabIds} from 'types/TabTypes'
import {getYearsToPresent} from 'utils/dates'

const LINK_LEVEL_DEPTH = 10 // https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/links/retrieval-of-linked-items

type PaginationProps = Omit<NvPaginationProps, 'pagination'> & {
  pagination: NvPaginationState & {firstRequest?: boolean}
}

interface UseEducationCenterProps {
  defaultTabId: string
  modelName: 'webinar' | 'postersAndPresentations' | 'publication'
}

const CONTENTFUL_WEBINAR_QUERIES = {
  byRecentOrUpcoming: (modelName: UseEducationCenterProps['modelName'], tabType: string) => {
    if (modelName === 'postersAndPresentations') {
      return {
        order: '-fields.startDate',
      }
    }

    if (modelName === 'publication') {
      return {
        order: '-fields.date',
      }
    }

    const now: string = DateTime.local().toString()

    if (tabType === WebinarTabIds.Upcoming) {
      return {
        // webinars where the 'date' field is greater than or equal to ('gte') the current datetime
        'fields.date[gte]': now,
        order: 'fields.date',
      }
    }

    if (tabType === WebinarTabIds.Recent) {
      return {
        // webinars where the 'date' field is less than ('lt') the current datetime
        'fields.date[lt]': now,
        order: '-fields.date',
      }
    }

    return {}
  },
  bySearch: (searchString: string) => ({
    query: searchString,
  }),
  // webinars where the 'specialtyArea' field includes the passed-in string 'area'. multiple 'area' can be passed in as one string, comma-separated with no space
  // example: 'Oncology,Pediatric' would return webinars that have both Oncology and Pediatric in the 'specialtyArea' field
  bySpecialtyArea: (area: string) => ({
    'fields.specialtyArea[in]': specialties.find(specialty => {
      return specialty.value === area
    })?.label,
  }),
  /**
   * Webinars where the 'date' field is greater or equal to the passed-in number 'year' (current year), but less than the 'year' + 1 (next year)
   * If user selects the current year, instead 1 year between dates, the interval should be the first date of the year and the current date
   */
  byYear: (modelName: UseEducationCenterProps['modelName'], selectedYear: number) => {
    const currentDate = DateTime.local()
    const startDate = DateTime.local(selectedYear).toString()
    const endDate = (currentDate.year === selectedYear ? currentDate : DateTime.local(selectedYear + 1)).toString()

    if (modelName === 'postersAndPresentations') {
      return {
        'fields.endDate[lt]': endDate,
        'fields.startDate[gte]': startDate,
      }
    }

    return {
      'fields.date[gte]': startDate,
      'fields.date[lt]': endDate,
    }
  },
}

const SPECIALTY_AREAS = specialties
const FILTERABLE_YEARS: number[] = getYearsToPresent(2014)

/**
 * Delete this SpecialtyParam rule after:
 * All the links on `/providers/education-center` be fixed
 */
const SpecialtyParam: QueryParamConfig<string, string | undefined> = {
  decode(value) {
    if (!value) return ''

    return kebabCase(value as string)
  },

  encode(value) {
    return value
  },
}

export const useEducationCenter = <T>({defaultTabId, modelName}: UseEducationCenterProps) => {
  const contentfulClient = useContentfulClient()
  const {currentTab, selectTab} = useTabs()
  const {getText} = useTextCopy()
  const [tabParam] = useQueryParam('tab', StringParam)

  const [query, setQuery] = useQueryParams({
    currentPage: withDefault(NumberParam, 1),
    numPerPage: withDefault(NumberParam, 10),
    searchQuery: withDefault(StringParam, undefined),
    specialty: withDefault(SpecialtyParam, ''),
    tab: withDefault(StringParam, defaultTabId),
    year: withDefault(NumberParam, undefined),
  })

  const realTab = React.useMemo(() => currentTab === query.tab, [currentTab, query.tab])

  const {pathCountryCode: countryCode} = useCountryLocationPrefix()
  const {pagination, resetPagination, setPagination, goBackToPageOne} =
    React.useContext<PaginationProps>(NvPaginationContext)

  const paginationFirstRender = React.useRef(true)
  const onSearchFirstRender = React.useRef(true)

  React.useEffect(resetPagination, [realTab])
  React.useEffect(goBackToPageOne, [query.year, query.specialty, query.searchQuery])

  const fetchEntries = React.useCallback(async () => {
    // avoid multiple requests if it's in the wrong tab
    if (!realTab) return []

    // contentfulQueries is an object used in querying the Contentful API: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters
    // multiple queries can be included as key/value pairs of strings, and the API will combine them to narrow the search
    // we start with the selected tab and then add the other queries based on the filters in state
    const contentfulQueries = {
      ...CONTENTFUL_WEBINAR_QUERIES.byRecentOrUpcoming(modelName, query.tab),
      ...(query.year && CONTENTFUL_WEBINAR_QUERIES.byYear(modelName, query.year)),
      ...(query.specialty && CONTENTFUL_WEBINAR_QUERIES.bySpecialtyArea(query.specialty)),
      ...(query.searchQuery && CONTENTFUL_WEBINAR_QUERIES.bySearch(query.searchQuery)),
    }

    const entries = await getContentfulEntries(contentfulClient, {
      content_type: modelName,
      include: LINK_LEVEL_DEPTH,
      limit: query.numPerPage,
      locale: countryCodeToLocale[countryCode?.toUpperCase()],
      skip: query.currentPage > 1 ? (query.currentPage - 1) * query.numPerPage : 0,
      ...contentfulQueries,
    })

    setPagination(pagination => ({
      ...pagination,
      ...(isNaN(pagination.currentPage) ? {currentPage: 1} : {}),
      ...(isNaN(pagination.numPerPage) ? {numPerPage: 10} : {}),
      firstRequest: true,
      totalEntries: entries.total,
    }))

    const entriesFields = entries?.items?.map?.(entry => entry.fields)
    // Returns the paginated results for the webinar entries
    return entriesFields as T[]
  }, [query, countryCode, contentfulClient, setPagination, realTab, modelName])

  const {data: entries, isLoading, error: fetchError} = useEffectAsync<T[]>(fetchEntries, [fetchEntries])

  const error = React.useMemo(
    () =>
      !entries?.length || fetchError ? 'There are no entries that match the current filter. Please try again.' : null,
    [entries, fetchError],
  )

  const onFilterChange = React.useCallback(
    (filter: string) => (value: string | number) => {
      setQuery({currentPage: 1, [filter]: value || undefined})
    },
    [setQuery],
  )

  const handleSearchFilter = React.useCallback(
    (value: string) => {
      // // if is first render, we do nothing
      // // we have to do that since input is dispatching onChange on the first render
      if (onSearchFirstRender.current) {
        onSearchFirstRender.current = false

        return
      }

      setQuery({
        currentPage: 1,
        searchQuery: value || undefined,
      })
    },
    [setQuery],
  )

  const specialtyAreaFilterConfig = {
    changeHandler: onFilterChange('specialty'),
    label: getText('specialtyLabel'),
    options: [...SPECIALTY_AREAS, {label: getText('defaultSpecialtyOptionText'), value: ''}],
    selectedValue: query.specialty,
    width: 260,
  }

  const yearFilterConfig = {
    changeHandler: onFilterChange('year'),
    label: getText('yearLabel'),
    options: [
      ...FILTERABLE_YEARS.map(optionNumber => ({label: optionNumber.toString(), value: optionNumber})),
      {label: getText('defaultYearOptionText'), value: undefined} as any,
    ],
    selectedValue: query.year as any,
  }

  React.useEffect(() => {
    // if first request to get entries already finished, is `upcoming` tab and there's no results, we have to redirect to `recent` tab
    if (
      !isLoading &&
      !tabParam &&
      error &&
      !pagination.totalEntries &&
      pagination?.firstRequest &&
      currentTab === WebinarTabIds.Upcoming
    ) {
      selectTab(WebinarTabIds.Recent)

      return
    }

    // if queryTab doesn't exist and we need to keep in the current tab, add tab to the URL query params
    if (!isLoading && !tabParam && pagination.totalEntries && pagination?.firstRequest) {
      setQuery({
        tab: currentTab,
      })
    }
  }, [error, isLoading, pagination, currentTab, setQuery, selectTab, tabParam])

  React.useEffect(() => {
    // just on first render
    if (paginationFirstRender.current && realTab) {
      // need to have values on the url otherwise we don't update pagination
      if (query.currentPage && query.numPerPage) {
        // get values from URL to update NvPagination on the footer
        setPagination(prev => ({
          ...prev,
          currentPage: Number(query.currentPage),
          currentPageInput: Number(query.currentPage),
          numPerPage: Number(query.numPerPage),
          numPerPageInput: Number(query.numPerPage),
        }))
      }

      paginationFirstRender.current = false
    }
  }, [query, setPagination, realTab])

  React.useEffect(() => {
    // verification to make sure we have entries and the first request already finished
    if (!isLoading && pagination.totalEntries && realTab) {
      // if pagination change currentPage or numPerPage, we need to update URL
      setQuery({
        currentPage: pagination.currentPage,
        numPerPage: pagination.numPerPage,
      })
    }
  }, [pagination, setQuery, isLoading, realTab])

  return {
    entries,
    error,
    handleSearchFilter,
    isLoading,
    pagination,
    searchValue: query.searchQuery,
    setPagination,
    specialtyAreaFilterConfig,
    tab: query.tab,
    totalResultsCount: pagination.totalEntries,
    yearFilterConfig,
  }
}
