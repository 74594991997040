import * as React from 'react'

import {NvHeading} from '@invitae/ids-react'

import {Job} from 'types/greenhouse'

import {useAnalytics} from './useAnalytics.hooks'
import {getJobTitle, getWorkLocationMetadata, groupByField} from './useCareersUtils'

import styles from './JobsByDepartment.module.scss'

interface JobByFamilyGroupProps {
  familyGroups: Record<string, Job[]>
}

const JobsByFamilyGroup = ({familyGroups}: JobByFamilyGroupProps) => {
  const {trackViewApplicationButton} = useAnalytics()

  return (
    <div>
      {Object.entries(familyGroups)
        .sort()
        .map(([key, jobs]) => (
          <div data-testid="job-family-section" key={key}>
            <NvHeading className={styles.heading} element="h3" level={3}>
              {key}
            </NvHeading>

            {Object.keys(groupByField(jobs, getJobTitle))
              .sort()
              .map(key => (
                <div data-testid="job-code" key={key}>
                  <span className={styles.jobTitle}>{key}</span> -{' '}
                  {groupByField(jobs, getJobTitle)[key].map(job => (
                    <a
                      className={styles.jobDetailLink}
                      href={job.absolute_url}
                      key={job.id}
                      onClick={() => {
                        trackViewApplicationButton(job.title, job.absolute_url)
                      }}
                      rel="noopener noreferrer"
                      target="_blank"
                      title="View application on Greenhouse"
                    >
                      {getWorkLocationMetadata(job)}
                    </a>
                  ))}
                </div>
              ))}

            <hr className={styles.lineSeparator} />
          </div>
        ))}
    </div>
  )
}

export default JobsByFamilyGroup
