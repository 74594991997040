import * as React from 'react'
import classnames from 'classnames'
import {CountUp} from 'use-count-up'

import {useElementVisibleAfterScroll} from '@invitae/nucleobase'
import {useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import styles from './HomeChosenByMillions.module.scss'

const COUNT_UP_START_COUNT = 2900000
const COUNT_UP_END_COUNT = 3000000
const COUNT_UP_DURATION = 1.2

const HomeChosenByMillions = () => {
  const targetRef = React.useRef<HTMLParagraphElement>(null)
  const isVisible = useElementVisibleAfterScroll(targetRef)

  const {getText} = useTextCopy<TextCopyType<'homeChosenByMillions'>>()

  return (
    <div className={classnames(styles.root)} data-cy="chosenByMillions">
      <p className={styles.title}>{getText('title')}</p>
      <div className={styles.count}>
        <p ref={targetRef}>
          <CountUp
            duration={COUNT_UP_DURATION}
            end={COUNT_UP_END_COUNT}
            isCounting={isVisible}
            start={COUNT_UP_START_COUNT}
            suffix="+"
          />
        </p>
      </div>
      <p className={styles.description}>{getText('description')}</p>
    </div>
  )
}

export default HomeChosenByMillions
