import {Answers, IGetSurvey} from 'components/Quiz/Quiz.types'
import marcomFetch from 'utils/marcomFetch/marcomFetch'

interface IQualtricsAPICall {
  qualtricsApiToken?: string
  qualtricsHostName?: string
  qualtricsSurveyId?: string
}

interface ISubmitQuiz extends IQualtricsAPICall {
  answers: Answers
}

export const getQualtricsAPI = async ({qualtricsApiToken, qualtricsHostName, qualtricsSurveyId}: IQualtricsAPICall) => {
  const response = await marcomFetch(`https://${qualtricsHostName}/API/v3/surveys/${qualtricsSurveyId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-TOKEN': qualtricsApiToken || '',
    },
    method: 'get',
  })

  return (await response.json()) as IGetSurvey
}

export const postQualtricsAPI = async ({
  answers,
  qualtricsApiToken,
  qualtricsHostName,
  qualtricsSurveyId,
}: ISubmitQuiz) => {
  const response = await marcomFetch(`https://${qualtricsHostName}/API/v3/surveys/${qualtricsSurveyId}/responses`, {
    body: JSON.stringify({values: answers}),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-TOKEN': qualtricsApiToken || '',
    },
    method: 'post',
  })

  return await response.json()
}
