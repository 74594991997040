export interface QuizData {
  flow: EnhancedFlowData[]
  activeQuestion?: Question
  position: {
    flowI: number
    subFlowI: number
    questionI: number
  }
  nextBlockI?: number
  answers: Answers
  questions: IGetSurvey['result']['questions']
  navHistory: Array<PositionData>
}

export interface PositionData {
  flowI: number
  subFlowI: number
  questionI: number
}

export type EnhancedFlowData = EnhancedFlowBlock | EnhancedFlowBranch | FlowEmbeddedData | FlowEndSurvey

interface EnhancedFlowBlock extends FlowBlock {
  questions: Array<EnhancedElement>
}

interface EnhancedFlowBranch extends FlowBranch {
  flow: Array<EnhancedFlowData>
}

export enum QuestionTypes {
  MCDL = 'MCDL',
  MCSAVR = 'MCSAVR',
  MCTE = 'MCTE',
  TEFORM = 'TEFORM',
  SFCO = 'SFCO',
}

export interface EnhancedElement {
  answers: Record<string, unknown>
  choices: IQuestion['choices']
  headerText: IQuestion['questionText']
  questionId: IElement['questionId']
  questionName: IQuestion['questionName']
  questionText: IQuestion['questionText']
  hasVariable: IQuestion['hasVariable']
  variableNames: IQuestion['variableNames']
  variableChoiceGroupValues: IQuestion['variableChoiceGroupValues']
  variableMaxLengths: IQuestion['variableMaxLengths']
  questionType: keyof typeof QuestionTypes
  validation: IQuestion['validation']
}

export type FlowData = FlowBranch | FlowBlock | FlowEmbeddedData | FlowEndSurvey

interface FlowBranch {
  type: 'Branch'
  flow: Array<FlowData>
}

interface FlowBlock {
  type: 'Block'
  id: string
}

interface FlowEmbeddedData {
  type: 'EmbeddedData'
}

interface FlowEndSurvey {
  type: 'EndSurvey'
}

export interface Answers {
  [key: string]: string | number
}

export interface Choice {
  index?: string
  setValue: (value: string | number, key: string) => void
  question: Question
  choiceText?: string
  description?: string
}

export interface Question {
  questionText: string
  questionName: string
  headerText: string
  questionType: EnhancedElement['questionType']
  questionId: string
  type?: string
  submitOnComplete?: boolean
  answers: Answers
  validation: {
    doesForceResponse: boolean
    email?: boolean
    isValid?: boolean | null
  }
  choices: {
    [key: string]: {
      choiceText: string
      description?: string
      variableName: string | null
      recode?: string
    }
  }
  step?: number
  totalSteps?: number
  hasVariable: boolean | undefined
  variableNames: string[] | undefined
  variableChoiceGroupValues: string[] | undefined
  variableMaxLengths:
    | {
        [k: string]: {small: number; medium: number}
      }
    | undefined
  tags?:
    | {
        [k: string]: string[]
      }
    | undefined
}

export interface IColumnMap {
  question: string
  choice?: string
}

interface IElement {
  type: string
  questionId: string
}

export interface IBlock {
  description: string
  elements: Array<IElement>
}

export interface IQuestion {
  questionType: {type: 'TE' | 'MC'; selector: 'FORM' | 'SAVR'; subSelector: 'TX' | null}
  questionText: string
  questionLabel: null
  validation: {doesForceResponse: boolean; email?: boolean; isValid?: boolean | null}
  questionName: string
  choices: {
    [k: string]: {
      recode?: string
      description: string
      choiceText: string
      imageDescription: null
      variableName: string | null
      analyze: boolean
    }
  }
  step?: number
  totalSteps?: number
  hasVariable?: boolean
  variableNames?: string[]
  variableChoiceGroupValues?: string[]
  variableMaxLengths?: {
    [k: string]: {small: number; medium: number}
  }
  tags?: {
    [k: string]: (string | null)[]
  }
}

export interface IGetSurvey {
  result: {
    id: string
    name: string
    ownerId: string
    organizationId: string
    isActive: boolean
    creationDate: string
    lastModifiedDate: string
    expiration: {
      startDate: string | null
      endDate: string | null
    }
    questions: {[key: string]: IQuestion}
    exportColumnMap: {[key: string]: IColumnMap}
    blocks: {[key: string]: IBlock}
    flow: Array<FlowData>
    embeddedData: Array<{name: string; defaultValue: string}>
    comments: Record<string, unknown>
    loopAndMerge: Record<string, unknown>
    responseCounts: {auditable: number; generated: number; deleted: number}
  }
  meta: {
    httpStatus: string
    requestId: string
  }
}
