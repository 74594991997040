import * as React from 'react'

import {Choice, Question} from 'components/Quiz/Quiz.types'

import {TextEntry} from './TextEntry/TextEntry'

const MultipleChoiceTextEntry = ({setValue, question, index}: Choice) => {
  const questionOverride: Question = {...question}

  const indexOverride = 'TEXT'
  questionOverride.choices = {
    [indexOverride]: question?.choices?.[index || '1'] || {choiceText: '', description: ''},
  } as any
  questionOverride.questionId = `${questionOverride.questionId}_${index}`

  function setBothValues(value: React.ReactText, key: string) {
    setValue(value, key)
    if (index === undefined) throw Error()
    setValue(parseInt(index), question.questionId)
  }

  return <TextEntry index={indexOverride} question={questionOverride} setValue={setBothValues} />
}

export {MultipleChoiceTextEntry}
