import {InsuranceType} from './enums'

const PAP_MATRIX = [
  {
    householdMembers: 1,
    threshold_0: 29180,
    threshold_100: 58320,
    threshold_250: 87480,
  },
  {
    householdMembers: 2,
    threshold_0: 39440,
    threshold_100: 78880,
    threshold_250: 118320,
  },
  {
    householdMembers: 3,
    threshold_0: 49720,
    threshold_100: 99440,
    threshold_250: 149160,
  },
  {
    householdMembers: 4,
    threshold_0: 60000,
    threshold_100: 120000,
    threshold_250: 180000,
  },
  {
    householdMembers: 5,
    threshold_0: 70280,
    threshold_100: 140560,
    threshold_250: 210840,
  },
  {
    householdMembers: 6,
    threshold_0: 80560,
    threshold_100: 161120,
    threshold_250: 241680,
  },
  {
    householdMembers: 7,
    threshold_0: 90840,
    threshold_100: 181680,
    threshold_250: 272520,
  },
  {
    householdMembers: 8,
    threshold_0: 101120,
    threshold_100: 202240,
    threshold_250: 303360,
  },
  {
    householdMembers: '> 8',
  },
]

const HOUSEHOLD_DATA = PAP_MATRIX.reduce(
  (acc, currentValue) => [...acc, {label: currentValue.householdMembers, value: currentValue.householdMembers}],
  [] as {label: string | number; value: string | number}[],
)

const INSURANCE_TYPES = (getText: (key: any) => string) => [
  {label: getText(InsuranceType.INSURED), value: InsuranceType.INSURED},
  {label: getText(InsuranceType.NON_INSURED), value: InsuranceType.NON_INSURED},
  {label: getText(InsuranceType.GOVERNMENT_PAYOR), value: InsuranceType.GOVERNMENT_PAYOR},
]

export {PAP_MATRIX, INSURANCE_TYPES, HOUSEHOLD_DATA}
