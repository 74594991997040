import * as React from 'react'

import {IImageFields} from '@invitae/contentful-types'
import {NvHeading} from '@invitae/ids-react'
import {useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import styles from './EmbryoSection.module.scss'

type ImageType = {
  alt?: string
  url?: string
}

type ImageProps = Record<'image1' | 'image2' | 'image3' | 'image4', ImageType>

const EmbryoSection = () => {
  const {getText, getMedia, isKeywordEmpty} = useTextCopy<TextCopyType<'embryoSection'>>()

  const image1 = (getMedia('image1')?.fields || {}) as IImageFields
  const image2 = (getMedia('image2')?.fields || {}) as IImageFields
  const image3 = (getMedia('image3')?.fields || {}) as IImageFields
  const image4 = (getMedia('image4')?.fields || {}) as IImageFields

  const imageProps = [image1, image2, image3, image4].reduce((acc, current, index) => {
    return {
      ...acc,
      [`image${index + 1}`]: {
        alt: current?.textAlternative,
        url: current?.asset?.fields?.file?.url,
      },
    }
  }, {} as ImageProps)

  const description = (getText('description') as string)
    .split('\n')
    .filter(text => text.trim())
    .map(text => <p key={text}>{text.trim()}</p>)

  return (
    <div className={styles.root}>
      <NvHeading element="h2" level={1}>
        {getText('title')}
      </NvHeading>
      {description}
      <h4>{getText('graphTitle')}</h4>
      <div className={styles.percentages}>
        {!isKeywordEmpty('image1') && <img alt={imageProps?.image1?.alt} src={imageProps?.image1?.url} />}
        {!isKeywordEmpty('image2') && <img alt={imageProps?.image2?.alt} src={imageProps?.image2?.url} />}
        {!isKeywordEmpty('image3') && <img alt={imageProps?.image3?.alt} src={imageProps?.image3?.url} />}
        {!isKeywordEmpty('image4') && <img alt={imageProps?.image4?.alt} src={imageProps?.image4?.url} />}
      </div>
    </div>
  )
}

export default EmbryoSection
