import * as React from 'react'

import {ChevronWhite, IconCloseWhite} from '@invitae/nucleobase'

import {useExitQuiz} from 'components/Quiz/hooks/useExitQuiz'

import styles from './QuizHeader.module.scss'

interface NavBarProps {
  step?: number
  changeQuestion: (forward: boolean) => void
  onExit?: () => void
}

const NavBar = ({step, changeQuestion, onExit}: NavBarProps) => {
  const exitQuiz = useExitQuiz()

  const handleBack = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()

      changeQuestion(false)
    },
    [changeQuestion],
  )

  const handleExit = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()

      if (onExit) onExit()

      exitQuiz()
    },
    [exitQuiz, onExit],
  )

  return (
    <div className={styles.navBar}>
      {step !== 1 ? (
        <button data-cy="quiz_prevStep-button" onClick={handleBack}>
          <ChevronWhite />
        </button>
      ) : (
        <div />
      )}

      <button data-cy="quiz_close-button" onClick={handleExit}>
        <IconCloseWhite />
      </button>
    </div>
  )
}

export {NavBar}
