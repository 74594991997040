import React from 'react'

import {NvTab} from '@invitae/ids-react'
import {useTextCopy} from '@invitae/nucleobase'

import EducationCenter from 'components/EducationCenter/EducationCenter'
import EducationCenterTabs from 'components/EducationCenter/EducationCenterTabs'
import {TextCopyType} from 'constants/textCopies'
import {WebinarTabIds} from 'types/TabTypes'

import WebinarSectionItemsCollection from './WebinarSectionItemsCollection/WebinarSectionItemsCollection'

const WebinarSection = () => {
  const {getText} = useTextCopy<TextCopyType<'webinarSection'>>()

  return (
    <EducationCenter>
      <EducationCenterTabs defaultTabId={WebinarTabIds.Upcoming}>
        <NvTab tabId={WebinarTabIds.Upcoming} titleText={getText('upcomingTabTitle')}>
          <WebinarSectionItemsCollection
            buttonText={getText('upcomingTabButton')}
            shouldShowTime
            tabName={WebinarTabIds.Upcoming}
          />
        </NvTab>
        <NvTab tabId={WebinarTabIds.Recent} titleText={getText('recentTabTitle')}>
          <WebinarSectionItemsCollection
            buttonText={getText('recentTabButton')}
            shouldShowTime={false}
            tabName={WebinarTabIds.Recent}
          />
        </NvTab>
      </EducationCenterTabs>
    </EducationCenter>
  )
}

export default WebinarSection
