import * as React from 'react'

const LeftBlob = (props: Record<string, unknown>) => (
  <svg fill="none" height="374" viewBox="0 0 429 374" width="429" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M66.3 63.312C186.413 5.16901 350.376 -36.365 408.213 49.511C466.049 135.386 386.622 182.221 339.302 239.611C291.982 297 289.535 373.5 215.269 373.5C141.002 373.5 114.574 336.287 66.2991 289.366C18.0251 242.444 -53.8139 121.454 66.2991 63.312H66.3Z"
      fill="#BFE8E4"
      fillRule="evenodd"
    />
  </svg>
)

export default LeftBlob
