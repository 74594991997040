import React, {ChangeEvent, useState} from 'react'
import {useRouter} from 'next/router'

import {NvButton, NvHeading, NvInput, NvSelect} from '@invitae/ids-react'
import {useCountryLocationPrefix} from '@invitae/nucleobase'

import {Option} from 'types/option'

import specialtyAreas from '../../../../constants/specialtyAreas'
import {getYearsToPresent} from '../../../../utils/dates'

import styles from './WebinarPageNavitagion.module.scss'

const FILTERABLE_YEARS = getYearsToPresent(2014).map(year => ({label: year.toString(), value: year}))
const FILTERABLE_SPECIALTY_AREAS = specialtyAreas.map(specialtyArea => ({label: specialtyArea, value: specialtyArea}))

const WebinarPageNavigation = () => {
  const router = useRouter()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchArea, setSearchArea] = useState<string>()
  const [year, setYear] = useState<number>()
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const withSpecialty = searchArea ? `&specialty=${searchArea}` : ''
    const withYear = year ? `&year=${year}` : ''
    const withSearchQuery = searchQuery.length > 0 ? `&searchQuery=${searchQuery}` : ''

    router.push(
      addCountryCodePrefix(`/providers/educational-webinars?tab=recent${withSearchQuery}${withSpecialty}${withYear}`),
    )
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const handleSpecialtyAreasChange = (option: Option<string>) => {
    setSearchArea(option.value)
  }

  const handleYearChange = (option: Option<number>) => {
    setYear(option.value)
  }

  return (
    <form className={styles.webinarNavigation} onSubmit={handleSubmit}>
      <div className={styles.webinarBrowse}>
        <NvHeading level={3}>Browse webinars</NvHeading>
        <NvInput
          id="search-webinars"
          label=" "
          onChange={handleSearchChange}
          placeholder="E.g. Sherloc: a comprehensive..."
        />
      </div>
      <div className={styles.webinarFilters}>
        <NvSelect
          label="Specialty areas"
          name="specialty-areas"
          onChange={option => handleSpecialtyAreasChange(option as Option<string>)}
          options={FILTERABLE_SPECIALTY_AREAS}
        />
        <NvSelect<{label: string; value: number}>
          label="Webinar archive"
          name="webinar-archive"
          onChange={option => handleYearChange(option as Option<number>)}
          options={FILTERABLE_YEARS}
        />
        <NvButton className={styles.submitButton} size="small" variant="secondary">
          Submit
        </NvButton>
      </div>
    </form>
  )
}

export default WebinarPageNavigation
