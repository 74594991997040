import * as React from 'react'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

import {NvHeading, NvInput, NvSelect, NvSpinner} from '@invitae/ids-react'
import {useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'
import {FilterValues} from 'types/greenhouse'

import JobsByDepartment from './JobsByDepartment'
import JobsByFamilyGroup from './JobsByFamilyGroup'
import {ICareersSearchState, useCareers} from './useCareers.hooks'
import {getJobFamilyMetadata, groupByField} from './useCareersUtils'

import styles from './CareersSearch.module.scss'

const CareersSearch = () => {
  const {getText} = useTextCopy<TextCopyType<'careersSearch'>>()

  const {jobs, departments, setIsLoading, filterValues, isLoading, locations, setFilterValues}: ICareersSearchState =
    useCareers({
      allLocationsText: getText('locationPlaceholder'),
    })

  const handleInputChange = React.useCallback(
    (value: string, field: string) => {
      setFilterValues((prevState: FilterValues) => ({
        ...prevState,
        [field]: value,
      }))
    },
    [setFilterValues],
  )
  const onChange = React.useCallback(
    (value: string) => {
      handleInputChange(value, 'searchTerm')
      setIsLoading(false)
    },
    [handleInputChange, setIsLoading],
  )

  const debounceSearch = React.useCallback(debounce(onChange, 300), [onChange])

  const handleSearch = React.useCallback(
    (value: string) => {
      setIsLoading(true)
      debounceSearch(value)
    },
    [debounceSearch],
  )

  const nvSelectOptions = (options: string[]) => options.map(option => ({label: option, value: option}))

  return (
    <div className={classnames(styles.root, 'nv-container')} data-cy="job-positions">
      <div className={styles.innerWrapper}>
        <h2>{getText('kicker')}</h2>

        <NvHeading className={styles.heading} element="h2" level={2}>
          {getText('title')}
        </NvHeading>

        <div className={styles.filtersWrapper}>
          <NvInput
            classes={{root: styles.select}}
            label={getText('searchLabel')}
            name="searchJob"
            onChange={e => handleSearch(e.target.value)}
            placeholder={getText('searchPlaceholder')}
          />

          <NvSelect
            classes={{root: styles.select}}
            disabled={isLoading}
            label={getText('locationLabel')}
            name="location"
            onChange={newValue => handleInputChange(newValue?.value as string, 'location')}
            options={nvSelectOptions(locations)}
            placeholder={getText('locationPlaceholder')}
            value={filterValues.location ? {label: filterValues.location, value: filterValues.location} : null}
          />
        </div>

        <div className={styles.jobsWrapper}>
          <NvSpinner isLoading={isLoading} />

          {!isLoading && (
            <div className="nv-body" data-testid="jobs-available">
              {departments.length === 0 && jobs.length === 0 && <div>{getText('noOpenOpportunitiesText')}</div>}

              {departments.length > 0 && jobs.length === 0 && <JobsByDepartment departments={departments} />}

              {departments.length === 0 && jobs.length > 0 && (
                <JobsByFamilyGroup familyGroups={groupByField(jobs, getJobFamilyMetadata)} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CareersSearch
