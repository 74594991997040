import {cloneDeep} from 'lodash'

import {Question, QuizData} from 'components/Quiz/Quiz.types'

export const setPreviousQuestion = (quiz: QuizData) => {
  // at first question or something has gone wrong
  const quizHistoryLength: number = quiz.navHistory.length
  if (quizHistoryLength === 0 || quizHistoryLength === 1) {
    return
  }

  // the last item in the history is current position we are shooting for before that
  quiz.navHistory.pop()
  const targetPosition = quiz.navHistory[quiz.navHistory.length - 1]
  const {flowI, subFlowI, questionI} = targetPosition

  let activeQuestion: Question | undefined
  const targetFlow = quiz.flow[flowI]
  if (targetFlow.type === 'Branch') {
    // if the target flow is a branch you must select a question from that branches subflow

    const selectedBlockSubFlow = targetFlow.flow[subFlowI]
    if (selectedBlockSubFlow.type !== 'Block') {
      throw Error()
    }

    activeQuestion = (selectedBlockSubFlow.questions[questionI] || quiz.activeQuestion) as Question
  } else if (targetFlow.type === 'Block') {
    activeQuestion = (targetFlow.questions[questionI] || quiz.activeQuestion) as Question
  }

  if (!activeQuestion) {
    throw Error('No questions in the flow')
  }

  quiz.activeQuestion = cloneDeep(activeQuestion)
  quiz.position = {...targetPosition}
}
