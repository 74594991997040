import * as React from 'react'
import classNames from 'classnames'

import {IComponentBlock, IComponentBlockFields, IMediaContent, IPageFields} from '@invitae/contentful-types'
import {NvHeading, NvLink, NvSubheading} from '@invitae/ids-react'
import {useCountryLocationPrefix, useTextCopy, WrappedVideo} from '@invitae/nucleobase'

// import {useMaxMediaQuery} from '@invitae/react-hooks'
import {TextCopyType} from 'constants/textCopies'

import styles from './HomeMobileResults.module.scss'

const HomeMobileResults = ({originalFields}: any) => {
  const homeMobileResultsComponent = (originalFields as IPageFields)?.sections?.find?.(
    field => (field.fields as IComponentBlockFields).componentName === 'homeMobileResults',
  ) as IComponentBlock

  // Video not being rendered on homepage mobile results block?
  // Check `homepageMobileResults` text copies' ordering first

  // We don't really filter/reduce through the list since internal name (or any other id-like field) can always change
  // And technically ordering of the text copies can also change so the best of the worst is to avoid additional...
  // ...mapping operation and assume that the media content will be the last text copy in the list
  // If at any point ordering of text copies in Contentful changes, 500 will be thrown...
  // ...so to avoid it we will not render the `WrappedVideo` component at all
  const fields = (homeMobileResultsComponent?.fields?.textCopies?.at(-1) as IMediaContent)?.fields?.media?.fields
    ?.imageOrVideo?.fields
  const {getText, isKeywordEmpty} = useTextCopy<TextCopyType<'homeMobileResults'>>()
  const {addCountryCodePrefix} = useCountryLocationPrefix()
  // const isMobile = useMaxMediaQuery(719)

  return (
    <section className={styles.root} data-cy="mobileResults">
      <div className={classNames('nv-container nv-grid')}>
        <div className={styles.mediaSection}>
          <div className={styles.mobilePhoneSection}>
            <div className={styles.mobilePhoneVideoContainer}>{fields && <WrappedVideo fields={fields} id="" />}</div>
          </div>
        </div>
        <div className={styles.textSection}>
          <NvHeading element="h2" level={2}>
            {getText('title')}
          </NvHeading>
          <NvSubheading className={styles.subHeading} level="level1book">
            {getText('description')}
          </NvSubheading>
          {!isKeywordEmpty('linkPath') && (
            <NvLink className={styles.button} href={addCountryCodePrefix(getText('linkPath'))} variant="secondary">
              {getText('linkText')}
            </NvLink>
          )}
        </div>
      </div>
    </section>
  )
}

export default HomeMobileResults
